import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const AWSCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                AWS Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install AWS CLI:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update
sudo apt-get install awscli

# On macOS:
brew install awscli`} />
                  </li>
                  <li>
                    <strong>Configure AWS CLI:</strong>
                    <ArticleCode code={`aws configure`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>EC2 (Elastic Compute Cloud):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Launch EC2 Instance:</strong>
                    <ArticleCode code={`aws ec2 run-instances --image-id ami-0c55b159cbfafe1f0 --count 1 --instance-type t2.micro --key-name MyKeyPair --security-group-ids sg-903004f8 --subnet-id subnet-6e7f829e`} />
                  </li>
                  <li>
                    <strong>Stop EC2 Instance:</strong>
                    <ArticleCode code={`aws ec2 stop-instances --instance-ids i-1234567890abcdef0`} />
                  </li>
                  <li>
                    <strong>Start EC2 Instance:</strong>
                    <ArticleCode code={`aws ec2 start-instances --instance-ids i-1234567890abcdef0`} />
                  </li>
                  <li>
                    <strong>Terminate EC2 Instance:</strong>
                    <ArticleCode code={`aws ec2 terminate-instances --instance-ids i-1234567890abcdef0`} />
                  </li>
                  <li>
                    <strong>Describe EC2 Instances:</strong>
                    <ArticleCode code={`aws ec2 describe-instances`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>S3 (Simple Storage Service):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create S3 Bucket:</strong>
                    <ArticleCode code={`aws s3 mb s3://my-bucket`} />
                  </li>
                  <li>
                    <strong>List S3 Buckets:</strong>
                    <ArticleCode code={`aws s3 ls`} />
                  </li>
                  <li>
                    <strong>Upload File to S3:</strong>
                    <ArticleCode code={`aws s3 cp myfile.txt s3://my-bucket/`} />
                  </li>
                  <li>
                    <strong>Download File from S3:</strong>
                    <ArticleCode code={`aws s3 cp s3://my-bucket/myfile.txt .`} />
                  </li>
                  <li>
                    <strong>Delete S3 Bucket:</strong>
                    <ArticleCode code={`aws s3 rb s3://my-bucket --force`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>IAM (Identity and Access Management):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create IAM User:</strong>
                    <ArticleCode code={`aws iam create-user --user-name myuser`} />
                  </li>
                  <li>
                    <strong>Delete IAM User:</strong>
                    <ArticleCode code={`aws iam delete-user --user-name myuser`} />
                  </li>
                  <li>
                    <strong>Create IAM Role:</strong>
                    <ArticleCode code={`aws iam create-role --role-name myrole --assume-role-policy-document file://policy.json`} />
                  </li>
                  <li>
                    <strong>Attach Policy to Role:</strong>
                    <ArticleCode code={`aws iam attach-role-policy --role-name myrole --policy-arn arn:aws:iam::aws:policy/AmazonS3FullAccess`} />
                  </li>
                  <li>
                    <strong>List IAM Roles:</strong>
                    <ArticleCode code={`aws iam list-roles`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>RDS (Relational Database Service):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create RDS Instance:</strong>
                    <ArticleCode code={`aws rds create-db-instance --db-instance-identifier mydbinstance --db-instance-class db.t2.micro --engine mysql --master-username admin --master-user-password secret99 --allocated-storage 20`} />
                  </li>
                  <li>
                    <strong>Delete RDS Instance:</strong>
                    <ArticleCode code={`aws rds delete-db-instance --db-instance-identifier mydbinstance --skip-final-snapshot`} />
                  </li>
                  <li>
                    <strong>Describe RDS Instances:</strong>
                    <ArticleCode code={`aws rds describe-db-instances`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Lambda:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Lambda Function:</strong>
                    <ArticleCode code={`aws lambda create-function --function-name myfunction --runtime nodejs14.x --role arn:aws:iam::123456789012:role/service-role/my-role --handler index.handler --zip-file fileb://function.zip`} />
                  </li>
                  <li>
                    <strong>Invoke Lambda Function:</strong>
                    <ArticleCode code={`aws lambda invoke --function-name myfunction outputfile.txt`} />
                  </li>
                  <li>
                    <strong>Delete Lambda Function:</strong>
                    <ArticleCode code={`aws lambda delete-function --function-name myfunction`} />
                  </li>
                  <li>
                    <strong>List Lambda Functions:</strong>
                    <ArticleCode code={`aws lambda list-functions`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CloudFormation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Stack:</strong>
                    <ArticleCode code={`aws cloudformation create-stack --stack-name mystack --template-body file://template.json`} />
                  </li>
                  <li>
                    <strong>Delete Stack:</strong>
                    <ArticleCode code={`aws cloudformation delete-stack --stack-name mystack`} />
                  </li>
                  <li>
                    <strong>Describe Stacks:</strong>
                    <ArticleCode code={`aws cloudformation describe-stacks`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CloudWatch:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Alarms:</strong>
                    <ArticleCode code={`aws cloudwatch describe-alarms`} />
                  </li>
                  <li>
                    <strong>Get Metrics:</strong>
                    <ArticleCode code={`aws cloudwatch get-metric-statistics --namespace AWS/EC2 --metric-name CPUUtilization --start-time 2021-01-01T00:00:00Z --end-time 2021-01-01T01:00:00Z --period 60 --statistics Average`} />
                  </li>
                  <li>
                    <strong>Put Metric Data:</strong>
                    <ArticleCode code={`aws cloudwatch put-metric-data --metric-name PageViewCount --namespace MyService --value 1 --unit Count`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Elastic Beanstalk:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Application:</strong>
                    <ArticleCode code={`aws elasticbeanstalk create-application --application-name myapp`} />
                  </li>
                  <li>
                    <strong>Create Environment:</strong>
                    <ArticleCode code={`aws elasticbeanstalk create-environment --application-name myapp --environment-name myenv --solution-stack-name "64bit Amazon Linux 2 v3.1.3 running Node.js 14"`} />
                  </li>
                  <li>
                    <strong>Describe Environments:</strong>
                    <ArticleCode code={`aws elasticbeanstalk describe-environments --application-name myapp`} />
                  </li>
                  <li>
                    <strong>Delete Environment:</strong>
                    <ArticleCode code={`aws elasticbeanstalk terminate-environment --environment-name myenv`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Networking (VPC):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create VPC:</strong>
                    <ArticleCode code={`aws ec2 create-vpc --cidr-block 10.0.0.0/16`} />
                  </li>
                  <li>
                    <strong>Create Subnet:</strong>
                    <ArticleCode code={`aws ec2 create-subnet --vpc-id vpc-12345678 --cidr-block 10.0.1.0/24`} />
                  </li>
                  <li>
                    <strong>Create Internet Gateway:</strong>
                    <ArticleCode code={`aws ec2 create-internet-gateway`} />
                  </li>
                  <li>
                    <strong>Attach Internet Gateway:</strong>
                    <ArticleCode code={`aws ec2 attach-internet-gateway --vpc-id vpc-12345678 --internet-gateway-id igw-12345678`} />
                  </li>
                  <li>
                    <strong>Create Route Table:</strong>
                    <ArticleCode code={`aws ec2 create-route-table --vpc-id vpc-12345678`} />
                  </li>
                  <li>
                    <strong>Create Route:</strong>
                    <ArticleCode code={`aws ec2 create-route --route-table-id rtb-12345678 --destination-cidr-block 0.0.0.0/0 --gateway-id igw-12345678`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Security Groups:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Security Group:</strong>
                    <ArticleCode code={`aws ec2 create-security-group --group-name mysg --description "My security group" --vpc-id vpc-12345678`} />
                  </li>
                  <li>
                    <strong>Authorize Inbound Rule:</strong>
                    <ArticleCode code={`aws ec2 authorize-security-group-ingress --group-id sg-12345678 --protocol tcp --port 22 --cidr 0.0.0.0/0`} />
                  </li>
                  <li>
                    <strong>Revoke Inbound Rule:</strong>
                    <ArticleCode code={`aws ec2 revoke-security-group-ingress --group-id sg-12345678 --protocol tcp --port 22 --cidr 0.0.0.0/0`} />
                  </li>
                  <li>
                    <strong>Delete Security Group:</strong>
                    <ArticleCode code={`aws ec2 delete-security-group --group-id sg-12345678`} />
                  </li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default AWSCheatsheet;
