import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const CassandraCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Cassandra Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Cassandra:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update
sudo apt-get install cassandra

# On macOS:
brew install cassandra`} />
                  </li>
                  <li>
                    <strong>Start Cassandra Service:</strong>
                    <ArticleCode code={`sudo service cassandra start`} />
                  </li>
                  <li>
                    <strong>Connect to Cassandra CLI (cqlsh):</strong>
                    <ArticleCode code={`cqlsh`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Show Keyspaces:</strong>
                    <ArticleCode code={`DESCRIBE KEYSPACES;`} />
                  </li>
                  <li>
                    <strong>Create Keyspace:</strong>
                    <ArticleCode code={`CREATE KEYSPACE mykeyspace WITH replication = {'class': 'SimpleStrategy', 'replication_factor': '1'};`} />
                  </li>
                  <li>
                    <strong>Use Keyspace:</strong>
                    <ArticleCode code={`USE mykeyspace;`} />
                  </li>
                  <li>
                    <strong>Show Tables:</strong>
                    <ArticleCode code={`DESCRIBE TABLES;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Table Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Table:</strong>
                    <ArticleCode code={`CREATE TABLE users (
  id UUID PRIMARY KEY,
  name TEXT,
  email TEXT
);`} />
                  </li>
                  <li>
                    <strong>Insert Data:</strong>
                    <ArticleCode code={`INSERT INTO users (id, name, email) VALUES (uuid(), 'John Doe', 'john.doe@example.com');`} />
                  </li>
                  <li>
                    <strong>Select Data:</strong>
                    <ArticleCode code={`SELECT * FROM users;`} />
                  </li>
                  <li>
                    <strong>Update Data:</strong>
                    <ArticleCode code={`UPDATE users SET email = 'new.email@example.com' WHERE id = some-uuid;`} />
                  </li>
                  <li>
                    <strong>Delete Data:</strong>
                    <ArticleCode code={`DELETE FROM users WHERE id = some-uuid;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Filtering with WHERE:</strong>
                    <ArticleCode code={`SELECT * FROM users WHERE name = 'John Doe';`} />
                  </li>
                  <li>
                    <strong>Ordering Results:</strong>
                    <ArticleCode code={`SELECT * FROM users WHERE name = 'John Doe' ORDER BY email DESC;`} />
                  </li>
                  <li>
                    <strong>Using IN Clause:</strong>
                    <ArticleCode code={`SELECT * FROM users WHERE id IN (uuid1, uuid2, uuid3);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Indexes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Index:</strong>
                    <ArticleCode code={`CREATE INDEX ON users(email);`} />
                  </li>
                  <li>
                    <strong>Drop Index:</strong>
                    <ArticleCode code={`DROP INDEX users_email_idx;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Batch Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Batch Insert:</strong>
                    <ArticleCode code={`BEGIN BATCH
INSERT INTO users (id, name, email) VALUES (uuid(), 'Jane Doe', 'jane.doe@example.com');
INSERT INTO users (id, name, email) VALUES (uuid(), 'Alice', 'alice@example.com');
APPLY BATCH;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>User Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create User:</strong>
                    <ArticleCode code={`CREATE USER 'myuser' WITH PASSWORD 'mypassword' NOSUPERUSER;`} />
                  </li>
                  <li>
                    <strong>Grant Permissions:</strong>
                    <ArticleCode code={`GRANT ALL PERMISSIONS ON KEYSPACE mykeyspace TO 'myuser';`} />
                  </li>
                  <li>
                    <strong>List Users:</strong>
                    <ArticleCode code={`LIST USERS;`} />
                  </li>
                  <li>
                    <strong>Revoke Permissions:</strong>
                    <ArticleCode code={`REVOKE ALL PERMISSIONS ON KEYSPACE mykeyspace FROM 'myuser';`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Backup and Restore:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Snapshot Backup:</strong>
                    <ArticleCode code={`nodetool snapshot -t my_snapshot mykeyspace`} />
                  </li>
                  <li>
                    <strong>Restore from Snapshot:</strong>
                    <ArticleParagraph>Copy the snapshot files back to the data directory and restart the node.</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Replication and Consistency:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Set Replication Strategy:</strong>
                    <ArticleCode code={`ALTER KEYSPACE mykeyspace WITH replication = {'class': 'NetworkTopologyStrategy', 'dc1': '3'};`} />
                  </li>
                  <li>
                    <strong>Set Consistency Level:</strong>
                    <ArticleCode code={`CONSISTENCY QUORUM;`} />
                  </li>
                  <li>
                    <strong>Check Consistency Level:</strong>
                    <ArticleCode code={`CONSISTENCY;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Performance Tuning:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check Cluster Status:</strong>
                    <ArticleCode code={`nodetool status`} />
                  </li>
                  <li>
                    <strong>Flush Memtables:</strong>
                    <ArticleCode code={`nodetool flush mykeyspace`} />
                  </li>
                  <li>
                    <strong>Compact Tables:</strong>
                    <ArticleCode code={`nodetool compact mykeyspace`} />
                  </li>
                  <li>
                    <strong>Repair Keyspace:</strong>
                    <ArticleCode code={`nodetool repair mykeyspace`} />
                  </li>
                  <li>
                    <strong>Monitor Performance:</strong>
                    <ArticleCode code={`nodetool tpstats`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default CassandraCheatsheet;
