import React from 'react';

const PrivacyNotice = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl">
        <h1 className="text-3xl font-bold text-blue-600 mb-4">Privacy Notice</h1>
        <p className="text-gray-700 mb-4">
          Welcome to javaspring.org. This privacy notice discloses the privacy practices for our website.
        </p>
        <p className="text-gray-700 mb-4">
          At javaspring.org, we are committed to protecting your privacy. This privacy notice applies solely to information collected by this website. However, we do not collect any personal information from our users. Our website is designed to provide articles and how-tos for technical coding languages without requiring any data from you.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Information Collection, Use, and Sharing</strong>
          <br />
          We do not collect, use, or share any personal information. We do not require registration, and we do not use cookies or other tracking technologies to collect data about your visit.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>External Links</strong>
          <br />
          Our website may contain links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personal information.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Changes to This Privacy Notice</strong>
          <br />
          We may update our privacy notice from time to time. We will notify you of any changes by posting the new privacy notice on our website. You are advised to review this privacy notice periodically for any changes.
        </p>
        <p className="text-gray-700">
          If you have any questions or concerns about our privacy notice, please feel free to contact us at info@javaspring.org.
        </p>
      </div>
    </div>
  );
};

export default PrivacyNotice;
