import React from "react";

const SubHeroSection = () => {
  const handleButtonClick = () => {
    window.location.href = '/cheat-sheets';
  };
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-12 mx-auto flex flex-wrap items-center">
        <h2 className="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5">
          Boost your development learning
        </h2>
        <div className="md:w-3/5 md:pl-6">
          <p className="leading-relaxed text-justify text-gray-700 font-medium">
            Start your path to a fulfilling career with our extensive range of
            tutorials, articles, and interview questions. Gain the high-demand
            knowledge and skills needed across industries. Our well-designed
            resources will help you excel as a job candidate. Engage with our
            content to boost your understanding and improve your job prospects.
            Begin today and confidently explore the opportunities ahead.
          </p>
          <div className="flex justify-center md:justify-start md:mt-4 mt-6">
            <button
              onClick={handleButtonClick}
              className="inline-flex text-white bg-indigo-500 border-0 py-2 px-4 focus:outline-none hover:bg-indigo-600 rounded transition-colors duration-300"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubHeroSection;
