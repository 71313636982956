import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const GCPCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Google Cloud Platform (GCP) Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Google Cloud SDK:</strong>
                    <ArticleCode code={`# On Ubuntu:
echo "deb [signed-by=/usr/share/keyrings/cloud.google.gpg] http://packages.cloud.google.com/apt cloud-sdk main" | sudo tee -a /etc/apt/sources.list.d/google-cloud-sdk.list
sudo apt-get install apt-transport-https ca-certificates gnupg
curl https://packages.cloud.google.com/apt/doc/apt-key.gpg | sudo apt-key --keyring /usr/share/keyrings/cloud.google.gpg add -
sudo apt-get update && sudo apt-get install google-cloud-sdk

# On macOS:
brew install --cask google-cloud-sdk`} />
                  </li>
                  <li>
                    <strong>Initialize Google Cloud SDK:</strong>
                    <ArticleCode code={`gcloud init`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Compute Engine (VMs):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create VM Instance:</strong>
                    <ArticleCode code={`gcloud compute instances create my-instance --zone=us-central1-a --machine-type=e2-medium --image-project=debian-cloud --image-family=debian-10`} />
                  </li>
                  <li>
                    <strong>Start VM Instance:</strong>
                    <ArticleCode code={`gcloud compute instances start my-instance --zone=us-central1-a`} />
                  </li>
                  <li>
                    <strong>Stop VM Instance:</strong>
                    <ArticleCode code={`gcloud compute instances stop my-instance --zone=us-central1-a`} />
                  </li>
                  <li>
                    <strong>Delete VM Instance:</strong>
                    <ArticleCode code={`gcloud compute instances delete my-instance --zone=us-central1-a`} />
                  </li>
                  <li>
                    <strong>List VM Instances:</strong>
                    <ArticleCode code={`gcloud compute instances list`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Cloud Storage:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Storage Bucket:</strong>
                    <ArticleCode code={`gsutil mb gs://my-bucket`} />
                  </li>
                  <li>
                    <strong>List Storage Buckets:</strong>
                    <ArticleCode code={`gsutil ls`} />
                  </li>
                  <li>
                    <strong>Upload File to Bucket:</strong>
                    <ArticleCode code={`gsutil cp myfile.txt gs://my-bucket`} />
                  </li>
                  <li>
                    <strong>Download File from Bucket:</strong>
                    <ArticleCode code={`gsutil cp gs://my-bucket/myfile.txt .`} />
                  </li>
                  <li>
                    <strong>Delete Storage Bucket:</strong>
                    <ArticleCode code={`gsutil rm -r gs://my-bucket`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Cloud Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Deploy Cloud Function:</strong>
                    <ArticleCode code={`gcloud functions deploy myFunction --runtime nodejs14 --trigger-http --allow-unauthenticated`} />
                  </li>
                  <li>
                    <strong>Invoke Cloud Function:</strong>
                    <ArticleCode code={`gcloud functions call myFunction --data '{"name":"GCP"}'`} />
                  </li>
                  <li>
                    <strong>Delete Cloud Function:</strong>
                    <ArticleCode code={`gcloud functions delete myFunction`} />
                  </li>
                  <li>
                    <strong>List Cloud Functions:</strong>
                    <ArticleCode code={`gcloud functions list`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Cloud SQL:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Cloud SQL Instance:</strong>
                    <ArticleCode code={`gcloud sql instances create my-instance --database-version=MYSQL_8_0 --tier=db-f1-micro --region=us-central1`} />
                  </li>
                  <li>
                    <strong>Create SQL Database:</strong>
                    <ArticleCode code={`gcloud sql databases create mydatabase --instance=my-instance`} />
                  </li>
                  <li>
                    <strong>Delete SQL Database:</strong>
                    <ArticleCode code={`gcloud sql databases delete mydatabase --instance=my-instance`} />
                  </li>
                  <li>
                    <strong>Delete SQL Instance:</strong>
                    <ArticleCode code={`gcloud sql instances delete my-instance`} />
                  </li>
                  <li>
                    <strong>List SQL Instances:</strong>
                    <ArticleCode code={`gcloud sql instances list`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Google Kubernetes Engine (GKE):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create GKE Cluster:</strong>
                    <ArticleCode code={`gcloud container clusters create my-cluster --zone us-central1-a --num-nodes=3`} />
                  </li>
                  <li>
                    <strong>Get Cluster Credentials:</strong>
                    <ArticleCode code={`gcloud container clusters get-credentials my-cluster --zone us-central1-a`} />
                  </li>
                  <li>
                    <strong>Deploy Application to Cluster:</strong>
                    <ArticleCode code={`kubectl create deployment my-app --image=gcr.io/my-project/my-app`} />
                  </li>
                  <li>
                    <strong>Expose Application:</strong>
                    <ArticleCode code={`kubectl expose deployment my-app --type=LoadBalancer --port 80 --target-port 8080`} />
                  </li>
                  <li>
                    <strong>Delete GKE Cluster:</strong>
                    <ArticleCode code={`gcloud container clusters delete my-cluster --zone us-central1-a`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>VPC (Virtual Private Cloud):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create VPC Network:</strong>
                    <ArticleCode code={`gcloud compute networks create my-vpc --subnet-mode=custom`} />
                  </li>
                  <li>
                    <strong>Create Subnet:</strong>
                    <ArticleCode code={`gcloud compute networks subnets create my-subnet --network=my-vpc --range=10.0.0.0/24`} />
                  </li>
                  <li>
                    <strong>Create Firewall Rule:</strong>
                    <ArticleCode code={`gcloud compute firewall-rules create allow-ssh --network my-vpc --allow tcp:22`} />
                  </li>
                  <li>
                    <strong>List VPC Networks:</strong>
                    <ArticleCode code={`gcloud compute networks list`} />
                  </li>
                  <li>
                    <strong>Delete VPC Network:</strong>
                    <ArticleCode code={`gcloud compute networks delete my-vpc`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>IAM (Identity and Access Management):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create IAM User:</strong>
                    <ArticleParagraph>IAM users are not directly created in GCP. Use service accounts or G Suite for user management.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Create Service Account:</strong>
                    <ArticleCode code={`gcloud iam service-accounts create my-service-account --display-name "My Service Account"`} />
                  </li>
                  <li>
                    <strong>Assign Role to Service Account:</strong>
                    <ArticleCode code={`gcloud projects add-iam-policy-binding my-project --member serviceAccount:my-service-account@my-project.iam.gserviceaccount.com --role roles/editor`} />
                  </li>
                  <li>
                    <strong>List Service Accounts:</strong>
                    <ArticleCode code={`gcloud iam service-accounts list`} />
                  </li>
                  <li>
                    <strong>Delete Service Account:</strong>
                    <ArticleCode code={`gcloud iam service-accounts delete my-service-account@my-project.iam.gserviceaccount.com`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>BigQuery:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Dataset:</strong>
                    <ArticleCode code={`bq mk mydataset`} />
                  </li>
                  <li>
                    <strong>Create Table:</strong>
                    <ArticleCode code={`bq mk --table mydataset.mytable name:STRING,age:INTEGER`} />
                  </li>
                  <li>
                    <strong>Load Data into Table:</strong>
                    <ArticleCode code={`bq load --source_format=CSV mydataset.mytable gs://my-bucket/myfile.csv`} />
                  </li>
                  <li>
                    <strong>Query Data:</strong>
                    <ArticleCode code={`bq query "SELECT * FROM mydataset.mytable"`} />
                  </li>
                  <li>
                    <strong>Delete Dataset:</strong>
                    <ArticleCode code={`bq rm -r -f mydataset`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Monitoring and Logging (Stackdriver):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Monitoring Workspace:</strong>
                    <ArticleParagraph>Use the GCP Console to create a monitoring workspace.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Create Log Sink:</strong>
                    <ArticleCode code={`gcloud logging sinks create my-sink storage.googleapis.com/my-bucket`} />
                  </li>
                  <li>
                    <strong>View Logs:</strong>
                    <ArticleCode code={`gcloud logging read "logName=projects/my-project/logs/my-log" --limit 10`} />
                  </li>
                  <li>
                    <strong>Delete Log Sink:</strong>
                    <ArticleCode code={`gcloud logging sinks delete my-sink`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default GCPCheatsheet;
