import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const GraphQLCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                GraphQL Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install GraphQL:</strong>
                    <ArticleCode code={`# For Node.js
npm install graphql express express-graphql

# For Apollo Server
npm install apollo-server graphql`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Setup:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Express GraphQL Server:</strong>
                    <ArticleCode code={`const express = require('express');
const { graphqlHTTP } = require('express-graphql');
const { buildSchema } = require('graphql');

const schema = buildSchema(\`
  type Query {
    hello: String
  }
\`);

const root = {
  hello: () => 'Hello world!'
};

const app = express();
app.use('/graphql', graphqlHTTP({
  schema: schema,
  rootValue: root,
  graphiql: true,
}));
app.listen(4000, () => console.log('Server running on http://localhost:4000/graphql'));`} />
                  </li>
                  <li>
                    <strong>Apollo Server:</strong>
                    <ArticleCode code={`const { ApolloServer, gql } = require('apollo-server');

const typeDefs = gql\`
  type Query {
    hello: String
  }
\`;

const resolvers = {
  Query: {
    hello: () => 'Hello world!',
  },
};

const server = new ApolloServer({ typeDefs, resolvers });

server.listen().then(({ url }) => {
  console.log(\`Server ready at \${url}\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Schema Definition:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Types:</strong>
                    <ArticleCode code={`type Query {
  hello: String
  age: Int
  isCool: Boolean
}`} />
                  </li>
                  <li>
                    <strong>Custom Types:</strong>
                    <ArticleCode code={`type User {
  id: ID
  name: String
  email: String
}

type Query {
  user(id: ID!): User
  users: [User]
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Resolvers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Resolver:</strong>
                    <ArticleCode code={`const resolvers = {
  Query: {
    hello: () => 'Hello world!',
  },
};`} />
                  </li>
                  <li>
                    <strong>Resolver with Arguments:</strong>
                    <ArticleCode code={`const resolvers = {
  Query: {
    user: (parent, args, context, info) => {
      const { id } = args;
      return users.find(user => user.id === id);
    },
    users: () => users,
  },
};`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Mutations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Mutations:</strong>
                    <ArticleCode code={`type Mutation {
  createUser(name: String!, email: String!): User
}

type User {
  id: ID
  name: String
  email: String
}`} />
                  </li>
                  <li>
                    <strong>Resolver for Mutation:</strong>
                    <ArticleCode code={`const resolvers = {
  Mutation: {
    createUser: (parent, args, context, info) => {
      const newUser = { id: Date.now().toString(), ...args };
      users.push(newUser);
      return newUser;
    },
  },
};`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Client Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Query:</strong>
                    <ArticleCode code={`query {
  hello
}`} />
                  </li>
                  <li>
                    <strong>Query with Arguments:</strong>
                    <ArticleCode code={`query {
  user(id: "1") {
    id
    name
    email
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Client Mutations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Mutation:</strong>
                    <ArticleCode code={`mutation {
  createUser(name: "John Doe", email: "john.doe@example.com") {
    id
    name
    email
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Middleware for Authentication:</strong>
                    <ArticleCode code={`const { ApolloServer, gql } = require('apollo-server');
const jwt = require('jsonwebtoken');

const getUser = (token) => {
  try {
    if (token) {
      return jwt.verify(token, 'your_secret_key');
    }
    return null;
  } catch (err) {
    return null;
  }
};

const server = new ApolloServer({
  typeDefs,
  resolvers,
  context: ({ req }) => {
    const token = req.headers.authorization || '';
    const user = getUser(token);
    return { user };
  },
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Subscriptions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Subscriptions:</strong>
                    <ArticleCode code={`const { ApolloServer, gql, PubSub } = require('apollo-server');

const pubsub = new PubSub();
const MESSAGE_ADDED = 'MESSAGE_ADDED';

const typeDefs = gql\`
  type Message {
    id: ID!
    content: String!
  }

  type Query {
    messages: [Message!]
  }

  type Mutation {
    addMessage(content: String!): Message!
  }

  type Subscription {
    messageAdded: Message!
  }
\`;

const resolvers = {
  Query: {
    messages: () => messages,
  },
  Mutation: {
    addMessage: (parent, { content }) => {
      const message = { id: Date.now().toString(), content };
      messages.push(message);
      pubsub.publish(MESSAGE_ADDED, { messageAdded: message });
      return message;
    },
  },
  Subscription: {
    messageAdded: {
      subscribe: () => pubsub.asyncIterator([MESSAGE_ADDED]),
    },
  },
};

const server = new ApolloServer({ typeDefs, resolvers });

server.listen().then(({ url }) => {
  console.log(\`Server ready at \${url}\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Custom Error Handling:</strong>
                    <ArticleCode code={`const { ApolloServer, gql, ApolloError } = require('apollo-server');

const typeDefs = gql\`
  type Query {
    throwError: String
  }
\`;

const resolvers = {
  Query: {
    throwError: () => {
      throw new ApolloError('This is a custom error message', 'MY_CUSTOM_ERROR');
    },
  },
};

const server = new ApolloServer({ typeDefs, resolvers });

server.listen().then(({ url }) => {
  console.log(\`Server ready at \${url}\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphQLCheatsheet;
