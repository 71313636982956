import React from "react";

const SideLongBox = ({ data }) => {
  return (
    // Replace max-h-96 with a style attribute for custom height
    <div
      className="custom-scrollbar border border-gray-300 p-3 rounded-lg shadow-xs bg-white hover:shadow-2xl transition-shadow duration-300 overflow-auto"
      style={{ maxHeight: "31rem" }}
    >
      {data.map((box, index) => (
        <div key={index} className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            {box.title}
          </h2>
          <ul>
            {box.linkToResources.map((resource, resourceIndex) => (
              <li key={resourceIndex} className="mb-1">
                <div className="bg-gray-50 rounded">
                  <a
                    href={`/${resource.path}`}
                    className="text-blue-900 hover:text-green-700 transition-colors duration-300 text-base"
                  >
                    {resource.title}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SideLongBox;
