import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const RubyCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
              {/* <PDFViewer /> */}
            </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Ruby Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`puts "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`x = 5
y = 3.14
c = 'A'
s = "Hello"`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`# This is a single-line comment
=begin
This is a 
multi-line comment
=end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Numbers:</strong>
                    <ArticleCode code={`x = 10  # Integer
y = 2.5  # Float`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`s = "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`arr = [1, 2, 3]`} />
                  </li>
                  <li>
                    <strong>Hashes:</strong>
                    <ArticleCode code={`hash = {"name" => "John", "age" => 30}`} />
                  </li>
                  <li>
                    <strong>Symbols:</strong>
                    <ArticleCode code={`sym = :my_symbol`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if x > 0
  puts "x is positive"
elsif x == 0
  puts "x is zero"
else
  puts "x is negative"
end`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for i in 0..4
  puts i
end`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`while x > 0
  puts x
  x -= 1
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Methods:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Methods:</strong>
                    <ArticleCode code={`def my_method
  puts "Hello from a method"
end`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`def add(a, b)
  a + b
end

result = add(2, 3)
puts result`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes and Objects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`class MyClass
  def initialize(number)
    @my_number = number
  end

  def my_method
    puts "Hello, World!"
  end
end`} />
                  </li>
                  <li>
                    <strong>Creating an Object:</strong>
                    <ArticleCode code={`obj = MyClass.new(5)
obj.my_method`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Base and Derived Classes:</strong>
                    <ArticleCode code={`class Base
  def display
    puts "Base class"
  end
end

class Derived < Base
  def show
    puts "Derived class"
  end
end`} />
                  </li>
                  <li>
                    <strong>Using Inheritance:</strong>
                    <ArticleCode code={`obj = Derived.new
obj.display
obj.show`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Module:</strong>
                    <ArticleCode code={`module MyModule
  def my_method
    puts "Hello from a module"
  end
end`} />
                  </li>
                  <li>
                    <strong>Including a Module:</strong>
                    <ArticleCode code={`class MyClass
  include MyModule
end

obj = MyClass.new
obj.my_method`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Blocks and Iterators:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Blocks:</strong>
                    <ArticleCode code={`[1, 2, 3].each do |num|
  puts num
end`} />
                  </li>
                  <li>
                    <strong>Using Iterators:</strong>
                    <ArticleCode code={`arr = [1, 2, 3]
arr.map! { |num| num * 2 }
puts arr`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Exception Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Begin...Rescue:</strong>
                    <ArticleCode code={`begin
  result = 10 / 0
rescue ZeroDivisionError => e
  puts "Exception caught: #{e.message}"
end`} />
                  </li>
                  <li>
                    <strong>Ensure:</strong>
                    <ArticleCode code={`begin
  result = 10 / 0
rescue ZeroDivisionError => e
  puts "Exception caught: #{e.message}"
ensure
  puts "This will always execute"
end`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default RubyCheatsheet;
