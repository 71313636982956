import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const JavaScriptCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                JavaScript Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Print to Console:</strong>
                    <ArticleCode code={`console.log("Hello, World!");`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`let x = 5;
const y = "Hello";`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a comment`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Numbers:</strong>
                    <ArticleCode code={`let x = 10;  // Number
let y = 2.5;  // Float`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`let s = "Hello, World!";`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`let arr = [1, 2, 3];`} />
                  </li>
                  <li>
                    <strong>Objects:</strong>
                    <ArticleCode code={`let obj = {name: "John", age: 30};`} />
                  </li>
                  <li>
                    <strong>Booleans:</strong>
                    <ArticleCode code={`let isTrue = true;`} />
                  </li>
                  <li>
                    <strong>Null:</strong>
                    <ArticleCode code={`let a = null;`} />
                  </li>
                  <li>
                    <strong>Undefined:</strong>
                    <ArticleCode code={`let b;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if (x > 0) {
  console.log("x is positive");
} else if (x === 0) {
  console.log("x is zero");
} else {
  console.log("x is negative");
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for (let i = 0; i < 5; i++) {
  console.log(i);
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`while (x > 0) {
  console.log(x);
  x--;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`function myFunction() {
  console.log("Hello from a function");
}`} />
                  </li>
                  <li>
                    <strong>Arrow Functions:</strong>
                    <ArticleCode code={`const myFunction = () => {
  console.log("Hello from an arrow function");
};`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`function add(a, b) {
  return a + b;
}

let result = add(2, 3);
console.log(result);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Exporting Modules:</strong>
                    <ArticleCode code={`// In file myModule.js
export const myFunction = () => {
  console.log("Hello from a module");
};`} />
                  </li>
                  <li>
                    <strong>Importing Modules:</strong>
                    <ArticleCode code={`// In another file
import { myFunction } from './myModule.js';
myFunction();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O (Node.js):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`const fs = require('fs');
fs.readFile('file.txt', 'utf8', (err, data) => {
  if (err) {
    console.error(err);
    return;
  }
  console.log(data);
});`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`const fs = require('fs');
fs.writeFile('file.txt', 'Hello, World!', (err) => {
  if (err) {
    console.error(err);
    return;
  }
  console.log('File has been written');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Built-in Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>ParseInt:</strong>
                    <ArticleCode code={`let num = parseInt("123");`} />
                  </li>
                  <li>
                    <strong>ParseFloat:</strong>
                    <ArticleCode code={`let num = parseFloat("123.45");`} />
                  </li>
                  <li>
                    <strong>ToString:</strong>
                    <ArticleCode code={`let str = (123).toString();`} />
                  </li>
                  <li>
                    <strong>TypeOf:</strong>
                    <ArticleCode code={`console.log(typeof x);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Exception Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Try...Catch:</strong>
                    <ArticleCode code={`try {
  let result = 10 / 0;
} catch (error) {
  console.error("Error:", error);
}`} />
                  </li>
                  <li>
                    <strong>Finally:</strong>
                    <ArticleCode code={`try {
  let result = 10 / 0;
} catch (error) {
  console.error("Error:", error);
} finally {
  console.log("This will always execute");
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default JavaScriptCheatsheet;
