import "./App.css";
import AppRouter from "./AppRouter";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig";

function App() {
  // logEvent(analytics, "page_view", { page_path: "/homepage" });
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
