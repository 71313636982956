import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const RustCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
              {/* <PDFViewer /> */}
            </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Rust Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`fn main() {
    println!("Hello, World!");
}`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`let x = 5;
let mut y = 3.14;
let z: i32 = 10;
let s = "Hello";`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/*
This is a
multi-line comment
*/`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`let x: i32 = 10;
let y: f64 = 2.5;
let c: char = 'A';
let b: bool = true;`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`let s = String::from("Hello, World!");`} />
                  </li>
                  <li>
                    <strong>Tuples:</strong>
                    <ArticleCode code={`let tup: (i32, f64, char) = (10, 2.5, 'A');`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`let arr: [i32; 3] = [1, 2, 3];`} />
                  </li>
                  <li>
                    <strong>Slices:</strong>
                    <ArticleCode code={`let slice: &[i32] = &arr[1..3];`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if x > 0 {
    println!("x is positive");
} else if x == 0 {
    println!("x is zero");
} else {
    println!("x is negative");
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for i in 0..5 {
    println!("{}", i);
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`let mut x = 5;
while x > 0 {
    println!("{}", x);
    x -= 1;
}`} />
                  </li>
                  <li>
                    <strong>Loop (Infinite Loop):</strong>
                    <ArticleCode code={`loop {
    println!("This will loop forever!");
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`fn my_function() {
    println!("Hello from a function");
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`fn add(a: i32, b: i32) -> i32 {
    a + b
}

let result = add(2, 3);
println!("{}", result);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Structs:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Struct:</strong>
                    <ArticleCode code={`struct Person {
    name: String,
    age: u8,
}`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`let p = Person { name: String::from("John"), age: 30 };
println!("{} is {} years old", p.name, p.age);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Enums:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining an Enum:</strong>
                    <ArticleCode code={`enum Direction {
    Up,
    Down,
    Left,
    Right,
}`} />
                  </li>
                  <li>
                    <strong>Using Enums:</strong>
                    <ArticleCode code={`let dir = Direction::Up;
match dir {
    Direction::Up => println!("Going up!"),
    Direction::Down => println!("Going down!"),
    Direction::Left => println!("Going left!"),
    Direction::Right => println!("Going right!"),
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Option and Result:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Option:</strong>
                    <ArticleCode code={`let some_number = Some(5);
let absent_number: Option<i32> = None;

if let Some(x) = some_number {
    println!("Found a number: {}", x);
}`} />
                  </li>
                  <li>
                    <strong>Using Result:</strong>
                    <ArticleCode code={`fn divide(a: f64, b: f64) -> Result<f64, String> {
    if b == 0.0 {
        Err(String::from("Cannot divide by zero"))
    } else {
        Ok(a / b)
    }
}

match divide(4.0, 2.0) {
    Ok(result) => println!("Result: {}", result),
    Err(e) => println!("Error: {}", e),
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Ownership and Borrowing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Ownership:</strong>
                    <ArticleCode code={`let s1 = String::from("Hello");
let s2 = s1; // s1 is moved to s2
// println!("{}", s1); // This would cause an error`} />
                  </li>
                  <li>
                    <strong>Borrowing:</strong>
                    <ArticleCode code={`let s1 = String::from("Hello");
let s2 = &s1; // s1 is borrowed by s2
println!("{}", s1);
println!("{}", s2);`} />
                  </li>
                  <li>
                    <strong>Mutable Borrowing:</strong>
                    <ArticleCode code={`let mut s = String::from("Hello");
let s1 = &mut s;
s1.push_str(", world!");
println!("{}", s1);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Concurrency:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Threads:</strong>
                    <ArticleCode code={`use std::thread;

let handle = thread::spawn(|| {
    for i in 1..10 {
        println!("Hi number {} from the spawned thread!", i);
    }
});

for i in 1..5 {
    println!("Hi number {} from the main thread!", i);
}

handle.join().unwrap();`} />
                  </li>
                  <li>
                    <strong>Using Channels:</strong>
                    <ArticleCode code={`use std::sync::mpsc;
use std::thread;

let (tx, rx) = mpsc::channel();

thread::spawn(move || {
    let val = String::from("hi");
    tx.send(val).unwrap();
});

let received = rx.recv().unwrap();
println!("Got: {}", received);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Panic:</strong>
                    <ArticleCode code={`fn main() {
    panic!("This is a panic!");
}`} />
                  </li>
                  <li>
                    <strong>Result:</strong>
                    <ArticleCode code={`use std::fs::File;

fn main() {
    let f = File::open("file.txt");

    let f = match f {
        Ok(file) => file,
        Err(e) => {
            println!("Error opening file: {}", e);
            return;
        }
    };
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default RustCheatsheet;
