import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const NumPyCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                NumPy Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to NumPy:</ArticleSubtitle>
              <ArticleParagraph>
                <p>NumPy is a fundamental package for scientific computing in Python. It provides support for arrays, matrices, and a large collection of mathematical functions to operate on these data structures. This cheat sheet covers essential commands and operations to help you get started with NumPy.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install NumPy:</strong>
                    <ArticleCode code={`pip install numpy`} />
                  </li>
                  <li>
                    <strong>Import NumPy:</strong>
                    <ArticleCode code={`import numpy as np`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Creating Arrays:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Array from List:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a)`} />
                  </li>
                  <li>
                    <strong>Create Multi-dimensional Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([[1, 2, 3], [4, 5, 6]])
print(a)`} />
                  </li>
                  <li>
                    <strong>Create Zeros Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.zeros((3, 3))
print(a)`} />
                  </li>
                  <li>
                    <strong>Create Ones Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.ones((3, 3))
print(a)`} />
                  </li>
                  <li>
                    <strong>Create Empty Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.empty((2, 2))
print(a)`} />
                  </li>
                  <li>
                    <strong>Create Array with Range of Values:</strong>
                    <ArticleCode code={`import numpy as np

a = np.arange(10)
print(a)`} />
                  </li>
                  <li>
                    <strong>Create Linearly Spaced Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.linspace(0, 10, 5)
print(a)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Array Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Array Addition:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3])
b = np.array([4, 5, 6])
c = a + b
print(c)`} />
                  </li>
                  <li>
                    <strong>Array Multiplication:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3])
b = np.array([4, 5, 6])
c = a * b
print(c)`} />
                  </li>
                  <li>
                    <strong>Dot Product:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2])
b = np.array([4, 5])
c = np.dot(a, b)
print(c)`} />
                  </li>
                  <li>
                    <strong>Element-wise Operations:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3])
b = np.array([4, 5, 6])
c = a * 2
d = b + 1
print(c)
print(d)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Array Indexing and Slicing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Indexing:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a[0])  # First element
print(a[-1])  # Last element`} />
                  </li>
                  <li>
                    <strong>Slicing:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a[1:3])  # Elements from index 1 to 2`} />
                  </li>
                  <li>
                    <strong>Multi-dimensional Indexing:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([[1, 2, 3], [4, 5, 6]])
print(a[1, 2])  # Element at row 1, column 2`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Array Reshaping:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reshape Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.arange(6)
b = a.reshape((2, 3))
print(b)`} />
                  </li>
                  <li>
                    <strong>Flatten Array:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([[1, 2, 3], [4, 5, 6]])
b = a.flatten()
print(b)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Statistical Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Mean:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a.mean())`} />
                  </li>
                  <li>
                    <strong>Sum:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a.sum())`} />
                  </li>
                  <li>
                    <strong>Standard Deviation:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a.std())`} />
                  </li>
                  <li>
                    <strong>Max:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a.max())`} />
                  </li>
                  <li>
                    <strong>Min:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([1, 2, 3, 4, 5])
print(a.min())`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Linear Algebra Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Matrix Multiplication:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([[1, 2], [3, 4]])
b = np.array([[5, 6], [7, 8]])
c = np.matmul(a, b)
print(c)`} />
                  </li>
                  <li>
                    <strong>Determinant:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([[1, 2], [3, 4]])
det = np.linalg.det(a)
print(det)`} />
                  </li>
                  <li>
                    <strong>Inverse:</strong>
                    <ArticleCode code={`import numpy as np

a = np.array([[1, 2], [3, 4]])
inv = np.linalg.inv(a)
print(inv)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Vectorized Operations:</strong> Leverage NumPy's vectorized operations for better performance.</li>
                  <li><strong>Use Broadcasting:</strong> Utilize broadcasting to perform operations on arrays of different shapes.</li>
                  <li><strong>Handle Large Datasets:</strong> Use NumPy for efficient handling of large datasets and complex mathematical operations.</li>
                  <li><strong>Document Your Code:</strong> Add comments and docstrings to explain your data processing steps.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default NumPyCheatsheet;
