import React from 'react';

const ArticleCode = ({ code }) => {
  return (
    <div className="bg-gray-900 p-5 rounded-lg my-5 overflow-auto">
      <pre className="text-yellow-200 text-sm whitespace-pre-wrap break-all">
        <code>{code}</code>
      </pre>
    </div>
  );
};

export default ArticleCode;
