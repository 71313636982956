import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const SvelteCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Svelte.js Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Svelte Project:</strong>
                    <ArticleCode code={`npx degit sveltejs/template svelte-app
cd svelte-app
npm install
npm run dev`} />
                  </li>
                  <li>
                    <strong>Basic Structure:</strong>
                    <ArticleCode code={`<script>
  let name = 'world';
</script>

<style>
  h1 {
    color: purple;
  }
</style>

<h1>Hello {name}!</h1>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Reactivity:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reactive Statements:</strong>
                    <ArticleCode code={`<script>
  let count = 0;

  $: doubled = count * 2;
</script>

<p>{count} doubled is {doubled}</p>
<button on:click={() => count += 1}>
  Increment
</button>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Bindings:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Bind to Input:</strong>
                    <ArticleCode code={`<script>
  let name = '';
</script>

<input bind:value={name}>
<p>Hello {name}!</p>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Events:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Event Handling:</strong>
                    <ArticleCode code={`<script>
  function handleClick() {
    alert('Button clicked!');
  }
</script>

<button on:click={handleClick}>
  Click me
</button>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Conditional Rendering:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Blocks:</strong>
                    <ArticleCode code={`<script>
  let loggedIn = false;
</script>

{#if loggedIn}
  <p>Welcome back!</p>
{:else}
  <p>Please log in.</p>
{/if}

<button on:click={() => loggedIn = !loggedIn}>
  {#if loggedIn} Log out {:else} Log in {/if}
</button>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Loops:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Each Blocks:</strong>
                    <ArticleCode code={`<script>
  let items = ['one', 'two', 'three'];
</script>

<ul>
  {#each items as item}
    <li>{item}</li>
  {/each}
</ul>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Stores:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Writable Store:</strong>
                    <ArticleCode code={`import { writable } from 'svelte/store';

export const count = writable(0);`} />
                  </li>
                  <li>
                    <strong>Using Store:</strong>
                    <ArticleCode code={`<script>
  import { count } from './stores.js';
</script>

<button on:click={() => $count += 1}>
  Clicked {$count} times
</button>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Components:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Component:</strong>
                    <ArticleCode code={`<script>
  export let name;
</script>

<p>Hello {name}!</p>`} />
                  </li>
                  <li>
                    <strong>Using a Component:</strong>
                    <ArticleCode code={`<script>
  import Child from './Child.svelte';
</script>

<Child name="world" />`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Transitions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Fade Transition:</strong>
                    <ArticleCode code={`<script>
  import { fade } from 'svelte/transition';
  let visible = true;
</script>

<button on:click={() => visible = !visible}>
  Toggle
</button>

{#if visible}
  <p transition:fade>Hello!</p>
{/if}`}/>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Animations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Animate Directive:</strong>
                    <ArticleCode code={`<script>
  import { writable } from 'svelte/store';
  import { tweened } from 'svelte/motion';

  let count = writable(0);
</script>

<button on:click={() => count.update(n => n + 1)}>
  Increment
</button>

<p>{$count}</p>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Context API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Set Context:</strong>
                    <ArticleCode code={`<script context="module">
  import { setContext } from 'svelte';
  setContext('key', { value: 123 });
</script>`} />
                  </li>
                  <li>
                    <strong>Get Context:</strong>
                    <ArticleCode code={`<script>
  import { getContext } from 'svelte';
  const context = getContext('key');
</script>

<p>{context.value}</p>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Lifecycle:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>onMount:</strong>
                    <ArticleCode code={`<script>
  import { onMount } from 'svelte';
  onMount(() => {
    console.log('Component mounted');
  });
</script>`} />
                  </li>
                  <li>
                    <strong>onDestroy:</strong>
                    <ArticleCode code={`<script>
  import { onDestroy } from 'svelte';
  onDestroy(() => {
    console.log('Component destroyed');
  });
</script>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Server-side Rendering:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Sapper:</strong>
                    <ArticleCode code={`npx degit "sveltejs/sapper-template#rollup" my-app
cd my-app
npm install
npm run dev`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>TypeScript Support:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using TypeScript:</strong>
                    <ArticleCode code={`<script lang="ts">
  let name: string = 'world';
</script>

<p>Hello {name}!</p>`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SvelteCheatsheet;
