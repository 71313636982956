import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const TypeScriptCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                TypeScript Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`const message: string = "Hello, World!";
console.log(message);`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`let x: number = 5;
const y: number = 3.14;
let z: number = 10;
let s: string = "Hello";`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/*
This is a
multi-line comment
*/`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`let x: number = 10;
let y: number = 2.5;
let c: string = 'A';
let b: boolean = true;`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`let s: string = "Hello, World!";`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`let arr: number[] = [1, 2, 3];
let strArr: string[] = ["a", "b", "c"];`} />
                  </li>
                  <li>
                    <strong>Tuples:</strong>
                    <ArticleCode code={`let tuple: [number, string] = [10, "Hello"];`} />
                  </li>
                  <li>
                    <strong>Enums:</strong>
                    <ArticleCode code={`enum Color {
    Red,
    Green,
    Blue
}
let c: Color = Color.Green;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`function myFunction(): void {
    console.log("Hello from a function");
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`function add(a: number, b: number): number {
    return a + b;
}

let result: number = add(2, 3);
console.log(result);`} />
                  </li>
                  <li>
                    <strong>Optional Parameters:</strong>
                    <ArticleCode code={`function greet(name: string, age?: number): void {
    if (age) {
        console.log(\`Hello, \${name}. You are \${age} years old.\`);
    } else {
        console.log(\`Hello, \${name}.\`);
    }
}

greet("Alice");
greet("Bob", 30);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Interfaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining an Interface:</strong>
                    <ArticleCode code={`interface Person {
    name: string;
    age: number;
    greet(): void;
}`} />
                  </li>
                  <li>
                    <strong>Using an Interface:</strong>
                    <ArticleCode code={`let person: Person = {
    name: "John",
    age: 30,
    greet: function () {
        console.log(\`Hello, my name is \${this.name}\`);
    }
};
person.greet();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`class Person {
    name: string;
    age: number;

    constructor(name: string, age: number) {
        this.name = name;
        this.age = age;
    }

    greet(): void {
        console.log(\`Hello, my name is \${this.name}\`);
    }
}`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`let person = new Person("John", 30);
person.greet();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Base and Derived Classes:</strong>
                    <ArticleCode code={`class Animal {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    move(distance: number): void {
        console.log(\`\${this.name} moved \${distance} meters.\`);
    }
}

class Dog extends Animal {
    bark(): void {
        console.log("Woof! Woof!");
    }
}`} />
                  </li>
                  <li>
                    <strong>Using Inheritance:</strong>
                    <ArticleCode code={`let dog = new Dog("Buddy");
dog.bark();
dog.move(10);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Generics:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Generic Functions:</strong>
                    <ArticleCode code={`function identity<T>(arg: T): T {
    return arg;
}

let output1 = identity<string>("Hello");
let output2 = identity<number>(42);`} />
                  </li>
                  <li>
                    <strong>Generic Classes:</strong>
                    <ArticleCode code={`class GenericNumber<T> {
    zeroValue: T;
    add: (x: T, y: T) => T;

    constructor(zeroValue: T, add: (x: T, y: T) => T) {
        this.zeroValue = zeroValue;
        this.add = add;
    }
}

let myGenericNumber = new GenericNumber<number>(0, (x, y) => x + y);
console.log(myGenericNumber.add(1, 2));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules and Namespaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Exporting and Importing:</strong>
                    <ArticleCode code={`// math.ts
export function add(x: number, y: number): number {
    return x + y;
}

// main.ts
import { add } from './math';
console.log(add(5, 3));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Type Assertions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Type Assertions:</strong>
                    <ArticleCode code={`let someValue: any = "this is a string";
let strLength: number = (someValue as string).length;
console.log(strLength);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Union Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Union Types:</strong>
                    <ArticleCode code={`function printId(id: number | string) {
    console.log("Your ID is: " + id);
}

printId(101);
printId("202");`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Intersection Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Intersection Types:</strong>
                    <ArticleCode code={`interface Colorful {
    color: string;
}

interface Circle {
    radius: number;
}

type ColorfulCircle = Colorful & Circle;

const cc: ColorfulCircle = {
    color: "red",
    radius: 42,
};`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypeScriptCheatsheet;
