import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NavigationMenu = () => {
  // Using useNavigate hook for navigation
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Assuming your input field is named 'search'
    const query = event.target.elements.search.value;
    // Redirect to a search results page, passing the query as a URL parameter
    navigate(`/search?query=${encodeURIComponent(query)}`);
  };

  return (
    <header className="text-gray-600 body-font">
      <div className="container mx-1 flex flex-wrap p-2 flex-col md:flex-row items-center max-w-full">
        <Link
          to="/"
          className="flex ml-auto mr-auto title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <img
            src="/favicon.ico"
            alt="Java Spring logo"
            className="w-12 h-15 p-1 rounded-full"
          />
          <span className="ml-2 mr-auto text-xl">Java Spring</span>
        </Link>
        <div className="md:ml-auto mr-auto flex flex-col md:flex-row items-center w-full md:w-auto">
          <form onSubmit={handleSubmit} className="max-w-md mx-auto relative">
            <div className="flex items-center border border-gray-300 rounded-lg bg-gray-50">
              <svg
                className="w-5 h-5 text-gray-500 ml-3"
                aria-hidden="true"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <input
                name="search"
                type="search"
                style={{ outline: "none", boxShadow: "none" }}
                className="pl-1 w-full text-sm text-gray-900  bg-gray-50"
                placeholder="Search Anything..."
                autoComplete="off"
                required
              />

              <button
                type="submit"
                className="px-2 py-2 bg-indigo-500 text-white rounded-r-lg hover:bg-blue-400 focus:ring-2 focus:outline-none focus:ring-blue-200"
              >
                Search
              </button>
            </div>
          </form>
        </div>
        <nav className="md:ml-auto mr-auto flex flex-wrap items-right text-base justify-center">
          <Link to="/java-home" className="text-blue-900 mr-5 hover:text-gray-900 font-medium">Java</Link>
          <Link to="/spring-boot-home" className="text-blue-900 mr-5 hover:text-gray-900 font-medium">Spring Boot</Link>
          <Link to="/microservices-home" className="text-blue-900 mr-5 hover:text-gray-900 font-medium">Microservices</Link>
          <Link to="/sql-home" className="text-blue-900 mr-5 hover:text-gray-900 font-medium">SQL</Link>
        
        </nav>
        <button className="flex flex-wrap items-center ml-auto mr-auto rounded-xl bg-gray-50 border-0 py-1 px-4 focus:outline-none hover:bg-gray-200 text-base mt-4 md:mt-0"
        onClick={() => { window.location.href = '/latest-articles'; }}
        >
          Latest Articles
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1 rounded"
            viewBox="0 0 28 28"
          >
            <path
              d="M21 21L20 20"
              stroke="#000"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 21V3H5L6 11L7 19L8 27L21 21Z"
              fill="#2F88FF"
              stroke="#000"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 19H15"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 11H15"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 5H19L20 11L21 19L22 27H14H5V5Z"
              fill="#2F88FF"
              stroke="#000"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 11H17"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 19H17"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 27H18"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </header>
  );
};

export default NavigationMenu;
