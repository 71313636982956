import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const GoCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
              {/* <PDFViewer /> */}
            </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Go Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`package main

import "fmt"

func main() {
    fmt.Println("Hello, World!")
}`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`var x int = 5
var y float64 = 3.14
var c rune = 'A'
var s string = "Hello"`} />
                  </li>
                  <li>
                    <strong>Short Variable Declaration:</strong>
                    <ArticleCode code={`x := 5
y := 3.14`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/*
This is a
multi-line comment
*/`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`var x int = 10
var y float64 = 2.5
var c rune = 'A'
var b bool = true`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`var s string = "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`var arr [3]int = [3]int{1, 2, 3}`} />
                  </li>
                  <li>
                    <strong>Slices:</strong>
                    <ArticleCode code={`var slice []int = []int{1, 2, 3}`} />
                  </li>
                  <li>
                    <strong>Maps:</strong>
                    <ArticleCode code={`var m map[string]int = map[string]int{"one": 1, "two": 2}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if x > 0 {
    fmt.Println("x is positive")
} else if x == 0 {
    fmt.Println("x is zero")
} else {
    fmt.Println("x is negative")
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for i := 0; i < 5; i++ {
    fmt.Println(i)
}`} />
                  </li>
                  <li>
                    <strong>While Loops (using for):</strong>
                    <ArticleCode code={`x := 5
for x > 0 {
    fmt.Println(x)
    x--
}`} />
                  </li>
                  <li>
                    <strong>Range Loops:</strong>
                    <ArticleCode code={`arr := []int{1, 2, 3}
for i, value := range arr {
    fmt.Println(i, value)
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`func myFunction() {
    fmt.Println("Hello from a function")
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`func add(a int, b int) int {
    return a + b
}

result := add(2, 3)
fmt.Println(result)`} />
                  </li>
                  <li>
                    <strong>Multiple Return Values:</strong>
                    <ArticleCode code={`func swap(a, b string) (string, string) {
    return b, a
}

x, y := swap("hello", "world")
fmt.Println(x, y)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Structs:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Struct:</strong>
                    <ArticleCode code={`type Person struct {
    Name string
    Age  int
}`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`p := Person{Name: "John", Age: 30}
fmt.Println(p.Name, p.Age)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Methods:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Methods:</strong>
                    <ArticleCode code={`func (p Person) greet() {
    fmt.Println("Hello, my name is", p.Name)
}

p := Person{Name: "John", Age: 30}
p.greet()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Interfaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining an Interface:</strong>
                    <ArticleCode code={`type Greeter interface {
    greet()
}`} />
                  </li>
                  <li>
                    <strong>Implementing an Interface:</strong>
                    <ArticleCode code={`type Person struct {
    Name string
}

func (p Person) greet() {
    fmt.Println("Hello, my name is", p.Name)
}

var g Greeter = Person{Name: "John"}
g.greet()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Concurrency:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Goroutines:</strong>
                    <ArticleCode code={`go myFunction()`} />
                  </li>
                  <li>
                    <strong>Channels:</strong>
                    <ArticleCode code={`ch := make(chan int)
go func() {
    ch <- 42
}()
result := <-ch
fmt.Println(result)`} />
                  </li>
                  <li>
                    <strong>Select Statement:</strong>
                    <ArticleCode code={`ch1 := make(chan int)
ch2 := make(chan int)

go func() {
    ch1 <- 42
}()

go func() {
    ch2 <- 24
}()

select {
case res := <-ch1:
    fmt.Println("Received", res, "from ch1")
case res := <-ch2:
    fmt.Println("Received", res, "from ch2")
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`data, err := ioutil.ReadFile("file.txt")
if err != nil {
    log.Fatal(err)
}
fmt.Println(string(data))`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`err := ioutil.WriteFile("file.txt", []byte("Hello, World!"), 0644)
if err != nil {
    log.Fatal(err)
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Errors:</strong>
                    <ArticleCode code={`func myFunction() error {
    return errors.New("an error occurred")
}

err := myFunction()
if err != nil {
    fmt.Println(err)
}`} />
                  </li>
                  <li>
                    <strong>Custom Errors:</strong>
                    <ArticleCode code={`type MyError struct {
    Message string
}

func (e *MyError) Error() string {
    return e.Message
}

func myFunction() error {
    return &MyError{Message: "an error occurred"}
}

err := myFunction()
if err != nil {
    fmt.Println(err)
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoCheatsheet;
