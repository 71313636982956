// src/data/latestArticlesData.js
const articles = [
    {
      id: 1,
      title: "Learning React with Tailwind",
      summary: "A deep dive into using React and Tailwind CSS together for efficient web development.",
      path: "/article/react-tailwind"
    },
    {
      id: 2,
      title: "Advanced CSS Techniques",
      summary: "Explore advanced techniques in CSS for more sophisticated web designs.",
      path: "/article/advanced-css"
    }
  ];
  
  export default articles;
  