const SearchResultsPage = ({ results }) => {
  return (
    <div className="bg-gray-50 min-h-screen p-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Search Results</h2>
      <ul className="list-none space-y-2">
        {results.map((item, index) => (
          <li key={index} className="p-4 bg-white shadow rounded-lg">
            <a href={`/${item.path}`} className="text-blue-600 hover:text-blue-800 visited:text-purple-600">
              {item.title}
            </a>
            {/* Optionally show category: <span className="text-sm text-gray-500 ml-2">{item.categoryName}</span> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResultsPage;
