import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const DevOpsCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                DevOps Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Version Control (Git):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Initialize Repository:</strong>
                    <ArticleCode code={`git init`} />
                  </li>
                  <li>
                    <strong>Clone Repository:</strong>
                    <ArticleCode code={`git clone <repository-url>`} />
                  </li>
                  <li>
                    <strong>Commit Changes:</strong>
                    <ArticleCode code={`git add .
git commit -m "Commit message"`} />
                  </li>
                  <li>
                    <strong>Push Changes:</strong>
                    <ArticleCode code={`git push origin <branch-name>`} />
                  </li>
                  <li>
                    <strong>Pull Changes:</strong>
                    <ArticleCode code={`git pull origin <branch-name>`} />
                  </li>
                  <li>
                    <strong>Create Branch:</strong>
                    <ArticleCode code={`git checkout -b <branch-name>`} />
                  </li>
                  <li>
                    <strong>Merge Branch:</strong>
                    <ArticleCode code={`git checkout <target-branch>
git merge <source-branch>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Continuous Integration (CI):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup GitHub Actions:</strong>
                    <ArticleCode code={`# .github/workflows/ci.yml
name: CI

on:
  push:
    branches:
      - main
  pull_request:
    branches:
      - main

jobs:
  build:
    runs-on: ubuntu-latest

    steps:
    - uses: actions/checkout@v2
    - name: Set up Node.js
      uses: actions/setup-node@v2
      with:
        node-version: '14'
    - run: npm install
    - run: npm test`} />
                  </li>
                  <li>
                    <strong>Setup CircleCI:</strong>
                    <ArticleCode code={`# .circleci/config.yml
version: 2.1
jobs:
  build:
    docker:
      - image: circleci/node:14
    steps:
      - checkout
      - run: npm install
      - run: npm test`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Continuous Deployment (CD):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup GitHub Pages:</strong>
                    <ArticleCode code={`# .github/workflows/deploy.yml
name: Deploy

on:
  push:
    branches:
      - main

jobs:
  build:
    runs-on: ubuntu-latest

    steps:
    - uses: actions/checkout@v2
    - name: Build and Deploy
      run: |
        npm install
        npm run build
        npx gh-pages -d build`} />
                  </li>
                  <li>
                    <strong>Setup Heroku Deployment:</strong>
                    <ArticleCode code={`# .github/workflows/deploy.yml
name: Deploy

on:
  push:
    branches:
      - main

jobs:
  build:
    runs-on: ubuntu-latest

    steps:
    - uses: actions/checkout@v2
    - name: Set up Node.js
      uses: actions/setup-node@v2
      with:
        node-version: '14'
    - run: npm install
    - run: npm run build
    - name: Deploy to Heroku
      run: git push https://heroku:<API_KEY>@git.heroku.com/<APP_NAME>.git HEAD:main`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Infrastructure as Code (IaC):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Terraform:</strong>
                    <ArticleCode code={`# main.tf
provider "aws" {
  region = "us-west-2"
}

resource "aws_instance" "example" {
  ami           = "ami-0c55b159cbfafe1f0"
  instance_type = "t2.micro"
}

output "instance_ip" {
  value = aws_instance.example.public_ip
}`} />
                  </li>
                  <li>
                    <strong>Initialize and Apply:</strong>
                    <ArticleCode code={`terraform init
terraform apply`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Containerization:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Dockerfile:</strong>
                    <ArticleCode code={`# Dockerfile
FROM node:14
WORKDIR /app
COPY package*.json ./
RUN npm install
COPY . .
EXPOSE 3000
CMD ["npm", "start"]`} />
                  </li>
                  <li>
                    <strong>Build and Run Container:</strong>
                    <ArticleCode code={`docker build -t myapp .
docker run -p 3000:3000 myapp`} />
                  </li>
                  <li>
                    <strong>Docker Compose:</strong>
                    <ArticleCode code={`# docker-compose.yml
version: '3'
services:
  web:
    build: .
    ports:
      - "3000:3000"
    environment:
      - NODE_ENV=development`} />
                  </li>
                  <li>
                    <strong>Start with Docker Compose:</strong>
                    <ArticleCode code={`docker-compose up`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Monitoring and Logging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Prometheus:</strong>
                    <ArticleCode code={`# prometheus.yml
global:
  scrape_interval: 15s

scrape_configs:
  - job_name: 'node'
    static_configs:
      - targets: ['localhost:9090']`} />
                  </li>
                  <li>
                    <strong>Setup Grafana:</strong>
                    <ArticleParagraph>Download and install Grafana from the official website and configure it to use Prometheus as a data source.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Logging with ELK Stack:</strong>
                    <ArticleParagraph>Setup Elasticsearch, Logstash, and Kibana (ELK) stack for centralized logging and visualization.</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Configuration Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Ansible Playbook:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Install and start nginx
  hosts: web
  become: yes
  tasks:
    - name: Install nginx
      apt:
        name: nginx
        state: present
    - name: Start nginx
      service:
        name: nginx
        state: started`} />
                  </li>
                  <li>
                    <strong>Run Ansible Playbook:</strong>
                    <ArticleCode code={`ansible-playbook -i inventory playbook.yml`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Security and Compliance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Vault:</strong>
                    <ArticleCode code={`# Start Vault server
vault server -dev`} />
                  </li>
                  <li>
                    <strong>Store Secrets:</strong>
                    <ArticleCode code={`vault kv put secret/mysecret value="s3cr3t"`} />
                  </li>
                  <li>
                    <strong>Retrieve Secrets:</strong>
                    <ArticleCode code={`vault kv get secret/mysecret`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Networking:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Nginx Reverse Proxy:</strong>
                    <ArticleCode code={`# nginx.conf
server {
    listen 80;
    server_name example.com;

    location / {
        proxy_pass http://localhost:3000;
        proxy_set_header Host $host;
        proxy_set_header X-Real-IP $remote_addr;
        proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
        proxy_set_header X-Forwarded-Proto $scheme;
    }
}`} />
                  </li>
                  <li>
                    <strong>Start Nginx:</strong>
                    <ArticleCode code={`sudo service nginx start`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevOpsCheatsheet;
