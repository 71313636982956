import React from 'react';

const Sidebar = ({ children }) => {
  return (
    <div className="w-full p-2 ">
      <div className="sticky top-4 ">{children}</div>
    </div>
  );
};

export default Sidebar;
