import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const HttpStatusCodesCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                HTTP Status Codes Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to HTTP Status Codes:</ArticleSubtitle>
              <ArticleParagraph>
                <p>HTTP status codes are issued by a server in response to a client's request made to the server. These codes indicate whether a specific HTTP request has been successfully completed. This cheat sheet covers essential HTTP status codes to help you understand their meanings and use cases.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Informational Responses (100-199):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>100 Continue:</strong> The server has received the request headers, and the client should proceed to send the request body.
                  </li>
                  <li>
                    <strong>101 Switching Protocols:</strong> The requester has asked the server to switch protocols, and the server has agreed to do so.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Successful Responses (200-299):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>200 OK:</strong> The request has succeeded.
                  </li>
                  <li>
                    <strong>201 Created:</strong> The request has been fulfilled, and a new resource has been created.
                  </li>
                  <li>
                    <strong>202 Accepted:</strong> The request has been accepted for processing, but the processing has not been completed.
                  </li>
                  <li>
                    <strong>204 No Content:</strong> The server successfully processed the request, and is not returning any content.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Redirection Messages (300-399):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>301 Moved Permanently:</strong> The URL of the requested resource has been changed permanently. The new URL is provided in the response.
                  </li>
                  <li>
                    <strong>302 Found:</strong> The URL of the requested resource has been changed temporarily.
                  </li>
                  <li>
                    <strong>304 Not Modified:</strong> Indicates that the resource has not been modified since the last request.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Client Error Responses (400-499):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>400 Bad Request:</strong> The server cannot or will not process the request due to an apparent client error.
                  </li>
                  <li>
                    <strong>401 Unauthorized:</strong> Authentication is required and has failed or has not yet been provided.
                  </li>
                  <li>
                    <strong>403 Forbidden:</strong> The request was valid, but the server is refusing action. The user might not have the necessary permissions for a resource.
                  </li>
                  <li>
                    <strong>404 Not Found:</strong> The requested resource could not be found but may be available in the future.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Server Error Responses (500-599):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>500 Internal Server Error:</strong> An unexpected condition was encountered, and no more specific message is suitable.
                  </li>
                  <li>
                    <strong>501 Not Implemented:</strong> The server either does not recognize the request method, or it lacks the ability to fulfill the request.
                  </li>
                  <li>
                    <strong>502 Bad Gateway:</strong> The server was acting as a gateway or proxy and received an invalid response from the upstream server.
                  </li>
                  <li>
                    <strong>503 Service Unavailable:</strong> The server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Proper Status Codes:</strong> Always use the appropriate status codes for responses to make debugging easier and to communicate effectively with the client.</li>
                  <li><strong>Redirection Handling:</strong> Use 301 for permanent redirection and 302 for temporary redirection.</li>
                  <li><strong>Error Handling:</strong> Implement proper error handling and provide meaningful messages for client and server errors.</li>
                  <li><strong>Security:</strong> Use 401 for unauthorized access and 403 for forbidden requests to enhance security.</li>
                  <li><strong>Monitoring:</strong> Regularly monitor and log status codes to identify and fix issues promptly.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default HttpStatusCodesCheatsheet;
