import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const WebSocketCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                WebSocket Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to WebSockets:</ArticleSubtitle>
              <ArticleParagraph>
                <p>WebSockets provide a full-duplex communication channel over a single, long-lived connection between a client and a server. This cheat sheet covers essential commands and operations to help you get started with WebSockets for real-time communication.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setting Up WebSockets:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Client-side WebSocket:</strong>
                    <ArticleCode code={`const socket = new WebSocket('ws://example.com/socket');`} />
                  </li>
                  <li>
                    <strong>Server-side WebSocket (Node.js with ws library):</strong>
                    <ArticleCode code={`const WebSocket = require('ws');
const server = new WebSocket.Server({ port: 8080 });

server.on('connection', socket => {
  console.log('Client connected');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Send a Message:</strong>
                    <ArticleCode code={`socket.send('Hello, Server!');`} />
                  </li>
                  <li>
                    <strong>Receive a Message:</strong>
                    <ArticleCode code={`socket.onmessage = event => {
  console.log('Message from server ', event.data);
};`} />
                  </li>
                  <li>
                    <strong>Handle Connection Open:</strong>
                    <ArticleCode code={`socket.onopen = event => {
  console.log('Connection opened');
};`} />
                  </li>
                  <li>
                    <strong>Handle Connection Close:</strong>
                    <ArticleCode code={`socket.onclose = event => {
  console.log('Connection closed');
};`} />
                  </li>
                  <li>
                    <strong>Handle Errors:</strong>
                    <ArticleCode code={`socket.onerror = error => {
  console.error('WebSocket error: ', error);
};`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Broadcast to All Clients (Node.js):</strong>
                    <ArticleCode code={`server.on('connection', socket => {
  socket.on('message', message => {
    server.clients.forEach(client => {
      if (client.readyState === WebSocket.OPEN) {
        client.send(message);
      }
    });
  });
});`} />
                  </li>
                  <li>
                    <strong>Ping/Pong for Connection Health (Node.js):</strong>
                    <ArticleCode code={`const interval = setInterval(() => {
  server.clients.forEach(client => {
    if (client.isAlive === false) return client.terminate();

    client.isAlive = false;
    client.ping();
  });
}, 30000);

server.on('connection', socket => {
  socket.isAlive = true;
  socket.on('pong', () => socket.isAlive = true);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Security:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Secure WebSocket (wss):</strong>
                    <ArticleCode code={`const socket = new WebSocket('wss://example.com/socket');`} />
                  </li>
                  <li>
                    <strong>Handling CORS:</strong>
                    <ArticleParagraph>
                      <p>Ensure your server is configured to handle Cross-Origin Resource Sharing (CORS) if you're connecting from a different origin.</p>
                    </ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Reconnect Logic:</strong> Implement reconnect logic in case of connection drops to maintain a stable connection.</li>
                  <li><strong>Keep-alive Messages:</strong> Send periodic keep-alive messages to ensure the connection remains open.</li>
                  <li><strong>Message Size Management:</strong> Handle large messages appropriately by breaking them into smaller chunks if necessary.</li>
                  <li><strong>Error Handling:</strong> Implement robust error handling to manage unexpected issues gracefully.</li>
                  <li><strong>Use a WebSocket Library:</strong> Use libraries like Socket.IO for additional features and easier implementation.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebSocketCheatsheet;
