import React from "react";
import articles from "../../data/latestArticlesData"; // Ensure the path is correct

function LatestArticles() {
  return (
    <div className="flex flex-col items-start justify-center mt-10 mx-auto w-full max-w-4xl px-4">
      {articles.map(article => (
        <a 
          key={article.id} 
          href={article.path} // Use dynamic path for each article
          className="text-lg text-blue-600 hover:text-blue-800 font-semibold py-2 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out w-full"
        >
          {article.title}
        </a>
      ))}
    </div>
  );
}

export default LatestArticles;
