import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const CSharpCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
              {/* <PDFViewer /> */}
            </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                C# Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`using System;

class Program {
    static void Main() {
        Console.WriteLine("Hello, World!");
    }
}`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`int x = 5;
double y = 3.14;
char c = 'A';
string s = "Hello";`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/* This is a
   multi-line comment */`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`int x = 10;
double y = 2.5;
char c = 'A';
bool b = true;`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`string s = "Hello, World!";`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if (x > 0) {
    Console.WriteLine("x is positive");
} else if (x == 0) {
    Console.WriteLine("x is zero");
} else {
    Console.WriteLine("x is negative");
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for (int i = 0; i < 5; i++) {
    Console.WriteLine(i);
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`while (x > 0) {
    Console.WriteLine(x);
    x--;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`void MyFunction() {
    Console.WriteLine("Hello from a function");
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`int Add(int a, int b) {
    return a + b;
}

int result = Add(2, 3);
Console.WriteLine(result);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes and Objects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`class MyClass {
    public int MyNumber;
    public void MyMethod() {
        Console.WriteLine("Hello, World!");
    }
}`} />
                  </li>
                  <li>
                    <strong>Creating an Object:</strong>
                    <ArticleCode code={`MyClass obj = new MyClass();
obj.MyNumber = 5;
obj.MyMethod();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Base and Derived Classes:</strong>
                    <ArticleCode code={`class Base {
    public void Display() {
        Console.WriteLine("Base class");
    }
}

class Derived : Base {
    public void Show() {
        Console.WriteLine("Derived class");
    }
}`} />
                  </li>
                  <li>
                    <strong>Using Inheritance:</strong>
                    <ArticleCode code={`Derived obj = new Derived();
obj.Display();
obj.Show();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Exception Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Try...Catch:</strong>
                    <ArticleCode code={`try {
    int result = 10 / 0;
} catch (DivideByZeroException e) {
    Console.WriteLine("Exception caught: " + e.Message);
}`} />
                  </li>
                  <li>
                    <strong>Finally:</strong>
                    <ArticleCode code={`try {
    int result = 10 / 0;
} catch (DivideByZeroException e) {
    Console.WriteLine("Exception caught: " + e.Message);
} finally {
    Console.WriteLine("This will always execute");
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>LINQ:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic LINQ Query:</strong>
                    <ArticleCode code={`int[] numbers = { 5, 10, 8, 3, 6, 12 };
var query = from num in numbers
            where num % 2 == 0
            orderby num
            select num;

foreach (int num in query) {
    Console.WriteLine(num);
}`} />
                  </li>
                  <li>
                    <strong>Lambda Expressions:</strong>
                    <ArticleCode code={`var evens = numbers.Where(n => n % 2 == 0).OrderBy(n => n);

foreach (int num in evens) {
    Console.WriteLine(num);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Collections:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Lists:</strong>
                    <ArticleCode code={`List<int> list = new List<int>() {1, 2, 3};
list.Add(4);
foreach (int i in list) {
    Console.WriteLine(i);
}`} />
                  </li>
                  <li>
                    <strong>Dictionaries:</strong>
                    <ArticleCode code={`Dictionary<int, string> dict = new Dictionary<int, string>();
dict[1] = "one";
dict[2] = "two";
foreach (var kvp in dict) {
    Console.WriteLine(kvp.Key + ": " + kvp.Value);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Async/Await:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Asynchronous Methods:</strong>
                    <ArticleCode code={`async Task<int> FetchDataAsync() {
    await Task.Delay(1000);
    return 42;
}

async void DisplayData() {
    int result = await FetchDataAsync();
    Console.WriteLine(result);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default CSharpCheatsheet;
