import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const TerraformCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Terraform Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Terraform:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update && sudo apt-get install -y gnupg software-properties-common curl
curl -fsSL https://apt.releases.hashicorp.com/gpg | sudo apt-key add -
sudo apt-add-repository "deb [arch=amd64] https://apt.releases.hashicorp.com $(lsb_release -cs) main"
sudo apt-get update && sudo apt-get install terraform

# On macOS:
brew install terraform`} />
                  </li>
                  <li>
                    <strong>Verify Installation:</strong>
                    <ArticleCode code={`terraform -v`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Initialize Terraform Configuration:</strong>
                    <ArticleCode code={`terraform init`} />
                  </li>
                  <li>
                    <strong>Validate Configuration:</strong>
                    <ArticleCode code={`terraform validate`} />
                  </li>
                  <li>
                    <strong>Plan Deployment:</strong>
                    <ArticleCode code={`terraform plan`} />
                  </li>
                  <li>
                    <strong>Apply Configuration:</strong>
                    <ArticleCode code={`terraform apply`} />
                  </li>
                  <li>
                    <strong>Destroy Resources:</strong>
                    <ArticleCode code={`terraform destroy`} />
                  </li>
                  <li>
                    <strong>Show State:</strong>
                    <ArticleCode code={`terraform show`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Configuration Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Provider:</strong>
                    <ArticleCode code={`provider "aws" {
  region = "us-west-2"
}`} />
                  </li>
                  <li>
                    <strong>Resource:</strong>
                    <ArticleCode code={`resource "aws_instance" "example" {
  ami           = "ami-0c55b159cbfafe1f0"
  instance_type = "t2.micro"
}`} />
                  </li>
                  <li>
                    <strong>Variable:</strong>
                    <ArticleCode code={`variable "instance_type" {
  description = "Type of instance to use"
  default     = "t2.micro"
}`} />
                  </li>
                  <li>
                    <strong>Output:</strong>
                    <ArticleCode code={`output "instance_id" {
  value = aws_instance.example.id
}`} />
                  </li>
                  <li>
                    <strong>Data Source:</strong>
                    <ArticleCode code={`data "aws_ami" "example" {
  most_recent = true
  owners      = ["self"]
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>State Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Resources in State:</strong>
                    <ArticleCode code={`terraform state list`} />
                  </li>
                  <li>
                    <strong>Show Specific Resource in State:</strong>
                    <ArticleCode code={`terraform state show aws_instance.example`} />
                  </li>
                  <li>
                    <strong>Remove Resource from State:</strong>
                    <ArticleCode code={`terraform state rm aws_instance.example`} />
                  </li>
                  <li>
                    <strong>Move Resource in State:</strong>
                    <ArticleCode code={`terraform state mv aws_instance.example aws_instance.new_example`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Module:</strong>
                    <ArticleCode code={`module "vpc" {
  source = "./modules/vpc"
  cidr_block = "10.0.0.0/16"
}`} />
                  </li>
                  <li>
                    <strong>Module Source from Registry:</strong>
                    <ArticleCode code={`module "vpc" {
  source  = "terraform-aws-modules/vpc/aws"
  version = "2.70.0"
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Workspaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Workspace:</strong>
                    <ArticleCode code={`terraform workspace new dev`} />
                  </li>
                  <li>
                    <strong>List Workspaces:</strong>
                    <ArticleCode code={`terraform workspace list`} />
                  </li>
                  <li>
                    <strong>Select Workspace:</strong>
                    <ArticleCode code={`terraform workspace select dev`} />
                  </li>
                  <li>
                    <strong>Delete Workspace:</strong>
                    <ArticleCode code={`terraform workspace delete dev`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Provisioners:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Local Exec Provisioner:</strong>
                    <ArticleCode code={`resource "aws_instance" "example" {
  ami           = "ami-0c55b159cbfafe1f0"
  instance_type = "t2.micro"

  provisioner "local-exec" {
    command = "echo $\{aws_instance.example.id}"
  }
}`} />
                  </li>
                  <li>
                    <strong>Remote Exec Provisioner:</strong>
                    <ArticleCode code={`resource "aws_instance" "example" {
  ami           = "ami-0c55b159cbfafe1f0"
  instance_type = "t2.micro"

  provisioner "remote-exec" {
    inline = [
      "sudo apt-get update",
      "sudo apt-get install -y nginx"
    ]

    connection {
      type     = "ssh"
      user     = "ubuntu"
      private_key = file("~/.ssh/id_rsa")
      host     = self.public_ip
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Remote State:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Configure Remote Backend:</strong>
                    <ArticleCode code={`terraform {
  backend "s3" {
    bucket = "my-terraform-state"
    key    = "global/s3/terraform.tfstate"
    region = "us-west-2"
  }
}`} />
                  </li>
                  <li>
                    <strong>Initialize Backend:</strong>
                    <ArticleCode code={`terraform init`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Useful Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Format Configuration:</strong>
                    <ArticleCode code={`terraform fmt`} />
                  </li>
                  <li>
                    <strong>Generate Graph:</strong>
                    <ArticleCode code={`terraform graph | dot -Tpng > graph.png`} />
                  </li>
                  <li>
                    <strong>Get Documentation:</strong>
                    <ArticleCode code={`terraform-docs .`} />
                  </li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default TerraformCheatsheet;
