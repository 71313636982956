import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const PandasCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Pandas Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Pandas:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Pandas is an open-source data analysis and manipulation library for Python. This cheat sheet covers essential commands and operations to help you get started with Pandas for data manipulation and analysis.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Pandas:</strong>
                    <ArticleCode code={`pip install pandas`} />
                  </li>
                  <li>
                    <strong>Import Pandas:</strong>
                    <ArticleCode code={`import pandas as pd`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Series:</strong>
                    <ArticleCode code={`import pandas as pd

s = pd.Series([1, 3, 5, 7, 9])
print(s)`} />
                  </li>
                  <li>
                    <strong>DataFrame:</strong>
                    <ArticleCode code={`import pandas as pd

data = {'Name': ['John', 'Anna', 'Peter'],
        'Age': [28, 24, 35]}
df = pd.DataFrame(data)
print(df)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Input/Output:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Read CSV:</strong>
                    <ArticleCode code={`df = pd.read_csv('file.csv')`} />
                  </li>
                  <li>
                    <strong>Write CSV:</strong>
                    <ArticleCode code={`df.to_csv('file.csv', index=False)`} />
                  </li>
                  <li>
                    <strong>Read Excel:</strong>
                    <ArticleCode code={`df = pd.read_excel('file.xlsx', sheet_name='Sheet1')`} />
                  </li>
                  <li>
                    <strong>Write Excel:</strong>
                    <ArticleCode code={`df.to_excel('file.xlsx', sheet_name='Sheet1', index=False)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Inspection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Head and Tail:</strong>
                    <ArticleCode code={`df.head()  # First 5 rows
df.tail()  # Last 5 rows`} />
                  </li>
                  <li>
                    <strong>Info:</strong>
                    <ArticleCode code={`df.info()`} />
                  </li>
                  <li>
                    <strong>Describe:</strong>
                    <ArticleCode code={`df.describe()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Selection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Select Column:</strong>
                    <ArticleCode code={`df['column_name']`} />
                  </li>
                  <li>
                    <strong>Select Multiple Columns:</strong>
                    <ArticleCode code={`df[['column1', 'column2']]`} />
                  </li>
                  <li>
                    <strong>Select Row by Index:</strong>
                    <ArticleCode code={`df.iloc[0]  # First row`} />
                  </li>
                  <li>
                    <strong>Select Row by Condition:</strong>
                    <ArticleCode code={`df[df['column_name'] > value]`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Manipulation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Sort Data:</strong>
                    <ArticleCode code={`df.sort_values('column_name')`} />
                  </li>
                  <li>
                    <strong>Filter Data:</strong>
                    <ArticleCode code={`df[df['column_name'] > value]`} />
                  </li>
                  <li>
                    <strong>Add Column:</strong>
                    <ArticleCode code={`df['new_column'] = value`} />
                  </li>
                  <li>
                    <strong>Drop Column:</strong>
                    <ArticleCode code={`df.drop('column_name', axis=1, inplace=True)`} />
                  </li>
                  <li>
                    <strong>Group By:</strong>
                    <ArticleCode code={`df.groupby('column_name').mean()`} />
                  </li>
                  <li>
                    <strong>Merge DataFrames:</strong>
                    <ArticleCode code={`pd.merge(df1, df2, on='column_name')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Cleaning:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Drop Missing Values:</strong>
                    <ArticleCode code={`df.dropna(inplace=True)`} />
                  </li>
                  <li>
                    <strong>Fill Missing Values:</strong>
                    <ArticleCode code={`df.fillna(value, inplace=True)`} />
                  </li>
                  <li>
                    <strong>Replace Values:</strong>
                    <ArticleCode code={`df['column_name'].replace('old_value', 'new_value', inplace=True)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Visualization:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Plotting:</strong>
                    <ArticleCode code={`df.plot(kind='line')  # Line plot
df.plot(kind='bar')  # Bar plot
df.plot(kind='hist')  # Histogram`} />
                  </li>
                  <li>
                    <strong>Show Plot:</strong>
                    <ArticleCode code={`import matplotlib.pyplot as plt
plt.show()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Consistent Data Types:</strong> Ensure columns have consistent data types for easier manipulation.</li>
                  <li><strong>Handle Missing Data:</strong> Appropriately handle missing data to avoid analysis errors.</li>
                  <li><strong>Use Vectorized Operations:</strong> Leverage Pandas vectorized operations for better performance.</li>
                  <li><strong>Chain Methods:</strong> Use method chaining for cleaner and more readable code.</li>
                  <li><strong>Document Your Code:</strong> Add comments and docstrings to explain your data processing steps.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PandasCheatsheet;
