import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const VueCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Vue.js Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Include Vue.js:</strong>
                    <ArticleCode code={`<script src="https://cdn.jsdelivr.net/npm/vue@2"></script>`} />
                  </li>
                  <li>
                    <strong>Initialize App:</strong>
                    <ArticleCode code={`<div id="app">
  {{ message }}
</div>

<script>
new Vue({
  el: '#app',
  data: {
    message: 'Hello, World!'
  }
});
</script>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Directives:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>v-model:</strong>
                    <ArticleCode code={`<input v-model="name">
<p>Hello, {{ name }}!</p>`} />
                  </li>
                  <li>
                    <strong>v-for:</strong>
                    <ArticleCode code={`<ul>
  <li v-for="item in items" :key="item">{{ item }}</li>
</ul>`} />
                  </li>
                  <li>
                    <strong>v-if:</strong>
                    <ArticleCode code={`<div v-if="isVisible">This is visible</div>`} />
                  </li>
                  <li>
                    <strong>v-show:</strong>
                    <ArticleCode code={`<div v-show="isShown">This is shown</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Components:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Component:</strong>
                    <ArticleCode code={`Vue.component('my-component', {
  template: '<div>A custom component!</div>'
});

new Vue({ el: '#app' });`} />
                  </li>
                  <li>
                    <strong>Props:</strong>
                    <ArticleCode code={`Vue.component('my-component', {
  props: ['message'],
  template: '<div>{{ message }}</div>'
});

new Vue({ el: '#app' });`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Events:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling Events:</strong>
                    <ArticleCode code={`<button v-on:click="doSomething">Click me</button>`} />
                  </li>
                  <li>
                    <strong>Methods:</strong>
                    <ArticleCode code={`new Vue({
  el: '#app',
  methods: {
    doSomething: function () {
      alert('You clicked me!');
    }
  }
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Computed Properties:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Computed Properties:</strong>
                    <ArticleCode code={`new Vue({
  el: '#app',
  data: {
    firstName: 'John',
    lastName: 'Doe'
  },
  computed: {
    fullName: function () {
      return this.firstName + ' ' + this.lastName;
    }
  }
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Watchers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Watchers:</strong>
                    <ArticleCode code={`new Vue({
  el: '#app',
  data: {
    question: '',
    answer: 'I cannot give you an answer until you ask a question!'
  },
  watch: {
    question: function (newQuestion, oldQuestion) {
      this.answer = 'Thinking...';
      this.getAnswer();
    }
  },
  methods: {
    getAnswer: _.debounce(
      function () {
        if (this.question.indexOf('?') === -1) {
          this.answer = 'Questions usually contain a question mark. ;-)';
          return;
        }
        this.answer = 'Yes!';
      },
      500
    )
  }
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Class and Style Bindings:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Class Bindings:</strong>
                    <ArticleCode code={`<div v-bind:class="{ active: isActive }"></div>`} />
                  </li>
                  <li>
                    <strong>Style Bindings:</strong>
                    <ArticleCode code={`<div v-bind:style="{ color: activeColor, fontSize: fontSize + 'px' }"></div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Form Input Bindings:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Input:</strong>
                    <ArticleCode code={`<input v-model="message">`} />
                  </li>
                  <li>
                    <strong>Checkbox:</strong>
                    <ArticleCode code={`<input type="checkbox" v-model="checked">`} />
                  </li>
                  <li>
                    <strong>Radio:</strong>
                    <ArticleCode code={`<input type="radio" v-model="picked" value="One">
<input type="radio" v-model="picked" value="Two">`} />
                  </li>
                  <li>
                    <strong>Select:</strong>
                    <ArticleCode code={`<select v-model="selected">
  <option disabled value="">Please select one</option>
  <option>A</option>
  <option>B</option>
  <option>C</option>
</select>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Directives:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Custom Directives:</strong>
                    <ArticleCode code={`Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
});

new Vue({ el: '#app' });`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Mixins:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Mixins:</strong>
                    <ArticleCode code={`var myMixin = {
  created: function () {
    this.hello();
  },
  methods: {
    hello: function () {
      console.log('hello from mixin!');
    }
  }
};

var Component = Vue.extend({
  mixins: [myMixin]
});

new Component();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Plugins:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Plugins:</strong>
                    <ArticleCode code={`Vue.use(MyPlugin);

new Vue({ el: '#app' });`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Vue Router:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up Routes:</strong>
                    <ArticleCode code={`import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/foo', component: Foo },
  { path: '/bar', component: Bar }
];

const router = new VueRouter({
  routes
});

new Vue({
  router
}).$mount('#app');`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Vuex:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>State Management:</strong>
                    <ArticleCode code={`import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    increment (state) {
      state.count++;
    }
  }
});

new Vue({
  store,
  computed: {
    count () {
      return this.$store.state.count;
    }
  },
  methods: {
    increment () {
      this.$store.commit('increment');
    }
  }
}).$mount('#app');`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default VueCheatsheet;
