import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const BackendDevCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Backend Development Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Node.js Server Setup:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Server:</strong>
                    <ArticleCode code={`const http = require('http');

const hostname = '127.0.0.1';
const port = 3000;

const server = http.createServer((req, res) => {
  res.statusCode = 200;
  res.setHeader('Content-Type', 'text/plain');
  res.end('Hello World');
});

server.listen(port, hostname, () => {
  console.log(\`Server running at http://\${hostname}:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Express.js Setup:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Express:</strong>
                    <ArticleCode code={`npm install express`} />
                  </li>
                  <li>
                    <strong>Basic Express Server:</strong>
                    <ArticleCode code={`const express = require('express');
const app = express();
const port = 3000;

app.get('/', (req, res) => {
  res.send('Hello World!');
});

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database Connection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>MongoDB Connection:</strong>
                    <ArticleCode code={`const mongoose = require('mongoose');

mongoose.connect('mongodb://localhost:27017/mydatabase', {
  useNewUrlParser: true,
  useUnifiedTopology: true
});

const db = mongoose.connection;
db.on('error', console.error.bind(console, 'connection error:'));
db.once('open', function() {
  console.log('Connected to MongoDB');
});`} />
                  </li>
                  <li>
                    <strong>MySQL Connection:</strong>
                    <ArticleCode code={`const mysql = require('mysql');

const connection = mysql.createConnection({
  host: 'localhost',
  user: 'root',
  password: '',
  database: 'mydatabase'
});

connection.connect((err) => {
  if (err) throw err;
  console.log('Connected to MySQL');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>RESTful API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic API Routes:</strong>
                    <ArticleCode code={`const express = require('express');
const app = express();
const port = 3000;

app.use(express.json());

app.get('/api/items', (req, res) => {
  res.json({ message: 'GET all items' });
});

app.post('/api/items', (req, res) => {
  res.json({ message: 'POST a new item' });
});

app.get('/api/items/:id', (req, res) => {
  res.json({ message: \`GET item with ID: \${req.params.id}\` });
});

app.put('/api/items/:id', (req, res) => {
  res.json({ message: \`PUT update item with ID: \${req.params.id}\` });
});

app.delete('/api/items/:id', (req, res) => {
  res.json({ message: \`DELETE item with ID: \${req.params.id}\` });
});

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>JWT Authentication:</strong>
                    <ArticleCode code={`const express = require('express');
const jwt = require('jsonwebtoken');
const app = express();
const port = 3000;

app.use(express.json());

const secretKey = 'your_secret_key';

app.post('/login', (req, res) => {
  const { username, password } = req.body;
  const token = jwt.sign({ username }, secretKey, { expiresIn: '1h' });
  res.json({ token });
});

app.get('/protected', verifyToken, (req, res) => {
  res.json({ message: 'This is a protected route' });
});

function verifyToken(req, res, next) {
  const bearerHeader = req.headers['authorization'];
  if (typeof bearerHeader !== 'undefined') {
    const bearer = bearerHeader.split(' ');
    const bearerToken = bearer[1];
    req.token = bearerToken;
    jwt.verify(req.token, secretKey, (err, authData) => {
      if (err) {
        res.sendStatus(403);
      } else {
        next();
      }
    });
  } else {
    res.sendStatus(403);
  }
}

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Middleware for Error Handling:</strong>
                    <ArticleCode code={`app.use((err, req, res, next) => {
  console.error(err.stack);
  res.status(500).send('Something broke!');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Environment Variables:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using dotenv:</strong>
                    <ArticleCode code={`// .env
PORT=3000
DB_HOST=localhost
DB_USER=root
DB_PASS=s1mpl3

// app.js
require('dotenv').config();
const express = require('express');
const app = express();
const port = process.env.PORT;

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Testing with Mocha and Chai:</strong>
                    <ArticleCode code={`const chai = require('chai');
const chaiHttp = require('chai-http');
const app = require('./app');

chai.use(chaiHttp);
chai.should();

describe("GET /", () => {
  it("should get homepage", (done) => {
    chai.request(app)
      .get('/')
      .end((err, res) => {
        res.should.have.status(200);
        done();
      });
  });
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File Uploads:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling File Uploads with Multer:</strong>
                    <ArticleCode code={`const express = require('express');
const multer = require('multer');
const upload = multer({ dest: 'uploads/' });
const app = express();
const port = 3000;

app.post('/upload', upload.single('file'), (req, res) => {
  res.send('File uploaded');
});

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>WebSockets:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Socket.io:</strong>
                    <ArticleCode code={`const express = require('express');
const http = require('http');
const socketIo = require('socket.io');
const app = express();
const server = http.createServer(app);
const io = socketIo(server);
const port = 3000;

io.on('connection', (socket) => {
  console.log('a user connected');
  socket.on('disconnect', () => {
    console.log('user disconnected');
  });
});

app.get('/', (req, res) => {
  res.sendFile(__dirname + '/index.html');
});

server.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackendDevCheatsheet;
