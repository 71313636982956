import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const SEOBasicsCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                SEO Basics Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to SEO:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Search Engine Optimization (SEO) is the practice of optimizing your website to increase its visibility when people search for products or services related to your business on search engines like Google. This cheat sheet covers essential SEO techniques to help you improve your website's ranking and attract more visitors.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Keyword Research:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Identify Keywords:</strong> Use tools like Google Keyword Planner, Ahrefs, or SEMrush to find relevant keywords for your niche.
                  </li>
                  <li>
                    <strong>Long-Tail Keywords:</strong> Focus on long-tail keywords as they often have less competition and higher conversion rates.
                  </li>
                  <li>
                    <strong>Analyze Competitors:</strong> Look at the keywords your competitors are targeting to find opportunities.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>On-Page SEO:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Title Tags:</strong> Include your primary keyword in the title tag and keep it under 60 characters.
                    <ArticleCode code={`<title>Primary Keyword - Brand Name</title>`} />
                  </li>
                  <li>
                    <strong>Meta Descriptions:</strong> Write compelling meta descriptions with keywords to improve click-through rates.
                    <ArticleCode code={`<meta name="description" content="Brief description with keywords">`} />
                  </li>
                  <li>
                    <strong>Header Tags:</strong> Use H1 tags for main titles and H2-H6 tags for subheadings. Include keywords naturally.
                    <ArticleCode code={`<h1>Main Title with Keyword</h1>`} />
                  </li>
                  <li>
                    <strong>URL Structure:</strong> Create SEO-friendly URLs by including keywords and keeping them short.
                    <ArticleCode code={`https://example.com/primary-keyword`} />
                  </li>
                  <li>
                    <strong>Internal Linking:</strong> Link to other relevant pages on your site to improve navigation and authority.
                    <ArticleCode code={`<a href="/related-page">Related Page</a>`} />
                  </li>
                  <li>
                    <strong>Image Optimization:</strong> Use descriptive filenames and alt tags for images.
                    <ArticleCode code={`<img src="keyword-image.jpg" alt="Description with keyword">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Content Creation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>High-Quality Content:</strong> Create valuable, engaging, and original content that addresses the needs of your audience.
                  </li>
                  <li>
                    <strong>Keyword Density:</strong> Use keywords naturally throughout your content without overstuffing.
                  </li>
                  <li>
                    <strong>Content Length:</strong> Longer content tends to perform better in search rankings. Aim for at least 1000 words for in-depth articles.
                  </li>
                  <li>
                    <strong>Regular Updates:</strong> Keep your content fresh by regularly updating and adding new information.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Technical SEO:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Site Speed:</strong> Optimize your website's loading speed for better user experience and rankings.
                  </li>
                  <li>
                    <strong>Mobile Optimization:</strong> Ensure your website is mobile-friendly and responsive.
                  </li>
                  <li>
                    <strong>XML Sitemap:</strong> Create and submit an XML sitemap to search engines for better indexing.
                    <ArticleCode code={`https://example.com/sitemap.xml`} />
                  </li>
                  <li>
                    <strong>Robots.txt:</strong> Use a robots.txt file to manage search engine crawling.
                    <ArticleCode code={`User-agent: *\nDisallow: /private/`} />
                  </li>
                  <li>
                    <strong>Canonical Tags:</strong> Use canonical tags to avoid duplicate content issues.
                    <ArticleCode code={`<link rel="canonical" href="https://example.com/page">`} />
                  </li>
                  <li>
                    <strong>Structured Data:</strong> Implement structured data (schema markup) to help search engines understand your content.
                    <ArticleCode code={`<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "Title of the Article",
  "image": "https://example.com/photo.jpg",
  "datePublished": "2024-01-01",
  "author": {
    "@type": "Person",
    "name": "Author Name"
  }
}
</script>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Off-Page SEO:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Backlinks:</strong> Earn high-quality backlinks from reputable websites to improve domain authority.
                  </li>
                  <li>
                    <strong>Social Media:</strong> Promote your content on social media platforms to increase visibility and drive traffic.
                  </li>
                  <li>
                    <strong>Guest Blogging:</strong> Write guest posts for other blogs in your industry to gain exposure and backlinks.
                  </li>
                  <li>
                    <strong>Online Directories:</strong> Submit your website to relevant online directories and listings.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Monitoring and Analytics:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Google Analytics:</strong> Use Google Analytics to track website traffic and user behavior.
                  </li>
                  <li>
                    <strong>Google Search Console:</strong> Monitor your website's performance in search results and fix issues.
                  </li>
                  <li>
                    <strong>SEO Tools:</strong> Utilize SEO tools like Ahrefs, SEMrush, and Moz to analyze and improve your SEO efforts.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Stay Updated:</strong> Keep up with the latest SEO trends and algorithm updates to maintain your rankings.</li>
                  <li><strong>Focus on User Experience:</strong> Prioritize user experience to improve engagement and reduce bounce rates.</li>
                  <li><strong>Consistent Branding:</strong> Maintain consistent branding across all online platforms.</li>
                  <li><strong>Analyze Competitors:</strong> Regularly analyze competitors to identify opportunities and areas for improvement.</li>
                  <li><strong>Be Patient:</strong> SEO is a long-term strategy. Results may take time, so be patient and persistent.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SEOBasicsCheatsheet;
