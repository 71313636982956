import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const ReactCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                React.js Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create React App:</strong>
                    <ArticleCode code={`npx create-react-app my-app
cd my-app
npm start`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>JSX:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic JSX:</strong>
                    <ArticleCode code={`const element = <h1>Hello, world!</h1>;`} />
                  </li>
                  <li>
                    <strong>Embedding Expressions:</strong>
                    <ArticleCode code={`const name = "John";
const element = <h1>Hello, {name}!</h1>;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Components:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Functional Component:</strong>
                    <ArticleCode code={`function Welcome(props) {
  return <h1>Hello, {props.name}</h1>;
}`} />
                  </li>
                  <li>
                    <strong>Class Component:</strong>
                    <ArticleCode code={`class Welcome extends React.Component {
  render() {
    return <h1>Hello, {this.props.name}</h1>;
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>State and Props:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using State:</strong>
                    <ArticleCode code={`class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  render() {
    return <h1>{this.state.date.toLocaleTimeString()}</h1>;
  }
}`} />
                  </li>
                  <li>
                    <strong>Using Props:</strong>
                    <ArticleCode code={`function Welcome(props) {
  return <h1>Hello, {props.name}</h1>;
}

const element = <Welcome name="Sara" />;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Lifecycle Methods:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>ComponentDidMount:</strong>
                    <ArticleCode code={`componentDidMount() {
  this.timerID = setInterval(
    () => this.tick(),
    1000
  );
}`} />
                  </li>
                  <li>
                    <strong>ComponentWillUnmount:</strong>
                    <ArticleCode code={`componentWillUnmount() {
  clearInterval(this.timerID);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Handling Events:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling Click Events:</strong>
                    <ArticleCode code={`class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return (
      <button onClick={this.handleClick}>
        {this.state.isToggleOn ? 'ON' : 'OFF'}
      </button>
    );
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Conditional Rendering:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using If Statements:</strong>
                    <ArticleCode code={`function Greeting(props) {
  if (props.isLoggedIn) {
    return <h1>Welcome back!</h1>;
  }
  return <h1>Please sign up.</h1>;
}`} />
                  </li>
                  <li>
                    <strong>Using Ternary Operator:</strong>
                    <ArticleCode code={`render() {
  const isLoggedIn = this.state.isLoggedIn;
  return (
    <div>
      {isLoggedIn ? <LogoutButton /> : <LoginButton />}
    </div>
  );
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Lists and Keys:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Rendering Lists:</strong>
                    <ArticleCode code={`const numbers = [1, 2, 3, 4, 5];
const listItems = numbers.map((number) =>
  <li key={number.toString()}>
    {number}
  </li>
);

ReactDOM.render(
  <ul>{listItems}</ul>,
  document.getElementById('root')
);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Forms:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling Form Submission:</strong>
                    <ArticleCode code={`class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Name:
          <input type="text" value={this.state.value} onChange={this.handleChange} />
        </label>
        <input type="submit" value="Submit" />
      </form>
    );
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Lifting State Up:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Lifting State:</strong>
                    <ArticleCode code={`class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {temperature: ''};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({temperature: e.target.value});
  }

  render() {
    const temperature = this.state.temperature;
    return (
      <fieldset>
        <legend>Enter temperature in Celsius:</legend>
        <input
          value={temperature}
          onChange={this.handleChange} />
        <BoilingVerdict
          celsius={parseFloat(temperature)} />
      </fieldset>
    );
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Composition vs Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Composition:</strong>
                    <ArticleCode code={`function FancyBorder(props) {
  return (
    <div className={'FancyBorder FancyBorder-' + props.color}>
      {props.children}
    </div>
  );
}

function WelcomeDialog() {
  return (
    <FancyBorder color="blue">
      <h1 className="Dialog-title">
        Welcome
      </h1>
      <p className="Dialog-message">
        Thank you for visiting our spacecraft!
      </p>
    </FancyBorder>
  );
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>React Hooks:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>useState:</strong>
                    <ArticleCode code={`import React, { useState } from 'react';

function Example() {
  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}`} />
                  </li>
                  <li>
                    <strong>useEffect:</strong>
                    <ArticleCode code={`import React, { useState, useEffect } from 'react';

function Example() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    document.title = \`You clicked \${count} times\`;
  }, [count]);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactCheatsheet;
