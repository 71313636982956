import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";


const JavaCollectionsCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Java Collections Framework Cheat Sheet
              </ArticleTitle>
              <ArticleSubtitle>Key Interfaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Collection:</strong> The root of the collection
                    hierarchy.
                  </li>
                  <li>
                    <strong>List:</strong> An ordered collection (also known as
                    a sequence). Implementations include ArrayList, LinkedList,
                    and Vector.
                  </li>
                  <li>
                    <strong>Set:</strong> A collection that contains no
                    duplicate elements. Key implementations are HashSet,
                    LinkedHashSet, and TreeSet.
                  </li>
                  <li>
                    <strong>Queue:</strong> A collection used to hold multiple
                    elements prior to processing. Includes LinkedList,
                    PriorityQueue, and ArrayDeque.
                  </li>
                  <li>
                    <strong>Deque:</strong> A linear collection that supports
                    element insertion and removal at both ends. Implemented by
                    LinkedList and ArrayDeque.
                  </li>
                  <li>
                    <strong>Map:</strong> An object that maps keys to values.
                    Includes HashMap, LinkedHashMap, TreeMap, and Hashtable.
                  </li>
                </ul>
              </ArticleParagraph>
              <ArticleSubtitle>List Implementations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>ArrayList: </strong>Resizable-array implementation
                    of the List interface. Best for storing and accessing data.
                  </li>
                  <li>
                    <strong>LinkedList: </strong>Doubly-linked list
                    implementation of the List and Deque interfaces. Ideal for
                    frequently adding and removing items.
                  </li>
                  <li>
                    <strong>Vector: </strong>Synchronized resizable-array
                    implementation of the List interface.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Set Implementations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>HashSet: </strong>Uses a hash table for storage.
                    Offers the best performance for large datasets.
                  </li>
                  <li>
                    <strong>LinkedHashSet: </strong>Hash table and linked list
                    implementation, maintaining insertion order.
                  </li>
                  <li>
                    <strong>TreeSet: </strong>Navigable set implemented based on
                    a TreeMap. Elements are sorted in ascending order.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Queue Implementations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>PriorityQueue: </strong>Elements are ordered
                    according to their natural ordering or by a Comparator
                    provided at queue construction.
                  </li>
                  <li>
                    <strong>ArrayDeque: </strong>Resizable-array implementation
                    of the Deque interface. More efficient than{" "}
                    <strong>Stack</strong> when used as a stack.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Map Implementations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>HashMap: </strong>Hash table based implementation of
                    the Map interface.
                  </li>
                  <li>
                    <strong>LinkedHashMap: </strong>Hash table and linked list
                    implementation of the Map, with predictable iteration order.
                  </li>
                  <li>
                    <strong>TreeMap: </strong>Red-Black tree based
                    implementation of the NavigableMap interface. Sorted
                    according to the natural ordering of its keys.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Add elements: add(), addAll()</strong>
                  </li>
                  <li>
                    <strong>
                      Remove elements: remove(), removeAll(), clear(){" "}
                    </strong>
                  </li>
                  <li>
                    <strong>Find elements: contains(), containsAll()</strong>
                  </li>
                  <li>
                    <strong>Size of collection: size()</strong>
                  </li>
                  <li>
                    <strong>Check if empty: isEmpty()</strong>
                  </li>
                  <li>
                    <strong>Iterate through collections</strong>
                    <ul className="list-disc pl-5">
                      <li>
                        <strong>List: for, iterator(), forEach()</strong>
                      </li>
                      <li>
                        <strong>Set: for, iterator(), forEach()</strong>
                      </li>
                      <li>
                        <strong>Map: keySet(), values(), entrySet()</strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default JavaCollectionsCheatsheet;
