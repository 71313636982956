import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation

const ArticleList = ({ topics }) => {
  return (
    <ul className="list-none">
      {topics.map((topic, index) => (
        <li key={index} className="mb-1">
          <Link to={topic.url} className="text-black md:font-medium hover:text-blue-700">{topic.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default ArticleList;
