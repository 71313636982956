import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const DjangoCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Django Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Django:</strong>
                    <ArticleCode code={`pip install django`} />
                  </li>
                  <li>
                    <strong>Create a New Project:</strong>
                    <ArticleCode code={`django-admin startproject myproject`} />
                  </li>
                  <li>
                    <strong>Run Development Server:</strong>
                    <ArticleCode code={`python manage.py runserver`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Apps:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a New App:</strong>
                    <ArticleCode code={`python manage.py startapp myapp`} />
                  </li>
                  <li>
                    <strong>Include App in Project:</strong>
                    <ArticleCode code={`# myproject/settings.py
INSTALLED_APPS = [
    'myapp',
    ...
]`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define a Model:</strong>
                    <ArticleCode code={`# myapp/models.py
from django.db import models

class MyModel(models.Model):
    name = models.CharField(max_length=100)
    age = models.IntegerField()`} />
                  </li>
                  <li>
                    <strong>Make Migrations:</strong>
                    <ArticleCode code={`python manage.py makemigrations`} />
                  </li>
                  <li>
                    <strong>Migrate Database:</strong>
                    <ArticleCode code={`python manage.py migrate`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Admin Interface:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Register a Model in Admin:</strong>
                    <ArticleCode code={`# myapp/admin.py
from django.contrib import admin
from .models import MyModel

admin.site.register(MyModel)`} />
                  </li>
                  <li>
                    <strong>Create Superuser:</strong>
                    <ArticleCode code={`python manage.py createsuperuser`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Views:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define a View:</strong>
                    <ArticleCode code={`# myapp/views.py
from django.http import HttpResponse

def home(request):
    return HttpResponse('Hello, World!')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>URLs:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>URL Configuration:</strong>
                    <ArticleCode code={`# myapp/urls.py
from django.urls import path
from . import views

urlpatterns = [
    path('', views.home, name='home'),
]`} />
                  </li>
                  <li>
                    <strong>Include App URLs in Project:</strong>
                    <ArticleCode code={`# myproject/urls.py
from django.contrib import admin
from django.urls import include, path

urlpatterns = [
    path('admin/', admin.site.urls),
    path('', include('myapp.urls')),
]`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Templates:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Templates:</strong>
                    <ArticleCode code={`# myapp/views.py
from django.shortcuts import render

def home(request):
    return render(request, 'home.html', {'name': 'John'})`} />
                  </li>
                  <li>
                    <strong>Create Template:</strong>
                    <ArticleCode code={`<!-- myapp/templates/home.html -->
<!DOCTYPE html>
<html>
<head>
    <title>Home</title>
</head>
<body>
    <h1>Hello, {{ name }}!</h1>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Static Files:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Serving Static Files:</strong>
                    <ArticleCode code={`# myproject/settings.py
STATIC_URL = '/static/'`} />
                  </li>
                  <li>
                    <strong>Using Static Files:</strong>
                    <ArticleCode code={`<!-- myapp/templates/home.html -->
<!DOCTYPE html>
<html>
<head>
    <title>Home</title>
    <link rel="stylesheet" type="text/css" href="{% static 'style.css' %}">
</head>
<body>
    <h1>Hello, {{ name }}!</h1>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Forms:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Form:</strong>
                    <ArticleCode code={`# myapp/forms.py
from django import forms

class NameForm(forms.Form):
    your_name = forms.CharField(label='Your name', max_length=100)`} />
                  </li>
                  <li>
                    <strong>Using a Form in a View:</strong>
                    <ArticleCode code={`# myapp/views.py
from django.shortcuts import render
from .forms import NameForm

def get_name(request):
    if request.method == 'POST':
        form = NameForm(request.POST)
        if form.is_valid():
            return HttpResponse('Success')
    else:
        form = NameForm()
    return render(request, 'name.html', {'form': form})`} />
                  </li>
                  <li>
                    <strong>Template for Form:</strong>
                    <ArticleCode code={`<!-- myapp/templates/name.html -->
<!DOCTYPE html>
<html>
<head>
    <title>Name Form</title>
</head>
<body>
    <h1>Name Form</h1>
    <form method="post">
        {% csrf_token %}
        {{ form.as_p }}
        <button type="submit">Submit</button>
    </form>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>REST API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Django REST Framework:</strong>
                    <ArticleCode code={`pip install djangorestframework`} />
                  </li>
                  <li>
                    <strong>Setting Up REST Framework:</strong>
                    <ArticleCode code={`# myproject/settings.py
INSTALLED_APPS = [
    ...
    'rest_framework',
]`} />
                  </li>
                  <li>
                    <strong>Creating a Serializer:</strong>
                    <ArticleCode code={`# myapp/serializers.py
from rest_framework import serializers
from .models import MyModel

class MyModelSerializer(serializers.ModelSerializer):
    class Meta:
        model = MyModel
        fields = '__all__'`} />
                  </li>
                  <li>
                    <strong>Creating API Views:</strong>
                    <ArticleCode code={`# myapp/views.py
from rest_framework import viewsets
from .models import MyModel
from .serializers import MyModelSerializer

class MyModelViewSet(viewsets.ModelViewSet):
    queryset = MyModel.objects.all()
    serializer_class = MyModelSerializer`} />
                  </li>
                  <li>
                    <strong>Setting Up API URLs:</strong>
                    <ArticleCode code={`# myapp/urls.py
from django.urls import path, include
from rest_framework.routers import DefaultRouter
from .views import MyModelViewSet

router = DefaultRouter()
router.register(r'mymodel', MyModelViewSet)

urlpatterns = [
    path('', include(router.urls)),
]`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Gunicorn:</strong>
                    <ArticleCode code={`pip install gunicorn
gunicorn myproject.wsgi:application`} />
                  </li>
                  <li>
                    <strong>Using WhiteNoise for Static Files:</strong>
                    <ArticleCode code={`pip install whitenoise

# myproject/settings.py
MIDDLEWARE = [
    ...
    'whitenoise.middleware.WhiteNoiseMiddleware',
]

STATICFILES_STORAGE = 'whitenoise.storage.CompressedManifestStaticFilesStorage'`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default DjangoCheatsheet;
