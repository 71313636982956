import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const RedisCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Redis Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Redis:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update
sudo apt-get install redis-server

# On macOS:
brew install redis`} />
                  </li>
                  <li>
                    <strong>Start Redis Server:</strong>
                    <ArticleCode code={`redis-server`} />
                  </li>
                  <li>
                    <strong>Connect to Redis CLI:</strong>
                    <ArticleCode code={`redis-cli`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Set a Key-Value Pair:</strong>
                    <ArticleCode code={`SET mykey "Hello, Redis!"`} />
                  </li>
                  <li>
                    <strong>Get the Value of a Key:</strong>
                    <ArticleCode code={`GET mykey`} />
                  </li>
                  <li>
                    <strong>Delete a Key:</strong>
                    <ArticleCode code={`DEL mykey`} />
                  </li>
                  <li>
                    <strong>Check if a Key Exists:</strong>
                    <ArticleCode code={`EXISTS mykey`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`SET key "value"
GET key`} />
                  </li>
                  <li>
                    <strong>Lists:</strong>
                    <ArticleCode code={`RPUSH mylist "item1"
RPUSH mylist "item2"
LRANGE mylist 0 -1`} />
                  </li>
                  <li>
                    <strong>Sets:</strong>
                    <ArticleCode code={`SADD myset "member1"
SADD myset "member2"
SMEMBERS myset`} />
                  </li>
                  <li>
                    <strong>Hashes:</strong>
                    <ArticleCode code={`HSET myhash field1 "value1"
HSET myhash field2 "value2"
HGETALL myhash`} />
                  </li>
                  <li>
                    <strong>Sorted Sets:</strong>
                    <ArticleCode code={`ZADD myzset 1 "one"
ZADD myzset 2 "two"
ZRANGE myzset 0 -1 WITHSCORES`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Increment a Key's Value:</strong>
                    <ArticleCode code={`INCR mycounter`} />
                  </li>
                  <li>
                    <strong>Expire a Key:</strong>
                    <ArticleCode code={`EXPIRE mykey 60`} />
                  </li>
                  <li>
                    <strong>Persist a Key:</strong>
                    <ArticleCode code={`PERSIST mykey`} />
                  </li>
                  <li>
                    <strong>Rename a Key:</strong>
                    <ArticleCode code={`RENAME mykey newkey`} />
                  </li>
                  <li>
                    <strong>Get Keys by Pattern:</strong>
                    <ArticleCode code={`KEYS pattern*`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Transactions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Start a Transaction:</strong>
                    <ArticleCode code={`MULTI`} />
                  </li>
                  <li>
                    <strong>Execute a Transaction:</strong>
                    <ArticleCode code={`EXEC`} />
                  </li>
                  <li>
                    <strong>Discard a Transaction:</strong>
                    <ArticleCode code={`DISCARD`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Pub/Sub:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Subscribe to a Channel:</strong>
                    <ArticleCode code={`SUBSCRIBE mychannel`} />
                  </li>
                  <li>
                    <strong>Publish to a Channel:</strong>
                    <ArticleCode code={`PUBLISH mychannel "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Unsubscribe from a Channel:</strong>
                    <ArticleCode code={`UNSUBSCRIBE mychannel`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Persistence:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Save Data to Disk (Snapshotting):</strong>
                    <ArticleCode code={`SAVE`} />
                  </li>
                  <li>
                    <strong>Save Data to Disk (Background):</strong>
                    <ArticleCode code={`BGSAVE`} />
                  </li>
                  <li>
                    <strong>Get Last Save Time:</strong>
                    <ArticleCode code={`LASTSAVE`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Replication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Set Up a Slave:</strong>
                    <ArticleCode code={`SLAVEOF masterip masterport`} />
                  </li>
                  <li>
                    <strong>Stop Being a Slave:</strong>
                    <ArticleCode code={`SLAVEOF NO ONE`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Cluster Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Cluster:</strong>
                    <ArticleCode code={`# Create a cluster with 3 nodes
redis-cli --cluster create 127.0.0.1:7000 127.0.0.1:7001 127.0.0.1:7002`} />
                  </li>
                  <li>
                    <strong>Check Cluster Status:</strong>
                    <ArticleCode code={`redis-cli -c -p 7000 cluster info`} />
                  </li>
                  <li>
                    <strong>Get Cluster Nodes:</strong>
                    <ArticleCode code={`redis-cli -c -p 7000 cluster nodes`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Performance Tuning:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Monitor Commands:</strong>
                    <ArticleCode code={`MONITOR`} />
                  </li>
                  <li>
                    <strong>Slow Log:</strong>
                    <ArticleCode code={`SLOWLOG GET`} />
                  </li>
                  <li>
                    <strong>Config Commands:</strong>
                    <ArticleCode code={`CONFIG GET *
CONFIG SET maxmemory 256mb`} />
                  </li>
                  <li>
                    <strong>Client List:</strong>
                    <ArticleCode code={`CLIENT LIST`} />
                  </li>
                  <li>
                    <strong>Info:</strong>
                    <ArticleCode code={`INFO`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedisCheatsheet;
