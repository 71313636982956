import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const ExpressCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Express.js Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Express:</strong>
                    <ArticleCode code={`npm install express`} />
                  </li>
                  <li>
                    <strong>Basic Server Setup:</strong>
                    <ArticleCode code={`const express = require('express');
const app = express();
const port = 3000;

app.get('/', (req, res) => {
  res.send('Hello World!');
});

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Route:</strong>
                    <ArticleCode code={`app.get('/', (req, res) => {
  res.send('Hello World!');
});`} />
                  </li>
                  <li>
                    <strong>Route Parameters:</strong>
                    <ArticleCode code={`app.get('/user/:id', (req, res) => {
  res.send(\`User ID: \${req.params.id}\`);
});`} />
                  </li>
                  <li>
                    <strong>Query Parameters:</strong>
                    <ArticleCode code={`app.get('/search', (req, res) => {
  res.send(\`Search Query: \${req.query.q}\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Middleware:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Middleware:</strong>
                    <ArticleCode code={`app.use((req, res, next) => {
  console.log('Time:', Date.now());
  next();
});`} />
                  </li>
                  <li>
                    <strong>Serving Static Files:</strong>
                    <ArticleCode code={`app.use(express.static('public'));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Request Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling JSON Data:</strong>
                    <ArticleCode code={`app.use(express.json());

app.post('/data', (req, res) => {
  console.log(req.body);
  res.send('Data received');
});`} />
                  </li>
                  <li>
                    <strong>Handling Form Data:</strong>
                    <ArticleCode code={`app.use(express.urlencoded({ extended: true }));

app.post('/submit', (req, res) => {
  console.log(req.body);
  res.send('Form submitted');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>View Engines:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up EJS:</strong>
                    <ArticleCode code={`app.set('view engine', 'ejs');

app.get('/', (req, res) => {
  res.render('index', { title: 'Home' });
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Error Handling Middleware:</strong>
                    <ArticleCode code={`app.use((err, req, res, next) => {
  console.error(err.stack);
  res.status(500).send('Something broke!');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File Uploads:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling File Uploads with Multer:</strong>
                    <ArticleCode code={`const multer = require('multer');
const upload = multer({ dest: 'uploads/' });

app.post('/upload', upload.single('file'), (req, res) => {
  res.send('File uploaded');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Passport.js:</strong>
                    <ArticleCode code={`const passport = require('passport');
const LocalStrategy = require('passport-local').Strategy;

passport.use(new LocalStrategy(
  function(username, password, done) {
    User.findOne({ username: username }, function (err, user) {
      if (err) { return done(err); }
      if (!user) { return done(null, false); }
      if (!user.verifyPassword(password)) { return done(null, false); }
      return done(null, user);
    });
  }
));

app.use(passport.initialize());
app.use(passport.session());`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Environment Variables:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using dotenv:</strong>
                    <ArticleCode code={`require('dotenv').config();

const port = process.env.PORT || 3000;

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database Integration:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Connecting to MongoDB with Mongoose:</strong>
                    <ArticleCode code={`const mongoose = require('mongoose');

mongoose.connect('mongodb://localhost:27017/mydatabase', {
  useNewUrlParser: true,
  useUnifiedTopology: true
});

const db = mongoose.connection;
db.on('error', console.error.bind(console, 'connection error:'));
db.once('open', function() {
  console.log('Connected to MongoDB');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Testing with Mocha and Chai:</strong>
                    <ArticleCode code={`const chai = require('chai');
const chaiHttp = require('chai-http');
const app = require('./app');

chai.use(chaiHttp);
chai.should();

describe("GET /", () => {
  it("should get homepage", (done) => {
    chai.request(app)
      .get('/')
      .end((err, res) => {
        res.should.have.status(200);
        done();
      });
  });
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpressCheatsheet;
