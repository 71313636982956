import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const Java8Cheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Java 8 Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Lambda Expressions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Syntax:</strong>
                    <ArticleCode code={`(parameters) -> expression`} />
                    <ArticleCode code={`(parameters) -> { statements; }`} />
                  </li>
                  <li>
                    <strong>Example:</strong>
                    <ArticleCode code={`List<String> list = Arrays.asList("a", "b", "c");
list.forEach(n -> System.out.println(n));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functional Interfaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Definition:</strong>
                    <ArticleCode code={`@FunctionalInterface
public interface MyFunctionalInterface {
    void myMethod();
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Streams API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Streams:</strong>
                    <ArticleCode code={`List<String> list = Arrays.asList("a", "b", "c");
Stream<String> stream = list.stream();`} />
                  </li>
                  <li>
                    <strong>Common Operations:</strong>
                    <ArticleCode code={`List<String> filtered = list.stream()
    .filter(s -> s.startsWith("a"))
    .collect(Collectors.toList());`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Optional Class:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Optional:</strong>
                    <ArticleCode code={`Optional<String> optional = Optional.of("Hello");`} />
                  </li>
                  <li>
                    <strong>Common Methods:</strong>
                    <ArticleCode code={`optional.isPresent();
optional.ifPresent(s -> System.out.println(s));
optional.orElse("default value");
optional.map(String::toUpperCase);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>New Date and Time API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>LocalDate, LocalTime, LocalDateTime:</strong>
                    <ArticleCode code={`LocalDate date = LocalDate.now();
LocalTime time = LocalTime.now();
LocalDateTime dateTime = LocalDateTime.now();`} />
                  </li>
                  <li>
                    <strong>Formatting:</strong>
                    <ArticleCode code={`DateTimeFormatter formatter = DateTimeFormatter.ofPattern("dd-MM-yyyy");
String formattedDate = date.format(formatter);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Default and Static Methods in Interfaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Default Methods:</strong>
                    <ArticleCode code={`public interface MyInterface {
    default void defaultMethod() {
        System.out.println("Default method");
    }
}`} />
                  </li>
                  <li>
                    <strong>Static Methods:</strong>
                    <ArticleCode code={`public interface MyInterface {
    static void staticMethod() {
        System.out.println("Static method");
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Method References:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reference to a Static Method:</strong>
                    <ArticleCode code={`List<String> list = Arrays.asList("a", "b", "c");
list.forEach(System.out::println);`} />
                  </li>
                  <li>
                    <strong>Reference to an Instance Method:</strong>
                    <ArticleCode code={`list.forEach(String::toUpperCase);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Parallel Streams:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Parallel Streams:</strong>
                    <ArticleCode code={`List<String> list = Arrays.asList("a", "b", "c");
list.parallelStream().forEach(System.out::println);`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default Java8Cheatsheet;
