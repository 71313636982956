import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const AzureCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Azure Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Azure CLI:</strong>
                    <ArticleCode code={`# On Ubuntu:
curl -sL https://aka.ms/InstallAzureCLIDeb | sudo bash

# On macOS:
brew update && brew install azure-cli`} />
                  </li>
                  <li>
                    <strong>Login to Azure:</strong>
                    <ArticleCode code={`az login`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Virtual Machines (VMs):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create VM:</strong>
                    <ArticleCode code={`az vm create --resource-group myResourceGroup --name myVM --image UbuntuLTS --admin-username azureuser --generate-ssh-keys`} />
                  </li>
                  <li>
                    <strong>Start VM:</strong>
                    <ArticleCode code={`az vm start --resource-group myResourceGroup --name myVM`} />
                  </li>
                  <li>
                    <strong>Stop VM:</strong>
                    <ArticleCode code={`az vm stop --resource-group myResourceGroup --name myVM`} />
                  </li>
                  <li>
                    <strong>Delete VM:</strong>
                    <ArticleCode code={`az vm delete --resource-group myResourceGroup --name myVM`} />
                  </li>
                  <li>
                    <strong>List VMs:</strong>
                    <ArticleCode code={`az vm list --output table`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Storage:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Storage Account:</strong>
                    <ArticleCode code={`az storage account create --name mystorageaccount --resource-group myResourceGroup --location eastus --sku Standard_LRS`} />
                  </li>
                  <li>
                    <strong>Create Blob Container:</strong>
                    <ArticleCode code={`az storage container create --account-name mystorageaccount --name mycontainer`} />
                  </li>
                  <li>
                    <strong>Upload Blob:</strong>
                    <ArticleCode code={`az storage blob upload --account-name mystorageaccount --container-name mycontainer --name myblob --file /path/to/file`} />
                  </li>
                  <li>
                    <strong>List Blobs:</strong>
                    <ArticleCode code={`az storage blob list --account-name mystorageaccount --container-name mycontainer --output table`} />
                  </li>
                  <li>
                    <strong>Delete Blob:</strong>
                    <ArticleCode code={`az storage blob delete --account-name mystorageaccount --container-name mycontainer --name myblob`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Azure Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Function App:</strong>
                    <ArticleCode code={`az functionapp create --resource-group myResourceGroup --consumption-plan-location eastus --runtime node --runtime-version 14 --functions-version 3 --name myFunctionApp --storage-account mystorageaccount`} />
                  </li>
                  <li>
                    <strong>Deploy Function:</strong>
                    <ArticleParagraph>Use the Azure Functions Core Tools to deploy your function app.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Invoke Function:</strong>
                    <ArticleCode code={`az functionapp invoke --resource-group myResourceGroup --name myFunctionApp --function-name myFunction --data '{"name":"Azure"}'`} />
                  </li>
                  <li>
                    <strong>Delete Function App:</strong>
                    <ArticleCode code={`az functionapp delete --resource-group myResourceGroup --name myFunctionApp`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Azure SQL Database:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create SQL Server:</strong>
                    <ArticleCode code={`az sql server create --name myserver --resource-group myResourceGroup --location eastus --admin-user myadmin --admin-password mypassword123`} />
                  </li>
                  <li>
                    <strong>Create SQL Database:</strong>
                    <ArticleCode code={`az sql db create --resource-group myResourceGroup --server myserver --name mydatabase --service-objective S0`} />
                  </li>
                  <li>
                    <strong>List SQL Databases:</strong>
                    <ArticleCode code={`az sql db list --resource-group myResourceGroup --server myserver --output table`} />
                  </li>
                  <li>
                    <strong>Delete SQL Database:</strong>
                    <ArticleCode code={`az sql db delete --resource-group myResourceGroup --server myserver --name mydatabase`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Azure Kubernetes Service (AKS):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create AKS Cluster:</strong>
                    <ArticleCode code={`az aks create --resource-group myResourceGroup --name myAKSCluster --node-count 1 --enable-addons monitoring --generate-ssh-keys`} />
                  </li>
                  <li>
                    <strong>Get AKS Credentials:</strong>
                    <ArticleCode code={`az aks get-credentials --resource-group myResourceGroup --name myAKSCluster`} />
                  </li>
                  <li>
                    <strong>Get Nodes in AKS Cluster:</strong>
                    <ArticleCode code={`kubectl get nodes`} />
                  </li>
                  <li>
                    <strong>Delete AKS Cluster:</strong>
                    <ArticleCode code={`az aks delete --resource-group myResourceGroup --name myAKSCluster`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Networking (VNet):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Virtual Network:</strong>
                    <ArticleCode code={`az network vnet create --name myVnet --resource-group myResourceGroup --address-prefix 10.0.0.0/16`} />
                  </li>
                  <li>
                    <strong>Create Subnet:</strong>
                    <ArticleCode code={`az network vnet subnet create --vnet-name myVnet --resource-group myResourceGroup --name mySubnet --address-prefix 10.0.1.0/24`} />
                  </li>
                  <li>
                    <strong>Create Network Security Group:</strong>
                    <ArticleCode code={`az network nsg create --resource-group myResourceGroup --name myNetworkSecurityGroup`} />
                  </li>
                  <li>
                    <strong>Create NSG Rule:</strong>
                    <ArticleCode code={`az network nsg rule create --resource-group myResourceGroup --nsg-name myNetworkSecurityGroup --name myNSGRule --protocol tcp --direction inbound --priority 1000 --source-address-prefix '*' --source-port-range '*' --destination-address-prefix '*' --destination-port-range 22 --access allow`} />
                  </li>
                  <li>
                    <strong>Associate NSG with Subnet:</strong>
                    <ArticleCode code={`az network vnet subnet update --vnet-name myVnet --name mySubnet --resource-group myResourceGroup --network-security-group myNetworkSecurityGroup`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Azure DevOps:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create DevOps Project:</strong>
                    <ArticleCode code={`az devops project create --name myproject --organization https://dev.azure.com/myorganization`} />
                  </li>
                  <li>
                    <strong>Create Build Pipeline:</strong>
                    <ArticleParagraph>Use the Azure DevOps portal to create and configure a build pipeline for your project.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Create Release Pipeline:</strong>
                    <ArticleParagraph>Use the Azure DevOps portal to create and configure a release pipeline for your project.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>List DevOps Projects:</strong>
                    <ArticleCode code={`az devops project list --organization https://dev.azure.com/myorganization --output table`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Monitoring and Logging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Log Analytics Workspace:</strong>
                    <ArticleCode code={`az monitor log-analytics workspace create --resource-group myResourceGroup --workspace-name myWorkspace`} />
                  </li>
                  <li>
                    <strong>List Log Analytics Workspaces:</strong>
                    <ArticleCode code={`az monitor log-analytics workspace list --resource-group myResourceGroup --output table`} />
                  </li>
                  <li>
                    <strong>Query Logs:</strong>
                    <ArticleCode code={`az monitor log-analytics query --workspace myWorkspace --analytics-query "AzureActivity | summarize count() by bin(timestamp, 1h)"`} />
                  </li>
                  <li>
                    <strong>Enable Monitoring for VM:</strong>
                    <ArticleCode code={`az monitor diagnostic-settings create --resource-group myResourceGroup --name myDiagnosticSetting --vm myVM --workspace myWorkspace`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default AzureCheatsheet;
