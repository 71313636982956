import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const NextCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Next.js Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Next.js App:</strong>
                    <ArticleCode code={`npx create-next-app my-app
cd my-app
npm run dev`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Pages and Routing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Pages:</strong>
                    <ArticleCode code={`// pages/index.js
export default function Home() {
  return <h1>Home Page</h1>;
}

// pages/about.js
export default function About() {
  return <h1>About Page</h1>;
}`} />
                  </li>
                  <li>
                    <strong>Dynamic Routing:</strong>
                    <ArticleCode code={`// pages/posts/[id].js
import { useRouter } from 'next/router';

export default function Post() {
  const router = useRouter();
  const { id } = router.query;

  return <h1>Post {id}</h1>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Linking Between Pages:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using the Link Component:</strong>
                    <ArticleCode code={`import Link from 'next/link';

export default function Home() {
  return (
    <div>
      <h1>Home Page</h1>
      <Link href="/about">
        <a>Go to About Page</a>
      </Link>
    </div>
  );
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CSS and Styling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Global CSS:</strong>
                    <ArticleCode code={`// pages/_app.js
import '../styles/globals.css';

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}`} />
                  </li>
                  <li>
                    <strong>CSS Modules:</strong>
                    <ArticleCode code={`// components/Button.module.css
.button {
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

// components/Button.js
import styles from './Button.module.css';

export default function Button() {
  return <button className={styles.button}>Click me</button>;
}`} />
                  </li>
                  <li>
                    <strong>Styled JSX:</strong>
                    <ArticleCode code={`export default function Home() {
  return (
    <div>
      <p>Styled JSX Example</p>
      <style jsx>{\`
        p {
          color: red;
        }
      \`}</style>
    </div>
  );
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Fetching:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>getStaticProps:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getStaticProps() {
  // Fetch data from an API or file
  const data = await fetchData();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data}</div>;
}`} />
                  </li>
                  <li>
                    <strong>getServerSideProps:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getServerSideProps() {
  // Fetch data from an API or file
  const data = await fetchData();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data}</div>;
}`} />
                  </li>
                  <li>
                    <strong>getStaticPaths:</strong>
                    <ArticleCode code={`// pages/posts/[id].js
export async function getStaticPaths() {
  const paths = await getPathsFromData();

  return {
    paths,
    fallback: false
  };
}

export async function getStaticProps({ params }) {
  const post = await getPostData(params.id);

  return {
    props: {
      post
    }
  };
}

export default function Post({ post }) {
  return <div>{post.title}</div>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>API Routes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating API Routes:</strong>
                    <ArticleCode code={`// pages/api/hello.js
export default function handler(req, res) {
  res.status(200).json({ text: 'Hello' });
}`} />
                  </li>
                  <li>
                    <strong>Using API Routes:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getServerSideProps() {
  const res = await fetch('http://localhost:3000/api/hello');
  const data = await res.json();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data.text}</div>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Environment Variables:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Environment Variables:</strong>
                    <ArticleCode code={`// .env.local
API_KEY=your_api_key_here

// next.config.js
module.exports = {
  env: {
    API_KEY: process.env.API_KEY
  }
};

// pages/index.js
export default function Home() {
  return <div>{process.env.API_KEY}</div>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Custom App and Document:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Custom App:</strong>
                    <ArticleCode code={`// pages/_app.js
import '../styles/globals.css';

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}`} />
                  </li>
                  <li>
                    <strong>Custom Document:</strong>
                    <ArticleCode code={`// pages/_document.js
import Document, { Html, Head, Main, NextScript } from 'next/document';

class MyDocument extends Document {
  static async getInitialProps(ctx) {
    const initialProps = await Document.getInitialProps(ctx);
    return { ...initialProps };
  }

  render() {
    return (
      <Html>
        <Head />
        <body>
          <Main />
          <NextScript />
        </body>
      </Html>
    );
  }
}

export default MyDocument;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>API Routes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>API Route Example:</strong>
                    <ArticleCode code={`// pages/api/hello.js
export default function handler(req, res) {
  res.status(200).json({ text: 'Hello' });
}`} />
                  </li>
                  <li>
                    <strong>Fetching from API Route:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getServerSideProps() {
  const res = await fetch('http://localhost:3000/api/hello');
  const data = await res.json();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data.text}</div>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Custom API Routes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>API Route Example:</strong>
                    <ArticleCode code={`// pages/api/hello.js
export default function handler(req, res) {
  res.status(200).json({ text: 'Hello' });
}`} />
                  </li>
                  <li>
                    <strong>Fetching from API Route:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getServerSideProps() {
  const res = await fetch('http://localhost:3000/api/hello');
  const data = await res.json();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data.text}</div>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Static and Server-side Rendering:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>getStaticProps:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getStaticProps() {
  const data = await fetchData();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data}</div>;
}`} />
                  </li>
                  <li>
                    <strong>getServerSideProps:</strong>
                    <ArticleCode code={`// pages/index.js
export async function getServerSideProps() {
  const data = await fetchData();

  return {
    props: {
      data
    }
  };
}

export default function Home({ data }) {
  return <div>{data}</div>;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Vercel Deployment:</strong>
                    <ArticleCode code={`# Install Vercel CLI
npm install -g vercel

# Deploy
vercel`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default NextCheatsheet;
