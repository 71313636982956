import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const NodeCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Node.js Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Node.js:</strong>
                    <ArticleCode code={`# On macOS and Ubuntu:
brew install node

# On Windows, download the installer from the Node.js website`} />
                  </li>
                  <li>
                    <strong>Check Installation:</strong>
                    <ArticleCode code={`node -v
npm -v`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Server:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Simple Server:</strong>
                    <ArticleCode code={`const http = require('http');

const hostname = '127.0.0.1';
const port = 3000;

const server = http.createServer((req, res) => {
  res.statusCode = 200;
  res.setHeader('Content-Type', 'text/plain');
  res.end('Hello World');
});

server.listen(port, hostname, () => {
  console.log(\`Server running at http://\${hostname}:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Importing Modules:</strong>
                    <ArticleCode code={`const fs = require('fs');
const path = require('path');`} />
                  </li>
                  <li>
                    <strong>Exporting Modules:</strong>
                    <ArticleCode code={`// myModule.js
module.exports = {
  myFunction: function() {
    console.log('Hello from myFunction');
  }
};

// app.js
const myModule = require('./myModule');
myModule.myFunction();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File System:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Read File:</strong>
                    <ArticleCode code={`const fs = require('fs');

fs.readFile('example.txt', 'utf8', (err, data) => {
  if (err) throw err;
  console.log(data);
});`} />
                  </li>
                  <li>
                    <strong>Write File:</strong>
                    <ArticleCode code={`const fs = require('fs');

fs.writeFile('example.txt', 'Hello, world!', err => {
  if (err) throw err;
  console.log('File has been written');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>NPM:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Initialize Project:</strong>
                    <ArticleCode code={`npm init -y`} />
                  </li>
                  <li>
                    <strong>Install Package:</strong>
                    <ArticleCode code={`npm install express`} />
                  </li>
                  <li>
                    <strong>Global Install:</strong>
                    <ArticleCode code={`npm install -g nodemon`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Express:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Express Server:</strong>
                    <ArticleCode code={`const express = require('express');
const app = express();
const port = 3000;

app.get('/', (req, res) => {
  res.send('Hello World!');
});

app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                  <li>
                    <strong>Route Parameters:</strong>
                    <ArticleCode code={`app.get('/user/:id', (req, res) => {
  res.send(\`User ID: \${req.params.id}\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Middleware:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Middleware:</strong>
                    <ArticleCode code={`const express = require('express');
const app = express();

app.use((req, res, next) => {
  console.log('Time:', Date.now());
  next();
});

app.get('/', (req, res) => {
  res.send('Hello World!');
});

app.listen(3000);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Handling JSON:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Parsing JSON:</strong>
                    <ArticleCode code={`const express = require('express');
const app = express();
app.use(express.json());

app.post('/data', (req, res) => {
  console.log(req.body);
  res.send('Data received');
});

app.listen(3000);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Connecting to MongoDB:</strong>
                    <ArticleCode code={`const mongoose = require('mongoose');

mongoose.connect('mongodb://localhost:27017/mydatabase', {
  useNewUrlParser: true,
  useUnifiedTopology: true
});

const db = mongoose.connection;
db.on('error', console.error.bind(console, 'connection error:'));
db.once('open', function() {
  console.log('Connected to MongoDB');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Environment Variables:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using dotenv:</strong>
                    <ArticleCode code={`// .env
PORT=3000

// app.js
require('dotenv').config();

const port = process.env.PORT;
app.listen(port, () => {
  console.log(\`Server running at http://localhost:\${port}/\`);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Passport.js:</strong>
                    <ArticleCode code={`const passport = require('passport');
const LocalStrategy = require('passport-local').Strategy;

passport.use(new LocalStrategy(
  function(username, password, done) {
    User.findOne({ username: username }, function (err, user) {
      if (err) { return done(err); }
      if (!user) { return done(null, false); }
      if (!user.verifyPassword(password)) { return done(null, false); }
      return done(null, user);
    });
  }
));

app.use(passport.initialize());
app.use(passport.session());`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Error Handling Middleware:</strong>
                    <ArticleCode code={`app.use((err, req, res, next) => {
  console.error(err.stack);
  res.status(500).send('Something broke!');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Mocha and Chai:</strong>
                    <ArticleCode code={`const chai = require('chai');
const chaiHttp = require('chai-http');
const app = require('./app');

chai.use(chaiHttp);
chai.should();

describe("GET /", () => {
  it("should get homepage", (done) => {
    chai.request(app)
      .get('/')
      .end((err, res) => {
        res.should.have.status(200);
        done();
      });
  });
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default NodeCheatsheet;
