import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const DockerCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Docker Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check Docker Version:</strong>
                    <ArticleCode code={`docker --version`} />
                  </li>
                  <li>
                    <strong>List Docker Info:</strong>
                    <ArticleCode code={`docker info`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Images:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Images:</strong>
                    <ArticleCode code={`docker images`} />
                  </li>
                  <li>
                    <strong>Pull Image:</strong>
                    <ArticleCode code={`docker pull image_name`} />
                  </li>
                  <li>
                    <strong>Remove Image:</strong>
                    <ArticleCode code={`docker rmi image_name`} />
                  </li>
                  <li>
                    <strong>Build Image:</strong>
                    <ArticleCode code={`docker build -t image_name .`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Containers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Running Containers:</strong>
                    <ArticleCode code={`docker ps`} />
                  </li>
                  <li>
                    <strong>List All Containers:</strong>
                    <ArticleCode code={`docker ps -a`} />
                  </li>
                  <li>
                    <strong>Run a Container:</strong>
                    <ArticleCode code={`docker run image_name`} />
                  </li>
                  <li>
                    <strong>Run a Container with Options:</strong>
                    <ArticleCode code={`docker run -d -p 80:80 --name container_name image_name`} />
                  </li>
                  <li>
                    <strong>Stop a Container:</strong>
                    <ArticleCode code={`docker stop container_name`} />
                  </li>
                  <li>
                    <strong>Remove a Container:</strong>
                    <ArticleCode code={`docker rm container_name`} />
                  </li>
                  <li>
                    <strong>Remove All Containers:</strong>
                    <ArticleCode code={`docker rm $(docker ps -a -q)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Volumes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Volumes:</strong>
                    <ArticleCode code={`docker volume ls`} />
                  </li>
                  <li>
                    <strong>Create Volume:</strong>
                    <ArticleCode code={`docker volume create volume_name`} />
                  </li>
                  <li>
                    <strong>Remove Volume:</strong>
                    <ArticleCode code={`docker volume rm volume_name`} />
                  </li>
                  <li>
                    <strong>Remove All Volumes:</strong>
                    <ArticleCode code={`docker volume rm $(docker volume ls -q)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Networks:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Networks:</strong>
                    <ArticleCode code={`docker network ls`} />
                  </li>
                  <li>
                    <strong>Create Network:</strong>
                    <ArticleCode code={`docker network create network_name`} />
                  </li>
                  <li>
                    <strong>Remove Network:</strong>
                    <ArticleCode code={`docker network rm network_name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Docker Compose:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Start Services:</strong>
                    <ArticleCode code={`docker-compose up`} />
                  </li>
                  <li>
                    <strong>Start Services in the Background:</strong>
                    <ArticleCode code={`docker-compose up -d`} />
                  </li>
                  <li>
                    <strong>Stop Services:</strong>
                    <ArticleCode code={`docker-compose down`} />
                  </li>
                  <li>
                    <strong>View Logs:</strong>
                    <ArticleCode code={`docker-compose logs`} />
                  </li>
                  <li>
                    <strong>View Specific Service Logs:</strong>
                    <ArticleCode code={`docker-compose logs service_name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Inspect a Container:</strong>
                    <ArticleCode code={`docker inspect container_name`} />
                  </li>
                  <li>
                    <strong>View Container Logs:</strong>
                    <ArticleCode code={`docker logs container_name`} />
                  </li>
                  <li>
                    <strong>Follow Container Logs:</strong>
                    <ArticleCode code={`docker logs -f container_name`} />
                  </li>
                  <li>
                    <strong>Execute Command in Running Container:</strong>
                    <ArticleCode code={`docker exec -it container_name command`} />
                  </li>
                  <li>
                    <strong>Remove Unused Data:</strong>
                    <ArticleCode code={`docker system prune`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default DockerCheatsheet;
