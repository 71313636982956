import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const EncryptionCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Encryption Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Encryption:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Encryption is the process of converting plaintext into ciphertext to protect data from unauthorized access. This cheat sheet covers essential concepts and commands to help you understand and implement encryption for securing your data.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Types of Encryption:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Symmetric Encryption:</strong> Uses the same key for both encryption and decryption.
                    <ArticleParagraph>Example Algorithms: AES, DES, 3DES</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Asymmetric Encryption:</strong> Uses a pair of keys: a public key for encryption and a private key for decryption.
                    <ArticleParagraph>Example Algorithms: RSA, ECC</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Symmetric Encryption Example (AES):</ArticleSubtitle>
              <ArticleParagraph>
                <ArticleParagraph>
                  <strong>Encrypt with AES:</strong>
                  <ArticleCode code={`import os
from Crypto.Cipher import AES
from Crypto.Util.Padding import pad, unpad

key = os.urandom(32)
cipher = AES.new(key, AES.MODE_CBC)
iv = cipher.iv
plaintext = b"Secret Message"
ciphertext = cipher.encrypt(pad(plaintext, AES.block_size))

print(f"Ciphertext: {ciphertext.hex()}")`} />
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Decrypt with AES:</strong>
                  <ArticleCode code={`cipher = AES.new(key, AES.MODE_CBC, iv=iv)
decrypted = unpad(cipher.decrypt(ciphertext), AES.block_size)
print(f"Decrypted: {decrypted}")`} />
                </ArticleParagraph>
              </ArticleParagraph>

              <ArticleSubtitle>Asymmetric Encryption Example (RSA):</ArticleSubtitle>
              <ArticleParagraph>
                <ArticleParagraph>
                  <strong>Generate RSA Keys:</strong>
                  <ArticleCode code={`from Crypto.PublicKey import RSA

key = RSA.generate(2048)
private_key = key.export_key()
public_key = key.publickey().export_key()

print(f"Private Key: {private_key.decode()}\\n")
print(f"Public Key: {public_key.decode()}")`} />
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Encrypt with RSA:</strong>
                  <ArticleCode code={`from Crypto.Cipher import PKCS1_OAEP
from Crypto.PublicKey import RSA

public_key = RSA.import_key(open('public.pem').read())
cipher = PKCS1_OAEP.new(public_key)
plaintext = b"Secret Message"
ciphertext = cipher.encrypt(plaintext)

print(f"Ciphertext: {ciphertext.hex()}")`} />
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Decrypt with RSA:</strong>
                  <ArticleCode code={`private_key = RSA.import_key(open('private.pem').read())
cipher = PKCS1_OAEP.new(private_key)
decrypted = cipher.decrypt(ciphertext)

print(f"Decrypted: {decrypted}")`} />
                </ArticleParagraph>
              </ArticleParagraph>

              <ArticleSubtitle>Hashing:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Hashing is the process of converting data into a fixed-size string of characters, which is typically a digest that represents the data. Hashing is used for data integrity checks and password storage.</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Example Hash Functions:</strong> MD5, SHA-1, SHA-256, SHA-3
                  </li>
                </ul>
                <ArticleParagraph>
                  <strong>Hashing with SHA-256:</strong>
                  <ArticleCode code={`import hashlib

data = b"Message to hash"
hash_object = hashlib.sha256(data)
hex_dig = hash_object.hexdigest()
print(f"SHA-256: {hex_dig}")`} />
                </ArticleParagraph>
              </ArticleParagraph>

              <ArticleSubtitle>Common Encryption Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Strong Algorithms:</strong> Use well-known and tested algorithms such as AES for symmetric encryption and RSA for asymmetric encryption.</li>
                  <li><strong>Manage Keys Securely:</strong> Store encryption keys securely and rotate them periodically.</li>
                  <li><strong>Use Salts:</strong> When hashing passwords, use a unique salt for each password to prevent rainbow table attacks.</li>
                  <li><strong>Encrypt Sensitive Data:</strong> Encrypt sensitive data both at rest and in transit.</li>
                  <li><strong>Regularly Update Libraries:</strong> Ensure that the cryptographic libraries you use are up-to-date and free of known vulnerabilities.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Encryption Tools:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>OpenSSL:</strong> A robust, full-featured toolkit for the Transport Layer Security (TLS) and Secure Sockets Layer (SSL) protocols.
                  </li>
                  <li>
                    <strong>GnuPG:</strong> A complete and free implementation of the OpenPGP standard for encrypting and signing data and communications.
                  </li>
                  <li>
                    <strong>Cryptography:</strong> A package designed to expose cryptographic recipes and primitives to Python developers.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Encryption Best Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Do Not Invent Your Own Encryption:</strong> Always use well-established cryptographic libraries and protocols.</li>
                  <li><strong>Keep Secrets Out of Source Code:</strong> Never hard-code encryption keys or passwords in your source code.</li>
                  <li><strong>Regular Security Audits:</strong> Regularly audit your encryption implementation and update it as needed.</li>
                  <li><strong>Use Multi-Factor Authentication (MFA):</strong> Add an extra layer of security by using MFA in addition to encryption.</li>
                  <li><strong>Educate Your Team:</strong> Ensure that your development and operations teams are educated about encryption and security best practices.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default EncryptionCheatsheet;
