import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const KotlinCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Kotlin Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`fun main() {
    println("Hello, World!")
}`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`val x = 5
val y = 3.14
val z = 10
val s = "Hello"`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/*
This is a
multi-line comment
*/`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`val x: Int = 10       // Integer
val y: Double = 2.5    // Float
val c: Char = 'A'      // Character
val b: Boolean = true  // Boolean`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`val s = "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`val arr = arrayOf(1, 2, 3)`} />
                  </li>
                  <li>
                    <strong>Lists:</strong>
                    <ArticleCode code={`val list = listOf(1, 2, 3)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if (x > 0) {
    println("x is positive")
} else if (x == 0) {
    println("x is zero")
} else {
    println("x is negative")
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for (i in 0..4) {
    println(i)
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`var x = 5
while (x > 0) {
    println(x)
    x--
}`} />
                  </li>
                  <li>
                    <strong>Foreach Loops:</strong>
                    <ArticleCode code={`val arr = arrayOf(1, 2, 3)
for (value in arr) {
    println(value)
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`fun myFunction() {
    println("Hello from a function")
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`fun add(a: Int, b: Int): Int {
    return a + b
}

val result = add(2, 3)
println(result)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes and Objects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`class Person(val name: String, val age: Int) {
    fun greet() {
        println("Hello, my name is \$name")
    }
}`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`val person = Person("John", 30)
person.greet()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Base and Derived Classes:</strong>
                    <ArticleCode code={`open class Base {
    open fun display() {
        println("Base class")
    }
}

class Derived : Base() {
    override fun display() {
        println("Derived class")
    }
}`} />
                  </li>
                  <li>
                    <strong>Using Inheritance:</strong>
                    <ArticleCode code={`val obj = Derived()
obj.display()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`import java.io.File

val data = File("file.txt").readText()
println(data)`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`import java.io.File

File("file.txt").writeText("Hello, World!")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Try...Catch:</strong>
                    <ArticleCode code={`try {
    val result = 10 / 0
} catch (e: Exception) {
    println("Exception caught: \${e.message}")
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default KotlinCheatsheet;
