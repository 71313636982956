import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const TensorFlowCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                TensorFlow Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to TensorFlow:</ArticleSubtitle>
              <ArticleParagraph>
                <p>TensorFlow is an open-source machine learning framework developed by Google. This cheat sheet covers essential commands and operations to help you get started with TensorFlow for building and training machine learning models.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install TensorFlow:</strong>
                    <ArticleCode code={`pip install tensorflow`} />
                  </li>
                  <li>
                    <strong>Import TensorFlow:</strong>
                    <ArticleCode code={`import tensorflow as tf`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Constants:</strong>
                    <ArticleCode code={`import tensorflow as tf

a = tf.constant(2)
b = tf.constant(3)
print(a + b)`} />
                  </li>
                  <li>
                    <strong>Creating Variables:</strong>
                    <ArticleCode code={`import tensorflow as tf

x = tf.Variable(2.0)
y = tf.Variable(3.0)
print(x * y)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Tensors:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Tensors:</strong>
                    <ArticleCode code={`import tensorflow as tf

tensor = tf.constant([[1, 2], [3, 4]])
print(tensor)`} />
                  </li>
                  <li>
                    <strong>Tensor Operations:</strong>
                    <ArticleCode code={`import tensorflow as tf

a = tf.constant([[1, 2], [3, 4]])
b = tf.constant([[5, 6], [7, 8]])
c = tf.matmul(a, b)
print(c)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Building a Model:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Sequential Model:</strong>
                    <ArticleCode code={`import tensorflow as tf
from tensorflow.keras.models import Sequential
from tensorflow.keras.layers import Dense

model = Sequential([
    Dense(32, activation='relu', input_shape=(784,)),
    Dense(10, activation='softmax')
])
model.summary()`} />
                  </li>
                  <li>
                    <strong>Compile Model:</strong>
                    <ArticleCode code={`model.compile(optimizer='adam',
              loss='sparse_categorical_crossentropy',
              metrics=['accuracy'])`} />
                  </li>
                  <li>
                    <strong>Train Model:</strong>
                    <ArticleCode code={`model.fit(train_data, train_labels, epochs=10)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Evaluating and Predicting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Evaluate Model:</strong>
                    <ArticleCode code={`model.evaluate(test_data, test_labels)`} />
                  </li>
                  <li>
                    <strong>Make Predictions:</strong>
                    <ArticleCode code={`predictions = model.predict(new_data)
print(predictions)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Pipeline:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Load Data:</strong>
                    <ArticleCode code={`import tensorflow as tf

(train_data, train_labels), (test_data, test_labels) = tf.keras.datasets.mnist.load_data()`} />
                  </li>
                  <li>
                    <strong>Data Preprocessing:</strong>
                    <ArticleCode code={`train_data = train_data / 255.0
test_data = test_data / 255.0`} />
                  </li>
                  <li>
                    <strong>Create Dataset:</strong>
                    <ArticleCode code={`train_dataset = tf.data.Dataset.from_tensor_slices((train_data, train_labels)).batch(32)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Saving and Loading Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Save Model:</strong>
                    <ArticleCode code={`model.save('my_model.h5')`} />
                  </li>
                  <li>
                    <strong>Load Model:</strong>
                    <ArticleCode code={`model = tf.keras.models.load_model('my_model.h5')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Callbacks:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Early Stopping:</strong>
                    <ArticleCode code={`from tensorflow.keras.callbacks import EarlyStopping

early_stopping = EarlyStopping(monitor='val_loss', patience=3)
model.fit(train_data, train_labels, epochs=10, validation_split=0.2, callbacks=[early_stopping])`} />
                  </li>
                  <li>
                    <strong>Model Checkpoint:</strong>
                    <ArticleCode code={`from tensorflow.keras.callbacks import ModelCheckpoint

checkpoint = ModelCheckpoint('best_model.h5', save_best_only=True)
model.fit(train_data, train_labels, epochs=10, validation_split=0.2, callbacks=[checkpoint])`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use GPU Acceleration:</strong> Utilize GPU for faster model training.</li>
                  <li><strong>Batch Processing:</strong> Use batch processing for efficient data handling and training.</li>
                  <li><strong>Regularization:</strong> Implement regularization techniques to prevent overfitting.</li>
                  <li><strong>Hyperparameter Tuning:</strong> Perform hyperparameter tuning to optimize model performance.</li>
                  <li><strong>Monitor Training:</strong> Use TensorBoard to monitor training metrics and visualize the model's performance.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default TensorFlowCheatsheet;
