import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const LinuxCommandLineCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Linux Command Line Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Linux Command Line:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The Linux command line is a powerful interface for interacting with the operating system. This cheat sheet covers essential commands to help you navigate and perform various tasks efficiently.</p>
              </ArticleParagraph>

              <ArticleSubtitle>File and Directory Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Files and Directories:</strong>
                    <ArticleCode code={`ls -al`} />
                  </li>
                  <li>
                    <strong>Change Directory:</strong>
                    <ArticleCode code={`cd /path/to/directory`} />
                  </li>
                  <li>
                    <strong>Copy Files:</strong>
                    <ArticleCode code={`cp source_file destination_file`} />
                  </li>
                  <li>
                    <strong>Move/Rename Files:</strong>
                    <ArticleCode code={`mv old_name new_name`} />
                  </li>
                  <li>
                    <strong>Delete Files:</strong>
                    <ArticleCode code={`rm file_name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File Permissions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Change File Permissions:</strong>
                    <ArticleCode code={`chmod 755 file_name`} />
                  </li>
                  <li>
                    <strong>Change File Owner:</strong>
                    <ArticleCode code={`chown user:group file_name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Process Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>View Running Processes:</strong>
                    <ArticleCode code={`ps aux`} />
                  </li>
                  <li>
                    <strong>Kill a Process:</strong>
                    <ArticleCode code={`kill process_id`} />
                  </li>
                  <li>
                    <strong>Top Command:</strong>
                    <ArticleCode code={`top`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Disk Usage:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check Disk Usage:</strong>
                    <ArticleCode code={`df -h`} />
                  </li>
                  <li>
                    <strong>Check Directory Size:</strong>
                    <ArticleCode code={`du -sh /path/to/directory`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Networking:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check Network Configuration:</strong>
                    <ArticleCode code={`ifconfig`} />
                  </li>
                  <li>
                    <strong>Ping a Host:</strong>
                    <ArticleCode code={`ping hostname_or_ip`} />
                  </li>
                  <li>
                    <strong>Traceroute:</strong>
                    <ArticleCode code={`traceroute hostname_or_ip`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Package Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Update Package List (Debian/Ubuntu):</strong>
                    <ArticleCode code={`sudo apt update`} />
                  </li>
                  <li>
                    <strong>Upgrade Packages (Debian/Ubuntu):</strong>
                    <ArticleCode code={`sudo apt upgrade`} />
                  </li>
                  <li>
                    <strong>Install a Package (Debian/Ubuntu):</strong>
                    <ArticleCode code={`sudo apt install package_name`} />
                  </li>
                  <li>
                    <strong>Remove a Package (Debian/Ubuntu):</strong>
                    <ArticleCode code={`sudo apt remove package_name`} />
                  </li>
                  <li>
                    <strong>Update Package List (CentOS/RHEL):</strong>
                    <ArticleCode code={`sudo yum check-update`} />
                  </li>
                  <li>
                    <strong>Upgrade Packages (CentOS/RHEL):</strong>
                    <ArticleCode code={`sudo yum update`} />
                  </li>
                  <li>
                    <strong>Install a Package (CentOS/RHEL):</strong>
                    <ArticleCode code={`sudo yum install package_name`} />
                  </li>
                  <li>
                    <strong>Remove a Package (CentOS/RHEL):</strong>
                    <ArticleCode code={`sudo yum remove package_name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Text Processing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Search in Files:</strong>
                    <ArticleCode code={`grep "search_term" file_name`} />
                  </li>
                  <li>
                    <strong>Sort Lines:</strong>
                    <ArticleCode code={`sort file_name`} />
                  </li>
                  <li>
                    <strong>Count Lines, Words, Characters:</strong>
                    <ArticleCode code={`wc file_name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Linux Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Display Current Directory:</strong> <ArticleCode code={`pwd`} /></li>
                  <li><strong>Display Date and Time:</strong> <ArticleCode code={`date`} /></li>
                  <li><strong>Check System Uptime:</strong> <ArticleCode code={`uptime`} /></li>
                  <li><strong>Display Disk Free Space:</strong> <ArticleCode code={`free -h`} /></li>
                  <li><strong>Show Manual for a Command:</strong> <ArticleCode code={`man command_name`} /></li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Shell Scripting Best Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Comments:</strong> Add comments to explain your code.</li>
                  <li><strong>Error Handling:</strong> Implement error handling to manage script failures.</li>
                  <li><strong>Use Functions:</strong> Organize your code into reusable functions.</li>
                  <li><strong>Follow Naming Conventions:</strong> Use descriptive names for variables and functions.</li>
                  <li><strong>Test Your Scripts:</strong> Regularly test your scripts to ensure they work as expected.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinuxCommandLineCheatsheet;
