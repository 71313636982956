import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const SSLTLSCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                SSL/TLS Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to SSL/TLS:</ArticleSubtitle>
              <ArticleParagraph>
                <p>SSL (Secure Sockets Layer) and TLS (Transport Layer Security) are cryptographic protocols designed to provide secure communication over a computer network. TLS is the successor to SSL. This cheat sheet covers essential concepts and commands to help you understand and implement SSL/TLS for secure communication.</p>
              </ArticleParagraph>

              <ArticleSubtitle>SSL/TLS Basics:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Encryption:</strong> SSL/TLS encrypts data sent over the network to prevent eavesdropping and tampering.
                  </li>
                  <li>
                    <strong>Authentication:</strong> SSL/TLS uses certificates to authenticate the identity of the parties involved.
                  </li>
                  <li>
                    <strong>Integrity:</strong> SSL/TLS ensures that data is not altered during transmission.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>SSL/TLS Handshake:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The SSL/TLS handshake is a process that establishes a secure connection between a client and a server.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Client Hello:</strong> The client sends a "Client Hello" message to the server, proposing SSL/TLS options.</li>
                  <li><strong>Server Hello:</strong> The server responds with a "Server Hello" message, selecting SSL/TLS options.</li>
                  <li><strong>Certificate Exchange:</strong> The server sends its certificate to the client for authentication.</li>
                  <li><strong>Key Exchange:</strong> The client and server exchange keys to establish a secure session.</li>
                  <li><strong>Finished:</strong> Both parties send a "Finished" message to complete the handshake.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Generating SSL/TLS Certificates:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Private Key:</strong>
                    <ArticleCode code={`openssl genpkey -algorithm RSA -out private.key -aes256`} />
                  </li>
                  <li>
                    <strong>Create a Certificate Signing Request (CSR):</strong>
                    <ArticleCode code={`openssl req -new -key private.key -out request.csr`} />
                  </li>
                  <li>
                    <strong>Create a Self-Signed Certificate:</strong>
                    <ArticleCode code={`openssl req -x509 -key private.key -in request.csr -out certificate.crt -days 365`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Configuring SSL/TLS in Web Servers:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Below are examples of how to configure SSL/TLS for common web servers.</p>
                
                <ArticleSubtitle>Apache:</ArticleSubtitle>
                <ArticleCode code={`<VirtualHost *:443>
  SSLEngine on
  SSLCertificateFile /path/to/certificate.crt
  SSLCertificateKeyFile /path/to/private.key
  SSLCertificateChainFile /path/to/chainfile.pem
</VirtualHost>`} />

                <ArticleSubtitle>Nginx:</ArticleSubtitle>
                <ArticleCode code={`server {
  listen 443 ssl;
  ssl_certificate /path/to/certificate.crt;
  ssl_certificate_key /path/to/private.key;
  ssl_trusted_certificate /path/to/chainfile.pem;
}`}/>

              </ArticleParagraph>

              <ArticleSubtitle>SSL/TLS Best Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Strong Protocols:</strong> Disable SSL and use TLS 1.2 or higher.</li>
                  <li><strong>Use Strong Cipher Suites:</strong> Prefer modern cipher suites with strong encryption.</li>
                  <li><strong>Keep Certificates Updated:</strong> Ensure your certificates are up-to-date and renew them before they expire.</li>
                  <li><strong>Enable HSTS:</strong> Enable HTTP Strict Transport Security (HSTS) to enforce HTTPS connections.</li>
                  <li><strong>Monitor Certificate Expiry:</strong> Use monitoring tools to keep track of certificate expiry dates.</li>
                  <li><strong>Regularly Review Configurations:</strong> Regularly review and update your SSL/TLS configurations to adhere to best practices.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common SSL/TLS Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check Certificate Expiry:</strong>
                    <ArticleCode code={`openssl x509 -enddate -noout -in certificate.crt`} />
                  </li>
                  <li>
                    <strong>Display Certificate Details:</strong>
                    <ArticleCode code={`openssl x509 -text -noout -in certificate.crt`} />
                  </li>
                  <li>
                    <strong>Verify Certificate Chain:</strong>
                    <ArticleCode code={`openssl verify -CAfile ca.pem certificate.crt`} />
                  </li>
                  <li>
                    <strong>Convert Certificate Format:</strong> Convert a certificate from PEM to DER format.
                    <ArticleCode code={`openssl x509 -outform der -in certificate.pem -out certificate.der`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Troubleshooting SSL/TLS Issues:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Check Open Ports:</strong> Ensure port 443 (HTTPS) is open and listening.</li>
                  <li><strong>Verify Certificate Paths:</strong> Check that the paths to your certificate and key files are correct.</li>
                  <li><strong>Inspect Logs:</strong> Review web server logs for SSL/TLS related error messages.</li>
                  <li><strong>Use Online Tools:</strong> Use tools like SSL Labs' SSL Test to analyze your SSL/TLS configuration.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Regularly Update Software:</strong> Keep your web server and OpenSSL library up to date.</li>
                  <li><strong>Automate Certificate Renewal:</strong> Use tools like Certbot to automate the renewal of Let's Encrypt certificates.</li>
                  <li><strong>Implement Perfect Forward Secrecy (PFS):</strong> Use cipher suites that support PFS to ensure session keys are not compromised.</li>
                  <li><strong>Enable OCSP Stapling:</strong> Use Online Certificate Status Protocol (OCSP) stapling to improve the performance of certificate status checking.</li>
                  <li><strong>Document Configurations:</strong> Maintain documentation of your SSL/TLS configurations for troubleshooting and audits.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SSLTLSCheatsheet;
