import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const MongoDBCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                MongoDB Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install MongoDB:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update
sudo apt-get install -y mongodb

# On macOS:
brew tap mongodb/brew
brew install mongodb-community`} />
                  </li>
                  <li>
                    <strong>Start MongoDB Service:</strong>
                    <ArticleCode code={`sudo service mongodb start`} />
                  </li>
                  <li>
                    <strong>Connect to MongoDB Shell:</strong>
                    <ArticleCode code={`mongo`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Show Databases:</strong>
                    <ArticleCode code={`show dbs`} />
                  </li>
                  <li>
                    <strong>Create/Use Database:</strong>
                    <ArticleCode code={`use mydatabase`} />
                  </li>
                  <li>
                    <strong>Show Collections:</strong>
                    <ArticleCode code={`show collections`} />
                  </li>
                  <li>
                    <strong>Create Collection:</strong>
                    <ArticleCode code={`db.createCollection("users")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CRUD Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Insert Document:</strong>
                    <ArticleCode code={`db.users.insertOne({ name: "John Doe", email: "john.doe@example.com" })`} />
                  </li>
                  <li>
                    <strong>Find Documents:</strong>
                    <ArticleCode code={`db.users.find()`} />
                  </li>
                  <li>
                    <strong>Find Document by Field:</strong>
                    <ArticleCode code={`db.users.findOne({ name: "John Doe" })`} />
                  </li>
                  <li>
                    <strong>Update Document:</strong>
                    <ArticleCode code={`db.users.updateOne({ name: "John Doe" }, { $set: { email: "new.email@example.com" } })`} />
                  </li>
                  <li>
                    <strong>Delete Document:</strong>
                    <ArticleCode code={`db.users.deleteOne({ name: "John Doe" })`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Projection:</strong>
                    <ArticleCode code={`db.users.find({}, { name: 1, _id: 0 })`} />
                  </li>
                  <li>
                    <strong>Sorting:</strong>
                    <ArticleCode code={`db.users.find().sort({ name: 1 })`} />
                  </li>
                  <li>
                    <strong>Limiting Results:</strong>
                    <ArticleCode code={`db.users.find().limit(5)`} />
                  </li>
                  <li>
                    <strong>Counting Documents:</strong>
                    <ArticleCode code={`db.users.countDocuments()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Indexes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Index:</strong>
                    <ArticleCode code={`db.users.createIndex({ email: 1 })`} />
                  </li>
                  <li>
                    <strong>List Indexes:</strong>
                    <ArticleCode code={`db.users.getIndexes()`} />
                  </li>
                  <li>
                    <strong>Drop Index:</strong>
                    <ArticleCode code={`db.users.dropIndex("email_1")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Aggregation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Aggregation:</strong>
                    <ArticleCode code={`db.users.aggregate([
  { $match: { status: "active" } },
  { $group: { _id: "$status", total: { $sum: 1 } } }
])`} />
                  </li>
                  <li>
                    <strong>Advanced Aggregation:</strong>
                    <ArticleCode code={`db.orders.aggregate([
  { $lookup: {
      from: "users",
      localField: "userId",
      foreignField: "_id",
      as: "userDetails"
    }
  },
  { $unwind: "$userDetails" },
  { $group: { _id: "$userDetails.name", totalSpent: { $sum: "$amount" } } }
])`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Backup and Restore:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Backup Database:</strong>
                    <ArticleCode code={`mongodump --db mydatabase --out /backup/mongodump/`} />
                  </li>
                  <li>
                    <strong>Restore Database:</strong>
                    <ArticleCode code={`mongorestore /backup/mongodump/mydatabase/`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>User Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create User:</strong>
                    <ArticleCode code={`db.createUser({
  user: "myuser",
  pwd: "mypassword",
  roles: [{ role: "readWrite", db: "mydatabase" }]
})`} />
                  </li>
                  <li>
                    <strong>List Users:</strong>
                    <ArticleCode code={`db.getUsers()`} />
                  </li>
                  <li>
                    <strong>Change User Password:</strong>
                    <ArticleCode code={`db.changeUserPassword("myuser", "newpassword")`} />
                  </li>
                  <li>
                    <strong>Revoke User Roles:</strong>
                    <ArticleCode code={`db.revokeRolesFromUser("myuser", [{ role: "readWrite", db: "mydatabase" }])`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Replication and Sharding:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Enable Replication:</strong>
                    <ArticleCode code={`# In mongod.conf
replication:
  replSetName: "rs0"

# Initialize the replica set
rs.initiate()`} />
                  </li>
                  <li>
                    <strong>Add Shard:</strong>
                    <ArticleCode code={`sh.addShard("rs0/mongo1:27017")`} />
                  </li>
                  <li>
                    <strong>Enable Sharding for Database:</strong>
                    <ArticleCode code={`sh.enableSharding("mydatabase")`} />
                  </li>
                  <li>
                    <strong>Shard a Collection:</strong>
                    <ArticleCode code={`sh.shardCollection("mydatabase.users", { "email": 1 })`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Performance Tuning:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Analyze Query Performance:</strong>
                    <ArticleCode code={`db.users.explain("executionStats").find({ name: "John Doe" })`} />
                  </li>
                  <li>
                    <strong>Use Indexes Efficiently:</strong>
                    <ArticleParagraph>Ensure that indexes are used for queries that are run frequently and for sorting operations.</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Monitor Performance:</strong>
                    <ArticleCode code={`db.serverStatus()`} />
                  </li>
                  <li>
                    <strong>Optimize Configuration:</strong>
                    <ArticleParagraph>Review and adjust the configuration settings in `mongod.conf` for your specific workload and environment.</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default MongoDBCheatsheet;
