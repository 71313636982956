import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const AngularCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                AngularJS Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Include AngularJS:</strong>
                    <ArticleCode code={`<script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.8.2/angular.min.js"></script>`} />
                  </li>
                  <li>
                    <strong>Initialize App:</strong>
                    <ArticleCode code={`<html ng-app="myApp">
<body ng-controller="myCtrl">
    <div>
        {{ message }}
    </div>
    <script>
        var app = angular.module('myApp', []);
        app.controller('myCtrl', function($scope) {
            $scope.message = "Hello, World!";
        });
    </script>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Directives:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>ng-model:</strong>
                    <ArticleCode code={`<input type="text" ng-model="name">
<p>Hello, {{ name }}!</p>`} />
                  </li>
                  <li>
                    <strong>ng-repeat:</strong>
                    <ArticleCode code={`<ul>
    <li ng-repeat="item in items">{{ item }}</li>
</ul>`} />
                  </li>
                  <li>
                    <strong>ng-if:</strong>
                    <ArticleCode code={`<div ng-if="isVisible">This is visible</div>`} />
                  </li>
                  <li>
                    <strong>ng-show/ng-hide:</strong>
                    <ArticleCode code={`<div ng-show="isShown">This is shown</div>
<div ng-hide="isHidden">This is hidden</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Controllers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Controller:</strong>
                    <ArticleCode code={`<div ng-controller="myCtrl">
    <p>{{ message }}</p>
</div>

<script>
var app = angular.module('myApp', []);
app.controller('myCtrl', function($scope) {
    $scope.message = "Hello, World!";
});
</script>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Services:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Service:</strong>
                    <ArticleCode code={`app.service('myService', function() {
    this.sayHello = function() {
        return "Hello, World!";
    };
});

app.controller('myCtrl', function($scope, myService) {
    $scope.message = myService.sayHello();
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Filters:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Filters:</strong>
                    <ArticleCode code={`<p>{{ message | uppercase }}</p>`} />
                  </li>
                  <li>
                    <strong>Custom Filters:</strong>
                    <ArticleCode code={`app.filter('reverse', function() {
    return function(input) {
        return input.split('').reverse().join('');
    };
});

<p>{{ message | reverse }}</p>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Configuring Routes:</strong>
                    <ArticleCode code={`<script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.8.2/angular-route.min.js"></script>
<script>
var app = angular.module('myApp', ['ngRoute']);
app.config(function($routeProvider) {
    $routeProvider
    .when("/", {
        templateUrl : "main.htm"
    })
    .when("/about", {
        templateUrl : "about.htm"
    });
});
</script>

<div ng-view></div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>HTTP Requests:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using $http:</strong>
                    <ArticleCode code={`app.controller('myCtrl', function($scope, $http) {
    $http.get("https://api.example.com/data")
    .then(function(response) {
        $scope.data = response.data;
    });
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Forms:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Form:</strong>
                    <ArticleCode code={`<form ng-submit="submitForm()">
    <input type="text" ng-model="formData.name">
    <input type="submit" value="Submit">
</form>`} />
                  </li>
                  <li>
                    <strong>Form Validation:</strong>
                    <ArticleCode code={`<form name="myForm" ng-submit="submitForm()">
    <input type="email" name="email" ng-model="formData.email" required>
    <span ng-show="myForm.email.$error.required">Required!</span>
    <input type="submit" value="Submit">
</form>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Directives:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Custom Directives:</strong>
                    <ArticleCode code={`app.directive('myDirective', function() {
    return {
        template: '<h1>Made by a directive!</h1>'
    };
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Modules:</strong>
                    <ArticleCode code={`var app = angular.module('myApp', []);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Two-Way Data Binding:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using ng-model:</strong>
                    <ArticleCode code={`<input type="text" ng-model="name">
<p>Hello, {{ name }}!</p>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Dependency Injection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Injecting Services:</strong>
                    <ArticleCode code={`app.controller('myCtrl', function($scope, $http) {
    // Controller code
});`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default AngularCheatsheet;
