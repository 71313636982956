import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const BashCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Bash Shell Scripting Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Bash:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Bash (Bourne Again SHell) is a Unix shell and command language. This cheat sheet covers essential commands, syntax, and scripts to help you get started with bash scripting.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Print to Console:</strong>
                    <ArticleCode code={`echo "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`NAME="John"
echo "Hello, $NAME"`} />
                  </li>
                  <li>
                    <strong>Conditionals:</strong>
                    <ArticleCode code={`if [ "$NAME" == "John" ]; then
  echo "Your name is John"
else
  echo "Your name is not John"
fi`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Loops:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>For Loop:</strong>
                    <ArticleCode code={`for i in 1 2 3 4 5; do
  echo "Welcome $i times"
done`} />
                  </li>
                  <li>
                    <strong>While Loop:</strong>
                    <ArticleCode code={`COUNTER=0
while [ $COUNTER -lt 5 ]; do
  echo "Count: $COUNTER"
  COUNTER=$((COUNTER+1))
done`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Function:</strong>
                    <ArticleCode code={`my_function() {
  echo "This is a function"
}

my_function`} />
                  </li>
                  <li>
                    <strong>Function with Parameters:</strong>
                    <ArticleCode code={`greet() {
  echo "Hello, $1"
}

greet "John"`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading a File:</strong>
                    <ArticleCode code={`while read line; do
  echo "$line"
done < filename.txt`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`echo "Some text" > file.txt`} />
                  </li>
                  <li>
                    <strong>Appending to a File:</strong>
                    <ArticleCode code={`echo "More text" >> file.txt`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Bash Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Change Directory:</strong> <ArticleCode code={`cd /path/to/directory`} /></li>
                  <li><strong>List Files:</strong> <ArticleCode code={`ls -al`} /></li>
                  <li><strong>Copy Files:</strong> <ArticleCode code={`cp source_file destination_file`} /></li>
                  <li><strong>Move/Rename Files:</strong> <ArticleCode code={`mv old_name new_name`} /></li>
                  <li><strong>Delete Files:</strong> <ArticleCode code={`rm file_name`} /></li>
                  <li><strong>File Permissions:</strong> <ArticleCode code={`chmod 755 file_name`} /></li>
                  <li><strong>Search in Files:</strong> <ArticleCode code={`grep "search_term" file_name`} /></li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Shell Scripting Best Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Comments:</strong> Add comments to explain your code.</li>
                  <li><strong>Error Handling:</strong> Implement error handling to manage script failures.</li>
                  <li><strong>Use Functions:</strong> Organize your code into reusable functions.</li>
                  <li><strong>Follow Naming Conventions:</strong> Use descriptive names for variables and functions.</li>
                  <li><strong>Test Your Scripts:</strong> Regularly test your scripts to ensure they work as expected.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default BashCheatsheet;
