import React from "react";

const Box = ({ title, urls, mainPageUrl }) => {
  // Determine if the "See More" link should be shown
  const showSeeMore = urls.length > 6;

  // Function to determine the correct URL
  const getUrl = (url) => url.thirdPartyPath ? url.thirdPartyPath : `/${url.path}`;

  return (
    <div className="border border-gray-300 p-3 rounded-lg shadow-xs bg-white hover:shadow-2xl transition-shadow duration-300">
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">{title}</h2>
      <ul>
        {urls.slice(0, 6).map((url, index) => (
          <li key={index} className="mb-1">
            <div className="bg-gray-50 rounded">
              <a
                href={getUrl(url)}
                className="text-blue-900 hover:text-green-700 transition-colors duration-300 text-base"
              >
                {url.title}
              </a>
            </div>
          </li>
        ))}
      </ul>
      {showSeeMore && (
        <a
          href={`/${mainPageUrl}`}
          className="inline-block mt-1 text-center text-black-600 hover:text-blue-500 border border-black-400 rounded-md px-4 py-2 transition-all duration-300 transform hover:scale-105"
        >
          See All...
        </a>
      )}
    </div>
  );
};

export default Box;
