import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const JavaMultithreadingCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Java Multithreading Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Creating Threads:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Extending Thread Class:</strong>
                    <ArticleCode code={`class MyThread extends Thread {
  public void run() {
    System.out.println("Thread is running");
  }
}

public class Test {
  public static void main(String[] args) {
    MyThread t1 = new MyThread();
    t1.start();
  }
}`} />
                  </li>
                  <li>
                    <strong>Implementing Runnable Interface:</strong>
                    <ArticleCode code={`class MyRunnable implements Runnable {
  public void run() {
    System.out.println("Thread is running");
  }
}

public class Test {
  public static void main(String[] args) {
    Thread t1 = new Thread(new MyRunnable());
    t1.start();
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Thread Lifecycle:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>New:</strong> A thread that is created but not started.
                  </li>
                  <li>
                    <strong>Runnable:</strong> A thread that is ready to run.
                  </li>
                  <li>
                    <strong>Running:</strong> A thread that is executing.
                  </li>
                  <li>
                    <strong>Blocked:</strong> A thread that is blocked waiting for a monitor lock.
                  </li>
                  <li>
                    <strong>Waiting:</strong> A thread that is waiting indefinitely for another thread to perform a particular action.
                  </li>
                  <li>
                    <strong>Timed Waiting:</strong> A thread that is waiting for another thread to perform a specific action within a specified waiting time.
                  </li>
                  <li>
                    <strong>Terminated:</strong> A thread that has exited.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Synchronization:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Synchronized Method:</strong>
                    <ArticleCode code={`public synchronized void synchronizedMethod() {
  // synchronized code
}`} />
                  </li>
                  <li>
                    <strong>Synchronized Block:</strong>
                    <ArticleCode code={`public void synchronizedBlock() {
  synchronized(this) {
    // synchronized code
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inter-thread Communication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Wait:</strong>
                    <ArticleCode code={`synchronized(obj) {
  obj.wait();
}`} />
                  </li>
                  <li>
                    <strong>Notify:</strong>
                    <ArticleCode code={`synchronized(obj) {
  obj.notify();
}`} />
                  </li>
                  <li>
                    <strong>Notify All:</strong>
                    <ArticleCode code={`synchronized(obj) {
  obj.notifyAll();
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Thread Pooling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Thread Pool:</strong>
                    <ArticleCode code={`ExecutorService executor = Executors.newFixedThreadPool(5);
executor.submit(new Runnable() {
  public void run() {
    System.out.println("Thread pool example");
  }
});
executor.shutdown();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Thread Methods:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Start:</strong>
                    <ArticleCode code={`thread.start();`} />
                  </li>
                  <li>
                    <strong>Sleep:</strong>
                    <ArticleCode code={`Thread.sleep(milliseconds);`} />
                  </li>
                  <li>
                    <strong>Join:</strong>
                    <ArticleCode code={`thread.join();`} />
                  </li>
                  <li>
                    <strong>Yield:</strong>
                    <ArticleCode code={`Thread.yield();`} />
                  </li>
                  <li>
                    <strong>Set Priority:</strong>
                    <ArticleCode code={`thread.setPriority(Thread.MAX_PRIORITY);`} />
                  </li>
                  <li>
                    <strong>Get Priority:</strong>
                    <ArticleCode code={`int priority = thread.getPriority();`} />
                  </li>
                  <li>
                    <strong>Get Name:</strong>
                    <ArticleCode code={`String name = thread.getName();`} />
                  </li>
                  <li>
                    <strong>Set Name:</strong>
                    <ArticleCode code={`thread.setName("MyThread");`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default JavaMultithreadingCheatsheet;
