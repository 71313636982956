import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const SwiftCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Swift Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`print("Hello, World!")`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`var x = 5
let y = 3.14
var z: Int = 10
let s = "Hello"`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/*
This is a
multi-line comment
*/`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`var x: Int = 10
var y: Double = 2.5
var c: Character = "A"
var b: Bool = true`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`var s = "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`var arr = [1, 2, 3]`} />
                  </li>
                  <li>
                    <strong>Dictionaries:</strong>
                    <ArticleCode code={`var dict = ["name": "John", "age": 30] as [String : Any]`} />
                  </li>
                  <li>
                    <strong>Tuples:</strong>
                    <ArticleCode code={`let person = (name: "John", age: 30)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if x > 0 {
    print("x is positive")
} else if x == 0 {
    print("x is zero")
} else {
    print("x is negative")
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for i in 0..<5 {
    print(i)
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`var x = 5
while x > 0 {
    print(x)
    x -= 1
}`} />
                  </li>
                  <li>
                    <strong>Repeat-While Loops:</strong>
                    <ArticleCode code={`var x = 5
repeat {
    print(x)
    x -= 1
} while x > 0`} />
                  </li>
                  <li>
                    <strong>Switch Statements:</strong>
                    <ArticleCode code={`let num = 3
switch num {
case 1:
    print("One")
case 2:
    print("Two")
case 3:
    print("Three")
default:
    print("Other")
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`func myFunction() {
    print("Hello from a function")
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`func add(a: Int, b: Int) -> Int {
    return a + b
}

let result = add(a: 2, b: 3)
print(result)`} />
                  </li>
                  <li>
                    <strong>Multiple Return Values:</strong>
                    <ArticleCode code={`func swap(a: String, b: String) -> (String, String) {
    return (b, a)
}

let (x, y) = swap(a: "hello", b: "world")
print(x, y)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes and Objects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`class Person {
    var name: String
    var age: Int

    init(name: String, age: Int) {
        self.name = name
        self.age = age
    }

    func greet() {
        print("Hello, my name is \\(name)")
    }
}`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`let person = Person(name: "John", age: 30)
person.greet()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Structs:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Struct:</strong>
                    <ArticleCode code={`struct Person {
    var name: String
    var age: Int

    func greet() {
        print("Hello, my name is \\(name)")
    }
}`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`let person = Person(name: "John", age: 30)
person.greet()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Enumerations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining an Enum:</strong>
                    <ArticleCode code={`enum Direction {
    case up
    case down
    case left
    case right
}`} />
                  </li>
                  <li>
                    <strong>Using Enums:</strong>
                    <ArticleCode code={`let dir = Direction.up
switch dir {
case .up:
    print("Going up!")
case .down:
    print("Going down!")
case .left:
    print("Going left!")
case .right:
    print("Going right!")
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Optionals:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Declaring Optionals:</strong>
                    <ArticleCode code={`var name: String? = "John"
var age: Int?`} />
                  </li>
                  <li>
                    <strong>Unwrapping Optionals:</strong>
                    <ArticleCode code={`if let unwrappedName = name {
    print("Name is \\(unwrappedName)")
} else {
    print("Name is nil")
}`} />
                  </li>
                  <li>
                    <strong>Force Unwrapping:</strong>
                    <ArticleCode code={`print("Name is \\(name!)")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Closures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Closure:</strong>
                    <ArticleCode code={`let greet = {
    print("Hello, World!")
}
greet()`} />
                  </li>
                  <li>
                    <strong>Closures with Parameters and Return Values:</strong>
                    <ArticleCode code={`let add: (Int, Int) -> Int = { (a, b) in
    return a + b
}
let result = add(2, 3)
print(result)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Throwing Errors:</strong>
                    <ArticleCode code={`enum MyError: Error {
    case runtimeError(String)
}

func myFunction() throws {
    throw MyError.runtimeError("An error occurred")
}`} />
                  </li>
                  <li>
                    <strong>Handling Errors:</strong>
                    <ArticleCode code={`do {
    try myFunction()
} catch MyError.runtimeError(let message) {
    print("Error: \\(message)")
} catch {
    print("An unexpected error occurred")
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwiftCheatsheet;
