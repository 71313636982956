import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const SpringBootCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Spring Boot Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Spring Boot Application:</strong>
                    <ArticleCode code={`$ spring init --dependencies=web my-project`} />
                  </li>
                  <li>
                    <strong>Main Application Class:</strong>
                    <ArticleCode code={`@SpringBootApplication
public class MyApplication {
  public static void main(String[] args) {
    SpringApplication.run(MyApplication.class, args);
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Application Properties:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Set Server Port:</strong>
                    <ArticleCode code={`server.port=8081`} />
                  </li>
                  <li>
                    <strong>Set Application Name:</strong>
                    <ArticleCode code={`spring.application.name=myapp`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>REST Controllers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a REST Controller:</strong>
                    <ArticleCode code={`@RestController
@RequestMapping("/api")
public class MyController {
  
  @GetMapping("/hello")
  public String sayHello() {
    return "Hello, World!";
  }
}`} />
                  </li>
                  <li>
                    <strong>Handling POST Requests:</strong>
                    <ArticleCode code={`@PostMapping("/create")
public ResponseEntity<String> create(@RequestBody MyObject obj) {
  // handle creation
  return ResponseEntity.ok("Created");
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Dependency Injection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Autowired:</strong>
                    <ArticleCode code={`@Service
public class MyService {
  private final MyRepository repository;
  
  @Autowired
  public MyService(MyRepository repository) {
    this.repository = repository;
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database Access:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>JPA Repository:</strong>
                    <ArticleCode code={`@Entity
public class User {
  @Id
  @GeneratedValue(strategy = GenerationType.IDENTITY)
  private Long id;
  private String name;
  // getters and setters
}

public interface UserRepository extends JpaRepository<User, Long> {
}`} />
                  </li>
                  <li>
                    <strong>Using the Repository:</strong>
                    <ArticleCode code={`@Service
public class UserService {
  private final UserRepository repository;
  
  @Autowired
  public UserService(UserRepository repository) {
    this.repository = repository;
  }
  
  public List<User> getAllUsers() {
    return repository.findAll();
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Configuration:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>External Configuration:</strong>
                    <ArticleCode code={`@Configuration
public class MyConfig {
  @Bean
  public MyBean myBean() {
    return new MyBean();
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Spring Boot Tests:</strong>
                    <ArticleCode code={`@SpringBootTest
public class MyApplicationTests {
  
  @Autowired
  private MyService myService;
  
  @Test
  public void contextLoads() {
    assertThat(myService).isNotNull();
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpringBootCheatsheet;
