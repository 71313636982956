import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const RCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                R Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`print("Hello, World!")`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`x <- 5
y <- 3.14
z <- 10
s <- "Hello"`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`# This is a single-line comment`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`x <- 10       # Integer
y <- 2.5      # Numeric
c <- 'A'      # Character
b <- TRUE     # Boolean`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`s <- "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Vectors:</strong>
                    <ArticleCode code={`v <- c(1, 2, 3)`} />
                  </li>
                  <li>
                    <strong>Lists:</strong>
                    <ArticleCode code={`l <- list(1, "a", TRUE)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if (x > 0) {
    print("x is positive")
} else if (x == 0) {
    print("x is zero")
} else {
    print("x is negative")
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for (i in 1:5) {
    print(i)
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`x <- 5
while (x > 0) {
    print(x)
    x <- x - 1
}`} />
                  </li>
                  <li>
                    <strong>Apply Family:</strong>
                    <ArticleCode code={`lapply(1:3, function(x) x^2)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`myFunction <- function() {
    print("Hello from a function")
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`add <- function(a, b) {
    return(a + b)
}

result <- add(2, 3)
print(result)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Frames:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Data Frame:</strong>
                    <ArticleCode code={`df <- data.frame(
    name = c("John", "Jane", "Doe"),
    age = c(30, 25, 35)
)`} />
                  </li>
                  <li>
                    <strong>Accessing Data:</strong>
                    <ArticleCode code={`print(df$name)
print(df[1, ])`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`data <- read.csv("file.csv")
print(data)`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`write.csv(df, "file.csv")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Try...Catch:</strong>
                    <ArticleCode code={`tryCatch({
    result <- 10 / 0
}, warning = function(w) {
    print("Warning caught")
}, error = function(e) {
    print("Error caught")
})`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Plotting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Plot:</strong>
                    <ArticleCode code={`plot(1:10, main="Basic Plot")`} />
                  </li>
                  <li>
                    <strong>ggplot2 Plot:</strong>
                    <ArticleCode code={`library(ggplot2)
ggplot(data.frame(x=1:10, y=rnorm(10)), aes(x=x, y=y)) + geom_point()`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default RCheatsheet;
