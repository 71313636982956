const sideboxdata = [
    {
      title: 'Most Viewed Pages',
      linkToResources: [
        { title: 'Java Collections Cheat Sheet', path: 'java/collections/cheatsheet' },
        { title: 'Java Strings & Arrays Cheat Sheet', path: 'java/strings/arrays/cheatsheet' },
        { title: 'Spring Boot Cheat Sheet', path: 'spring/boot/cheatsheet' },
        { title: 'SQL Cheat Sheet', path: 'sql/cheatsheet' },
        { title: 'Docker Cheat Sheet', path: 'docker/cheatsheet' },
        { title: 'Python Cheat Sheet', path: 'python/cheatsheet' },
        { title: 'JavaScript Cheat Sheet', path: 'javascript/cheatsheet' },
        { title: 'Java 8 Cheatsheet', path: 'java8/cheatsheet' },
        { title: 'C++ Cheatsheet', path: 'c++/cheatsheet' },
        { title: 'C# Cheatsheet', path: 'cSharp/cheatsheet' },
        { title: 'Ruby Cheatsheet', path: 'ruby/cheatsheet' },
        { title: 'Go Cheatsheet', path: 'go/cheatsheet' },
        { title: 'Rust Cheatsheet', path: 'rust/cheatsheet' },
        { title: 'Swift Cheatsheet', path: 'swift/cheatsheet' },
        { title: 'PHP Cheatsheet', path: 'php/cheatsheet' },
        { title: 'TypeScript Cheatsheet', path: 'typescript/cheatsheet' },
        { title: 'Kotlin Cheatsheet', path: 'kotlin/cheatsheet' },
        { title: 'Scala Cheatsheet', path: 'scala/cheatsheet' },
        { title: 'Perl Cheatsheet', path: 'perl/cheatsheet' },
        { title: 'R Cheatsheet', path: 'R/cheatsheet' },
        { title: 'Web Development Cheatsheet', path: 'webdevelopment/cheatsheet' },
        { title: 'HTML5 Cheatsheet', path: 'html5/cheatsheet' },
        { title: 'CSS3 Cheatsheet', path: 'css3/cheatsheet' },
        { title: 'Sass/SCSS Cheatsheet', path: 'sassscss/cheatsheet' },
        { title: 'BootStrap Cheatsheet', path: 'bootstrap/cheatsheet' },
        { title: 'Tailwind CSS Cheatsheet', path: 'tailwind/cheatsheet' },
        { title: 'React JS Cheatsheet', path: 'reactjs/cheatsheet' },
        { title: 'Vue JS Cheatsheet', path: 'vuejs/cheatsheet' },
        { title: 'Angular JS Cheatsheet', path: 'angularjs/cheatsheet' },
        { title: 'Svelte JS Cheatsheet', path: 'sveltejs/cheatsheet' },
        { title: 'Node JS Cheatsheet', path: 'nodejs/cheatsheet' },
        { title: 'Express JS Cheatsheet', path: 'expressjs/cheatsheet' },
        { title: 'Next JS Cheatsheet', path: 'nextjs/cheatsheet' },
      ],
    },
  ];
  
  export default sideboxdata;
  