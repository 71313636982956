import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const FlaskCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Flask Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Flask:</strong>
                    <ArticleCode code={`pip install Flask`} />
                  </li>
                  <li>
                    <strong>Basic Flask App:</strong>
                    <ArticleCode code={`from flask import Flask

app = Flask(__name__)

@app.route('/')
def hello_world():
    return 'Hello, World!'

if __name__ == '__main__':
    app.run(debug=True)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Route:</strong>
                    <ArticleCode code={`@app.route('/')
def home():
    return 'Home Page'`} />
                  </li>
                  <li>
                    <strong>Route with Variable:</strong>
                    <ArticleCode code={`@app.route('/user/<username>')
def show_user_profile(username):
    return f'User {username}'`} />
                  </li>
                  <li>
                    <strong>HTTP Methods:</strong>
                    <ArticleCode code={`@app.route('/login', methods=['GET', 'POST'])
def login():
    if request.method == 'POST':
        return 'Do the login'
    else:
        return 'Show the login form'`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Templates:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Rendering Templates:</strong>
                    <ArticleCode code={`from flask import render_template

@app.route('/')
def home():
    return render_template('index.html')`} />
                  </li>
                  <li>
                    <strong>Template File:</strong>
                    <ArticleCode code={`<!-- templates/index.html -->
<!DOCTYPE html>
<html>
<head>
    <title>Home</title>
</head>
<body>
    <h1>Hello, {{ name }}!</h1>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Static Files:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Serving Static Files:</strong>
                    <ArticleCode code={`<link rel="stylesheet" type="text/css" href="{{ url_for('static', filename='style.css') }}">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Forms:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Handling Forms:</strong>
                    <ArticleCode code={`from flask import request

@app.route('/submit', methods=['POST'])
def submit():
    name = request.form['name']
    return f'Hello, {name}!'`} />
                  </li>
                  <li>
                    <strong>Form Template:</strong>
                    <ArticleCode code={`<!-- templates/form.html -->
<!DOCTYPE html>
<html>
<head>
    <title>Form</title>
</head>
<body>
    <form method="POST" action="/submit">
        <label for="name">Name:</label>
        <input type="text" id="name" name="name">
        <input type="submit" value="Submit">
    </form>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up SQLite:</strong>
                    <ArticleCode code={`import sqlite3
from flask import g

DATABASE = 'database.db'

def get_db():
    db = getattr(g, '_database', None)
    if db is None:
        db = g._database = sqlite3.connect(DATABASE)
    return db

@app.teardown_appcontext
def close_connection(exception):
    db = getattr(g, '_database', None)
    if db is not None:
        db.close()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Flask-Migrate:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Flask-Migrate:</strong>
                    <ArticleCode code={`pip install Flask-Migrate`} />
                  </li>
                  <li>
                    <strong>Initialize Flask-Migrate:</strong>
                    <ArticleCode code={`from flask import Flask
from flask_sqlalchemy import SQLAlchemy
from flask_migrate import Migrate

app = Flask(__name__)
app.config['SQLALCHEMY_DATABASE_URI'] = 'sqlite:///app.db'
db = SQLAlchemy(app)
migrate = Migrate(app, db)

if __name__ == '__main__':
    app.run(debug=True)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Flask-WTF:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Flask-WTF:</strong>
                    <ArticleCode code={`pip install Flask-WTF`} />
                  </li>
                  <li>
                    <strong>Creating a Form:</strong>
                    <ArticleCode code={`from flask_wtf import FlaskForm
from wtforms import StringField, SubmitField
from wtforms.validators import DataRequired

class NameForm(FlaskForm):
    name = StringField('Name', validators=[DataRequired()])
    submit = SubmitField('Submit')`} />
                  </li>
                  <li>
                    <strong>Using Flask-WTF in Views:</strong>
                    <ArticleCode code={`@app.route('/form', methods=['GET', 'POST'])
def form():
    form = NameForm()
    if form.validate_on_submit():
        name = form.name.data
        return f'Hello, {name}!'
    return render_template('form.html', form=form)`} />
                  </li>
                  <li>
                    <strong>Flask-WTF Template:</strong>
                    <ArticleCode code={`<!-- templates/form.html -->
<!DOCTYPE html>
<html>
<head>
    <title>Form</title>
</head>
<body>
    <form method="POST">
        {{ form.hidden_tag() }}
        {{ form.name.label }} {{ form.name() }}
        {{ form.submit() }}
    </form>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Blueprints:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up Blueprints:</strong>
                    <ArticleCode code={`# myapp/__init__.py
from flask import Flask

def create_app():
    app = Flask(__name__)

    from .main import main as main_blueprint
    app.register_blueprint(main_blueprint)

    return app`} />
                  </li>
                  <li>
                    <strong>Creating a Blueprint:</strong>
                    <ArticleCode code={`# myapp/main.py
from flask import Blueprint

main = Blueprint('main', __name__)

@main.route('/')
def index():
    return 'Hello from Blueprint'`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>REST API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a REST API:</strong>
                    <ArticleCode code={`from flask import Flask, jsonify, request

app = Flask(__name__)

@app.route('/api/items', methods=['GET'])
def get_items():
    items = [{'id': 1, 'name': 'Item 1'}, {'id': 2, 'name': 'Item 2'}]
    return jsonify(items)

@app.route('/api/items', methods=['POST'])
def create_item():
    item = request.json
    return jsonify(item), 201

if __name__ == '__main__':
    app.run(debug=True)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Flask-Login:</strong>
                    <ArticleCode code={`pip install Flask-Login`} />
                  </li>
                  <li>
                    <strong>Initialize Flask-Login:</strong>
                    <ArticleCode code={`from flask import Flask
from flask_sqlalchemy import SQLAlchemy
from flask_login import LoginManager, UserMixin, login_user, login_required, logout_user, current_user

app = Flask(__name__)
app.config['SQLALCHEMY_DATABASE_URI'] = 'sqlite:///app.db'
app.config['SECRET_KEY'] = 'secret'
db = SQLAlchemy(app)
login_manager = LoginManager(app)

class User(UserMixin, db.Model):
    id = db.Column(db.Integer, primary_key=True)
    username = db.Column(db.String(150), unique=True, nullable=False)
    password = db.Column(db.String(150), nullable=False)

@login_manager.user_loader
def load_user(user_id):
    return User.query.get(int(user_id))`} />
                  </li>
                  <li>
                    <strong>Login and Logout:</strong>
                    <ArticleCode code={`@app.route('/login', methods=['GET', 'POST'])
def login():
    if request.method == 'POST':
        username = request.form['username']
        password = request.form['password']
        user = User.query.filter_by(username=username).first()
        if user and user.password == password:
            login_user(user)
            return redirect(url_for('dashboard'))
    return render_template('login.html')

@app.route('/logout')
@login_required
def logout():
    logout_user()
    return redirect(url_for('login'))

@app.route('/dashboard')
@login_required
def dashboard():
    return f'Hello, {current_user.username}! This is your dashboard.'`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Gunicorn:</strong>
                    <ArticleCode code={`pip install gunicorn
gunicorn app:app`} />
                  </li>
                  <li>
                    <strong>Using Heroku:</strong>
                    <ArticleCode code={`# Install Heroku CLI
heroku create
git push heroku master
heroku ps:scale web=1
heroku open`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlaskCheatsheet;
