import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const LaravelCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Laravel Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Laravel:</strong>
                    <ArticleCode code={`composer global require laravel/installer`} />
                  </li>
                  <li>
                    <strong>Create a New Laravel Project:</strong>
                    <ArticleCode code={`laravel new myproject`} />
                  </li>
                  <li>
                    <strong>Run Development Server:</strong>
                    <ArticleCode code={`php artisan serve`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Route:</strong>
                    <ArticleCode code={`// routes/web.php
Route::get('/', function () {
    return view('welcome');
});`} />
                  </li>
                  <li>
                    <strong>Route with Parameter:</strong>
                    <ArticleCode code={`Route::get('/user/{id}', function ($id) {
    return 'User '.$id;
});`} />
                  </li>
                  <li>
                    <strong>Named Routes:</strong>
                    <ArticleCode code={`Route::get('/user/profile', 'UserProfileController@show')->name('profile');`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Controllers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Controller:</strong>
                    <ArticleCode code={`php artisan make:controller UserController`} />
                  </li>
                  <li>
                    <strong>Basic Controller Action:</strong>
                    <ArticleCode code={`// app/Http/Controllers/UserController.php
namespace App\Http\Controllers;

use App\Models\User;
use Illuminate\Http\Request;

class UserController extends Controller
{
    public function index()
    {
        $users = User::all();
        return view('users.index', ['users' => $users]);
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Views:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Blade Template:</strong>
                    <ArticleCode code={`<!-- resources/views/users/index.blade.php -->
<!DOCTYPE html>
<html>
<head>
    <title>Users</title>
</head>
<body>
    <h1>All Users</h1>
    <ul>
        @foreach ($users as $user)
            <li>{{ $user->name }}</li>
        @endforeach
    </ul>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Model:</strong>
                    <ArticleCode code={`php artisan make:model User`} />
                  </li>
                  <li>
                    <strong>Basic Model:</strong>
                    <ArticleCode code={`// app/Models/User.php
namespace App\Models;

use Illuminate\Database\Eloquent\Factories\HasFactory;
use Illuminate\Database\Eloquent\Model;

class User extends Model
{
    use HasFactory;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database Migrations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Migration:</strong>
                    <ArticleCode code={`php artisan make:migration create_users_table`} />
                  </li>
                  <li>
                    <strong>Basic Migration:</strong>
                    <ArticleCode code={`// database/migrations/xxxx_xx_xx_create_users_table.php
public function up()
{
    Schema::create('users', function (Blueprint $table) {
        $table->id();
        $table->string('name');
        $table->string('email')->unique();
        $table->timestamps();
    });
}

public function down()
{
    Schema::dropIfExists('users');
}`} />
                  </li>
                  <li>
                    <strong>Run Migrations:</strong>
                    <ArticleCode code={`php artisan migrate`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Form Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Form in Blade Template:</strong>
                    <ArticleCode code={`<!-- resources/views/users/create.blade.php -->
<!DOCTYPE html>
<html>
<head>
    <title>Create User</title>
</head>
<body>
    <form method="POST" action="/users">
        @csrf
        <label for="name">Name:</label>
        <input type="text" id="name" name="name">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email">
        <button type="submit">Create</button>
    </form>
</body>
</html>`} />
                  </li>
                  <li>
                    <strong>Handling Form Submission:</strong>
                    <ArticleCode code={`// app/Http/Controllers/UserController.php
public function store(Request $request)
{
    $user = new User;
    $user->name = $request->name;
    $user->email = $request->email;
    $user.save();
    return redirect('/users');
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Validation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Validating Form Data:</strong>
                    <ArticleCode code={`public function store(Request $request)
{
    $validated = $request->validate([
        'name' => 'required|max:255',
        'email' => 'required|email|unique:users',
    ]);

    $user = new User;
    $user->name = $validated['name'];
    $user->email = $validated['email'];
    $user->save();

    return redirect('/users');
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Eloquent Relationships:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Relationships:</strong>
                    <ArticleCode code={`// app/Models/User.php
public function posts()
{
    return $this->hasMany(Post::class);
}

// app/Models/Post.php
public function user()
{
    return $this->belongsTo(User::class);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>API Development:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating API Routes:</strong>
                    <ArticleCode code={`// routes/api.php
Route::get('/users', [UserController::class, 'index']);
Route::get('/users/{id}', [UserController::class, 'show']);
Route::post('/users', [UserController::class, 'store']);
Route::put('/users/{id}', [UserController::class, 'update']);
Route::delete('/users/{id}', [UserController::class, 'destroy']);`} />
                  </li>
                  <li>
                    <strong>API Controller Methods:</strong>
                    <ArticleCode code={`public function index()
{
    return User::all();
}

public function show($id)
{
    return User::find($id);
}

public function store(Request $request)
{
    return User::create($request->all());
}

public function update(Request $request, $id)
{
    $user = User::find($id);
    $user->update($request->all());
    return $user;
}

public function destroy($id)
{
    return User::destroy($id);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up Authentication:</strong>
                    <ArticleCode code={`composer require laravel/ui
php artisan ui vue --auth
npm install && npm run dev`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Test Example:</strong>
                    <ArticleCode code={`// tests/Feature/UserTest.php
public function test_example()
{
    $response = $this->get('/');

    $response->assertStatus(200);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Queues and Jobs:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Job:</strong>
                    <ArticleCode code={`php artisan make:job SendEmail`} />
                  </li>
                  <li>
                    <strong>Defining the Job:</strong>
                    <ArticleCode code={`// app/Jobs/SendEmail.php
public function handle()
{
    // Send email logic
}`} />
                  </li>
                  <li>
                    <strong>Dispatching a Job:</strong>
                    <ArticleCode code={`SendEmail::dispatch($user);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Laravel Forge:</strong>
                    <ArticleCode code={`// Deployment steps using Laravel Forge
1. Create a new site in Laravel Forge.
2. Connect your repository.
3. Deploy your site.`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default LaravelCheatsheet;
