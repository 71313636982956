import React, { useState } from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { FaBars } from 'react-icons/fa'; // Importing Font Awesome bars icon for the menu

const AwsIntroduction = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const basicAWSS3Code = `// Load the AWS SDK for Node.js
var AWS = require('aws-sdk');
// Set the region 
AWS.config.update({region: 'us-west-2'});

// Create S3 service object
var s3 = new AWS.S3({apiVersion: '2006-03-01'});

// Create the parameters for calling listObjects
var bucketParams = {
  Bucket : 'YOUR_BUCKET_NAME',
};

// Call S3 to obtain a list of the objects in the bucket
s3.listObjects(bucketParams, function(err, data) {
  if (err) {
    console.log("Error", err);
  } else {
    console.log("Success", data);
  }
});`;

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="bg-gray-100 py-8">
      <div className="container mx-1 px-4 md:px-4 flex flex-wrap">
        {/* Toggle Button */}
        <button onClick={toggleSidebar} className="md:hidden">
          <FaBars size={30} />
        </button>

        {/* Article List Sidebar */}
        <div className={`${isSidebarVisible ? "block" : "hidden"} md:block md:w-1/4`}>
          <Sidebar>
            <ArticleList topics={awsListData} />
          </Sidebar>
        </div>

        {/* Main Article Content */}
        <div className="w-full md:w-1/2 p-2">
          <div className="bg-white rounded-lg shadow-md p-6">
            <ArticleTitle>AWS Introduction</ArticleTitle>
            <ArticleSubtitle>What is AWS?</ArticleSubtitle>
            <ArticleParagraph>
              AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon.
            </ArticleParagraph>
            <ArticleSubtitle>Basic Example: AWS S3 List Objects</ArticleSubtitle>
            <ArticleParagraph>
              Below is a basic example of using AWS SDK for Node.js to list objects in an S3 bucket.
            </ArticleParagraph>
            <ArticleCode code={basicAWSS3Code} />
          </div>
        </div>

        {/* Advertisement Sidebar */}
        <div className="md:w-1/4">
          <Sidebar>
            <div className="admob-ad mx-10">Ad space</div>
          </Sidebar>
        </div>
      </div>
    </div>
  );
};

export default AwsIntroduction;
