import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const FlutterCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Flutter Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Flutter:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Flutter is an open-source UI software development toolkit created by Google. It is used to develop cross-platform applications from a single codebase. This cheat sheet covers essential widgets, commands, and best practices to help you get started with Flutter development.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Flutter SDK:</strong>
                    <ArticleCode code={`flutter doctor`} />
                  </li>
                  <li>
                    <strong>Create a New Project:</strong>
                    <ArticleCode code={`flutter create my_app`} />
                  </li>
                  <li>
                    <strong>Run the Application:</strong>
                    <ArticleCode code={`cd my_app
flutter run`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Widgets:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Text Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

Text('Hello, world!')`} />
                  </li>
                  <li>
                    <strong>Container Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

Container(
  padding: EdgeInsets.all(16.0),
  margin: EdgeInsets.all(8.0),
  color: Colors.blue,
  child: Text('Hello, container!'),
)`} />
                  </li>
                  <li>
                    <strong>Image Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

Image.network('https://example.com/image.jpg')`} />
                  </li>
                  <li>
                    <strong>Button Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

ElevatedButton(
  onPressed: () {
    print('Button pressed!');
  },
  child: Text('Press me'),
)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Layouts:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Column Layout:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

Column(
  children: [
    Text('First item'),
    Text('Second item'),
  ],
)`} />
                  </li>
                  <li>
                    <strong>Row Layout:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

Row(
  children: [
    Text('First item'),
    Text('Second item'),
  ],
)`} />
                  </li>
                  <li>
                    <strong>Stack Layout:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

Stack(
  children: [
    Container(color: Colors.red, width: 100, height: 100),
    Container(color: Colors.blue, width: 50, height: 50),
  ],
)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>State Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Stateful Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

class MyStatefulWidget extends StatefulWidget {
  @override
  _MyStatefulWidgetState createState() => _MyStatefulWidgetState();
}

class _MyStatefulWidgetState extends State<MyStatefulWidget> {
  int _counter = 0;

  void _incrementCounter() {
    setState(() {
      _counter++;
    });
  }

  @override
  Widget build(BuildContext context) {
    return Scaffold(
      appBar: AppBar(
        title: Text('Stateful Widget Example'),
      ),
      body: Center(
        child: Column(
          mainAxisAlignment: MainAxisAlignment.center,
          children: <Widget>[
            Text('You have pushed the button this many times:'),
            Text('$_counter', style: Theme.of(context).textTheme.headline4),
          ],
        ),
      ),
      floatingActionButton: FloatingActionButton(
        onPressed: _incrementCounter,
        tooltip: 'Increment',
        child: Icon(Icons.add),
      ),
    );
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Navigation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Navigation:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

void main() {
  runApp(MyApp());
}

class MyApp extends StatelessWidget {
  @override
  Widget build(BuildContext context) {
    return MaterialApp(
      title: 'Flutter Demo',
      theme: ThemeData(
        primarySwatch: Colors.blue,
      ),
      home: MyHomePage(),
    );
  }
}

class MyHomePage extends StatelessWidget {
  @override
  Widget build(BuildContext context) {
    return Scaffold(
      appBar: AppBar(
        title: Text('Home Page'),
      ),
      body: Center(
        child: ElevatedButton(
          onPressed: () {
            Navigator.push(
              context,
              MaterialPageRoute(builder: (context) => SecondPage()),
            );
          },
          child: Text('Go to Second Page'),
        ),
      ),
    );
  }
}

class SecondPage extends StatelessWidget {
  @override
  Widget build(BuildContext context) {
    return Scaffold(
      appBar: AppBar(
        title: Text('Second Page'),
      ),
      body: Center(
        child: ElevatedButton(
          onPressed: () {
            Navigator.pop(context);
          },
          child: Text('Go back'),
        ),
      ),
    );
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Handling User Input:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>TextField Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

class MyTextField extends StatefulWidget {
  @override
  _MyTextFieldState createState() => _MyTextFieldState();
}

class _MyTextFieldState extends State<MyTextField> {
  TextEditingController _controller = TextEditingController();

  @override
  Widget build(BuildContext context) {
    return TextField(
      controller: _controller,
      decoration: InputDecoration(
        hintText: 'Enter text',
      ),
    );
  }
}`} />
                  </li>
                  <li>
                    <strong>GestureDetector Widget:</strong>
                    <ArticleCode code={`import 'package:flutter/material.dart';

GestureDetector(
  onTap: () {
    print('Container tapped');
  },
  child: Container(
    color: Colors.blue,
    width: 100,
    height: 100,
  ),
)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Debugging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Flutter DevTools:</strong>
                    <ArticleCode code={`flutter pub global activate devtools
flutter pub global run devtools`} />
                  </li>
                  <li>
                    <strong>Debugging in VSCode:</strong>
                    <ArticleParagraph>
                      <p>Open the command palette and select "Flutter: Open DevTools" to start debugging.</p>
                    </ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Widgets:</strong> Use widgets to create reusable and composable UI components.</li>
                  <li><strong>State Management:</strong> Use providers, Riverpod, Bloc, or other state management solutions to manage application state.</li>
                  <li><strong>Optimize Performance:</strong> Use const constructors, avoid rebuilding unnecessary widgets, and optimize rendering performance.</li>
                  <li><strong>Follow Naming Conventions:</strong> Use descriptive names for your widgets and variables.</li>
                  <li><strong>Testing:</strong> Implement testing using the Flutter test framework and other testing libraries.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlutterCheatsheet;
