import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, lazy, Suspense } from "react";
import HomePage from "./pages/mainPages/HomePage";
import NavigationMenu from "./components/mainComponents/NavigationMenu";
import SearchResultsPage from "./components/mainComponents/SearchResultsPage";
import boxdata from "./data/boxdata";
import Footer from "./components/mainComponents/Footer";
import LatestArticles from "./pages/mainPages/LatestArticles";
import SpringbootHomePage from "./pages/topicHomePage/SpringbootHomePage.js";
import SqlHomePage from "./pages/topicHomePage/SqlHomePage.js";
import MicroservicesHomePage from "./pages/topicHomePage/MicroservicesHomePage";
import CheatSheets from "./pages/seemorePages/cheat-sheets.js";
import JavaHomePage from "./pages/topicHomePage/JavaHomePage.js";
import TutorialsPage from "./pages/seemorePages/tutorialsPage.js";
import InterviewQuestions from "./pages/seemorePages/interviewQuestions.js";
import ProjectsHomePage from "./pages/seemorePages/projectsHomePage.js";
import PrivacyNotice from "./pages/mainPages/PrivacyNotice.js";
import AdSenseScript from "./components/mainComponents/AdSenseScript.js";
import usePageTracking from "./components/mainComponents/usePageTracking.js";

const SearchWrapper = () => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("query");
    if (query) {
      handleSearch(query);
    }
  }, [location]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const results = boxdata.flatMap((category) =>
      category.linkToResources.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      )
    );
    setSearchResults(results);
  };

  const loadPage = (pageName) =>
    lazy(() => import(`./pages/articlePages/${pageName}`));

  function importAll(r) {
    return r.keys().map((key) => {
      return {
        path: key, // This is the module path as a string
        module: r(key), // This is the actual imported module
      };
    });
  }

  // Use require.context to include all JavaScript files in a directory
  const modules = importAll(
    require.context("./pages/articlePages", false, /\.js$/)
  );

  // Extract filenames without extension and transform as needed
  const pageNames = modules.map(({ path }) =>
    path.replace("./", "").replace(".js", "")
  );

  usePageTracking();

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/latest-articles" element={<LatestArticles />} />
      <Route path="/java-home" element={<JavaHomePage />} />
      <Route path="/spring-boot-home" element={<SpringbootHomePage />} />
      <Route path="/microservices-home" element={<MicroservicesHomePage />} />
      <Route path="/sql-home" element={<SqlHomePage />} />
      <Route path="/cheat-sheets" element={<CheatSheets />} />
      <Route path="/tutorialspage" element={<TutorialsPage />} />
      <Route path="/interviewquestions" element={<InterviewQuestions />} />
      <Route path="/projectshomepage" element={<ProjectsHomePage />} />
      <Route path="/javahomepage" element={<JavaHomePage />} />
      <Route path="/privacy-notice" element={<PrivacyNotice />} />
      <Route
        path="/search"
        element={
          <SearchResultsPage query={searchQuery} results={searchResults} />
        }
      />
      {pageNames.map((pageName) => {
        const PageComponent = loadPage(pageName.replace(".js", ""));
        const path = `/${pageName.replace(".js", "").replace(/-/g, "/")}`;
        return (
          <Route
            key={pageName}
            path={path}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageComponent />
              </Suspense>
            }
          />
        );
      })}
    </Routes>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <div style={{ width: "100%", overflowX: "hidden" }}>
        <AdSenseScript />
        <NavigationMenu />
        <SearchWrapper />
        <Footer />
      </div>
    </Router>
  );
};

export default AppRouter;
