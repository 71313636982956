// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDM0-mOjZr35_gIbVbPTZ-EhEl1XWufHRA",
    authDomain: "javaspring-b14b2.firebaseapp.com",
    projectId: "javaspring-b14b2",
    storageBucket: "javaspring-b14b2.appspot.com",
    messagingSenderId: "1011000038241",
    appId: "1:1011000038241:web:907fa97b964a87a8f06b20",
    measurementId: "G-3QZFQPHNJ4"};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
