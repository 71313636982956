import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const PhoenixCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Phoenix (Elixir) Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Elixir:</strong>
                    <ArticleCode code={`# macOS
brew install elixir

# Ubuntu
sudo apt-get install elixir`} />
                  </li>
                  <li>
                    <strong>Install Phoenix:</strong>
                    <ArticleCode code={`mix archive.install hex phx_new`} />
                  </li>
                  <li>
                    <strong>Create a New Phoenix Project:</strong>
                    <ArticleCode code={`mix phx.new my_app`} />
                  </li>
                  <li>
                    <strong>Run Phoenix Server:</strong>
                    <ArticleCode code={`cd my_app
mix phx.server`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Controllers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Controller:</strong>
                    <ArticleCode code={`mix phx.gen.html Blog Post posts title:string body:text`} />
                  </li>
                  <li>
                    <strong>Basic Controller Example:</strong>
                    <ArticleCode code={`defmodule MyAppWeb.PageController do
  use MyAppWeb, :controller

  def index(conn, _params) do
    render(conn, "index.html")
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Views:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a View:</strong>
                    <ArticleCode code={`defmodule MyAppWeb.PageView do
  use MyAppWeb, :view
end`} />
                  </li>
                  <li>
                    <strong>Render a Template:</strong>
                    <ArticleCode code={`# templates/page/index.html.eex
<h1>Welcome to Phoenix!</h1>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Routes:</strong>
                    <ArticleCode code={`scope "/", MyAppWeb do
  pipe_through :browser

  get "/", PageController, :index
end`} />
                  </li>
                  <li>
                    <strong>Generate Resources:</strong>
                    <ArticleCode code={`resources "/posts", PostController`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define a Schema:</strong>
                    <ArticleCode code={`defmodule MyApp.Blog.Post do
  use Ecto.Schema
  import Ecto.Changeset

  schema "posts" do
    field :title, :string
    field :body, :text

    timestamps()
  end

  def changeset(post, attrs) do
    post
    |> cast(attrs, [:title, :body])
    |> validate_required([:title, :body])
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database Migrations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Migration:</strong>
                    <ArticleCode code={`mix ecto.gen.migration create_posts`} />
                  </li>
                  <li>
                    <strong>Define a Migration:</strong>
                    <ArticleCode code={`defmodule MyApp.Repo.Migrations.CreatePosts do
  use Ecto.Migration

  def change do
    create table(:posts) do
      add :title, :string
      add :body, :text

      timestamps()
    end
  end
end`} />
                  </li>
                  <li>
                    <strong>Run Migrations:</strong>
                    <ArticleCode code={`mix ecto.migrate`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Form Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Form:</strong>
                    <ArticleCode code={`<%= form_for @changeset, Routes.post_path(@conn, :create), fn f -> %>
  <div>
    <%= label f, :title %>
    <%= text_input f, :title %>
  </div>
  <div>
    <%= label f, :body %>
    <%= textarea f, :body %>
  </div>
  <div>
    <%= submit "Save" %>
  </div>
<% end %>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Validation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Validations:</strong>
                    <ArticleCode code={`def changeset(post, attrs) do
  post
  |> cast(attrs, [:title, :body])
  |> validate_required([:title, :body])
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Associations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Associations:</strong>
                    <ArticleCode code={`defmodule MyApp.Blog.Post do
  use Ecto.Schema
  import Ecto.Changeset

  schema "posts" do
    field :title, :string
    field :body, :text
    belongs_to :user, MyApp.Accounts.User

    timestamps()
  end

  def changeset(post, attrs) do
    post
    |> cast(attrs, [:title, :body, :user_id])
    |> validate_required([:title, :body])
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Contexts:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Context:</strong>
                    <ArticleCode code={`mix phx.gen.context Blog Post posts title:string body:text`} />
                  </li>
                  <li>
                    <strong>Use the Context:</strong>
                    <ArticleCode code={`def list_posts do
  Repo.all(Post)
end

def get_post!(id), do: Repo.get!(Post, id)

def create_post(attrs \\ %{}) do
  %Post{}
  |> Post.changeset(attrs)
  |> Repo.insert()
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Guardian:</strong>
                    <ArticleCode code={`# Add to mix.exs
{:guardian, "~> 2.0"}`} />
                  </li>
                  <li>
                    <strong>Configure Guardian:</strong>
                    <ArticleCode code={`# config/config.exs
config :my_app, MyApp.Guardian,
  issuer: "my_app",
  secret_key: "YOUR_SECRET_KEY"`} />
                  </li>
                  <li>
                    <strong>Define a Pipeline:</strong>
                    <ArticleCode code={`defmodule MyApp.Guardian.Pipeline do
  use Guardian.Plug.Pipeline, otp_app: :my_app,
    module: MyApp.Guardian,
    error_handler: MyApp.AuthErrorHandler

  plug Guardian.Plug.VerifyHeader, realm: "Bearer"
  plug Guardian.Plug.EnsureAuthenticated
  plug Guardian.Plug.LoadResource
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Test Example:</strong>
                    <ArticleCode code={`defmodule MyAppWeb.PageControllerTest do
  use MyAppWeb.ConnCase

  test "GET /", %{conn: conn} do
    conn = get(conn, "/")
    assert html_response(conn, 200) =~ "Welcome to Phoenix!"
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Distillery:</strong>
                    <ArticleCode code={`mix release.init`} />
                  </li>
                  <li>
                    <strong>Create a Release:</strong>
                    <ArticleCode code={`mix release`} />
                  </li>
                  <li>
                    <strong>Run the Release:</strong>
                    <ArticleCode code={`_build/prod/rel/my_app/bin/my_app start`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoenixCheatsheet;
