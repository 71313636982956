import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";


const PHPCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                PHP Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`<?php
echo "Hello, World!";
?>`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`<?php
$x = 5;
$y = 3.14;
$z = 10;
$s = "Hello";
?>`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`<?php
// This is a single-line comment
/*
This is a
multi-line comment
*/
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`<?php
$x = 10;        // Integer
$y = 2.5;       // Float
$c = 'A';       // Character
$b = true;      // Boolean
?>`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`<?php
$s = "Hello, World!";
?>`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`<?php
$arr = array(1, 2, 3);
?>`} />
                  </li>
                  <li>
                    <strong>Associative Arrays:</strong>
                    <ArticleCode code={`<?php
$dict = array("name" => "John", "age" => 30);
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`<?php
if ($x > 0) {
    echo "x is positive";
} elseif ($x == 0) {
    echo "x is zero";
} else {
    echo "x is negative";
}
?>`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`<?php
for ($i = 0; $i < 5; $i++) {
    echo $i;
}
?>`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`<?php
$x = 5;
while ($x > 0) {
    echo $x;
    $x--;
}
?>`} />
                  </li>
                  <li>
                    <strong>Foreach Loops:</strong>
                    <ArticleCode code={`<?php
$arr = array(1, 2, 3);
foreach ($arr as $value) {
    echo $value;
}
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`<?php
function myFunction() {
    echo "Hello from a function";
}
?>`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`<?php
function add($a, $b) {
    return $a + $b;
}

$result = add(2, 3);
echo $result;
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes and Objects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`<?php
class Person {
    public $name;
    public $age;

    function __construct($name, $age) {
        $this->name = $name;
        $this->age = $age;
    }

    function greet() {
        echo "Hello, my name is " . $this->name;
    }
}
?>`} />
                  </li>
                  <li>
                    <strong>Creating an Instance:</strong>
                    <ArticleCode code={`<?php
$person = new Person("John", 30);
$person->greet();
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Base and Derived Classes:</strong>
                    <ArticleCode code={`<?php
class Base {
    function display() {
        echo "Base class";
    }
}

class Derived extends Base {
    function show() {
        echo "Derived class";
    }
}
?>`} />
                  </li>
                  <li>
                    <strong>Using Inheritance:</strong>
                    <ArticleCode code={`<?php
$obj = new Derived();
$obj->display();
$obj->show();
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`<?php
$data = file_get_contents("file.txt");
echo $data;
?>`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`<?php
file_put_contents("file.txt", "Hello, World!");
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Try...Catch:</strong>
                    <ArticleCode code={`<?php
try {
    $result = 10 / 0;
} catch (Exception $e) {
    echo "Exception caught: " . $e->getMessage();
}
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Sessions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Starting a Session:</strong>
                    <ArticleCode code={`<?php
session_start();
?>`} />
                  </li>
                  <li>
                    <strong>Setting Session Variables:</strong>
                    <ArticleCode code={`<?php
$_SESSION["username"] = "JohnDoe";
?>`} />
                  </li>
                  <li>
                    <strong>Getting Session Variables:</strong>
                    <ArticleCode code={`<?php
$username = $_SESSION["username"];
echo $username;
?>`} />
                  </li>
                  <li>
                    <strong>Destroying a Session:</strong>
                    <ArticleCode code={`<?php
session_destroy();
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Cookies:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Cookies:</strong>
                    <ArticleCode code={`<?php
setcookie("username", "JohnDoe", time() + (86400 * 30), "/"); // 86400 = 1 day
?>`} />
                  </li>
                  <li>
                    <strong>Getting Cookies:</strong>
                    <ArticleCode code={`<?php
$username = $_COOKIE["username"];
echo $username;
?>`} />
                  </li>
                  <li>
                    <strong>Deleting Cookies:</strong>
                    <ArticleCode code={`<?php
setcookie("username", "", time() - 3600, "/");
?>`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PHPCheatsheet;
