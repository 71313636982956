import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const CppCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div class="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div class="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
              {/* <PDFViewer /> */}
            </div>
            </Sidebar>
          </div>
          <div class="md:col-span-4 p-1">
            <div class="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                C++ Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`#include <iostream>
using namespace std;

int main() {
    cout << "Hello, World!" << endl;
    return 0;
}`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`int x = 5;
double y = 3.14;
char c = 'A';
string s = "Hello";`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`// This is a single-line comment
/* This is a
   multi-line comment */`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Primitive Types:</strong>
                    <ArticleCode code={`int x = 10;
double y = 2.5;
char c = 'A';
bool b = true;`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`string s = "Hello, World!";`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if (x > 0) {
    cout << "x is positive" << endl;
} else if (x == 0) {
    cout << "x is zero" << endl;
} else {
    cout << "x is negative" << endl;
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for (int i = 0; i < 5; i++) {
    cout << i << endl;
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`while (x > 0) {
    cout << x << endl;
    x--;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`void myFunction() {
    cout << "Hello from a function" << endl;
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`int add(int a, int b) {
    return a + b;
}

int result = add(2, 3);
cout << result << endl;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Classes and Objects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Defining a Class:</strong>
                    <ArticleCode code={`class MyClass {
public:
    int myNumber;
    void myMethod() {
        cout << "Hello, World!" << endl;
    }
};`} />
                  </li>
                  <li>
                    <strong>Creating an Object:</strong>
                    <ArticleCode code={`MyClass obj;
obj.myNumber = 5;
obj.myMethod();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Base and Derived Classes:</strong>
                    <ArticleCode code={`class Base {
public:
    void display() {
        cout << "Base class" << endl;
    }
};

class Derived : public Base {
public:
    void show() {
        cout << "Derived class" << endl;
    }
};`} />
                  </li>
                  <li>
                    <strong>Using Inheritance:</strong>
                    <ArticleCode code={`Derived obj;
obj.display();
obj.show();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Pointers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Defining Pointers:</strong>
                    <ArticleCode code={`int x = 10;
int* ptr = &x;`} />
                  </li>
                  <li>
                    <strong>Dereferencing Pointers:</strong>
                    <ArticleCode code={`cout << *ptr << endl;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Standard Template Library (STL):</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Vectors:</strong>
                    <ArticleCode code={`#include <vector>
vector<int> v = {1, 2, 3};
v.push_back(4);
for (int i : v) {
    cout << i << endl;
}`} />
                  </li>
                  <li>
                    <strong>Maps:</strong>
                    <ArticleCode code={`#include <map>
map<int, string> m;
m[1] = "one";
m[2] = "two";
for (const auto& pair : m) {
    cout << pair.first << ": " << pair.second << endl;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Exception Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul class="list-disc pl-5">
                  <li>
                    <strong>Try...Catch:</strong>
                    <ArticleCode code={`try {
    int result = 10 / 0;
} catch (exception& e) {
    cout << "Exception caught: " << e.what() << endl;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div class="md:col-span-2">
            <Sidebar>
              <div class="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default CppCheatsheet;
