import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const CSS3Cheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                CSS3 Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>CSS Rule:</strong>
                    <ArticleCode code={`selector {
    property: value;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Selectors:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Class Selector:</strong>
                    <ArticleCode code={`.classname {
    property: value;
}`} />
                  </li>
                  <li>
                    <strong>ID Selector:</strong>
                    <ArticleCode code={`#idname {
    property: value;
}`} />
                  </li>
                  <li>
                    <strong>Attribute Selector:</strong>
                    <ArticleCode code={`[attribute=value] {
    property: value;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Box Model:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Box Model Properties:</strong>
                    <ArticleCode code={`.box {
    width: 100px;
    height: 100px;
    padding: 10px;
    border: 5px solid black;
    margin: 20px;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Colors:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Color Names:</strong>
                    <ArticleCode code={`color: red;`} />
                  </li>
                  <li>
                    <strong>Hexadecimal Colors:</strong>
                    <ArticleCode code={`color: #ff0000;`} />
                  </li>
                  <li>
                    <strong>RGB Colors:</strong>
                    <ArticleCode code={`color: rgb(255, 0, 0);`} />
                  </li>
                  <li>
                    <strong>RGBA Colors:</strong>
                    <ArticleCode code={`color: rgba(255, 0, 0, 0.5);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Text:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Text Properties:</strong>
                    <ArticleCode code={`.text {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
    text-decoration: underline;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Backgrounds:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Background Properties:</strong>
                    <ArticleCode code={`.background {
    background-color: #f0f0f0;
    background-image: url('image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Layouts:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Flexbox:</strong>
                    <ArticleCode code={`.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item {
    flex: 1;
}`} />
                  </li>
                  <li>
                    <strong>Grid:</strong>
                    <ArticleCode code={`.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.item {
    background-color: #ccc;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Transitions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Transition:</strong>
                    <ArticleCode code={`.element {
    transition: all 0.3s ease;
}

.element:hover {
    transform: scale(1.1);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Animations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Keyframes:</strong>
                    <ArticleCode code={`@keyframes example {
    from {background-color: red;}
    to {background-color: yellow;}
}

.element {
    animation-name: example;
    animation-duration: 4s;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Media Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Media Query:</strong>
                    <ArticleCode code={`@media (max-width: 600px) {
    .element {
        background-color: lightblue;
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Pseudo-classes and Pseudo-elements:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Pseudo-classes:</strong>
                    <ArticleCode code={`a:hover {
    color: red;
}

input:focus {
    border-color: blue;
}`} />
                  </li>
                  <li>
                    <strong>Pseudo-elements:</strong>
                    <ArticleCode code={`p::before {
    content: "Note: ";
    font-weight: bold;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default CSS3Cheatsheet;
