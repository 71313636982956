import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const TailwindCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Tailwind CSS Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Include Tailwind CSS:</strong>
                    <ArticleCode code={`<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Utility Classes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Background Color:</strong>
                    <ArticleCode code={`<div class="bg-blue-500">Blue background</div>`} />
                  </li>
                  <li>
                    <strong>Text Color:</strong>
                    <ArticleCode code={`<div class="text-red-500">Red text</div>`} />
                  </li>
                  <li>
                    <strong>Padding:</strong>
                    <ArticleCode code={`<div class="p-4">Padding of 1rem</div>`} />
                  </li>
                  <li>
                    <strong>Margin:</strong>
                    <ArticleCode code={`<div class="m-4">Margin of 1rem</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Flexbox:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Flex:</strong>
                    <ArticleCode code={`<div class="flex">
  <div class="flex-1">Flex 1</div>
  <div class="flex-1">Flex 2</div>
</div>`} />
                  </li>
                  <li>
                    <strong>Justify Content:</strong>
                    <ArticleCode code={`<div class="flex justify-center">Centered</div>`} />
                  </li>
                  <li>
                    <strong>Align Items:</strong>
                    <ArticleCode code={`<div class="flex items-center">Aligned Center</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Grid:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Grid:</strong>
                    <ArticleCode code={`<div class="grid grid-cols-3 gap-4">
  <div class="col-span-1">Column 1</div>
  <div class="col-span-1">Column 2</div>
  <div class="col-span-1">Column 3</div>
</div>`} />
                  </li>
                  <li>
                    <strong>Responsive Grid:</strong>
                    <ArticleCode code={`<div class="grid grid-cols-1 md:grid-cols-3 gap-4">
  <div class="col-span-1">Column 1</div>
  <div class="col-span-1">Column 2</div>
  <div class="col-span-1">Column 3</div>
</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Typography:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Font Size:</strong>
                    <ArticleCode code={`<div class="text-xl">Extra Large Text</div>`} />
                  </li>
                  <li>
                    <strong>Font Weight:</strong>
                    <ArticleCode code={`<div class="font-bold">Bold Text</div>`} />
                  </li>
                  <li>
                    <strong>Text Alignment:</strong>
                    <ArticleCode code={`<div class="text-center">Center Aligned Text</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Spacing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Margin:</strong>
                    <ArticleCode code={`<div class="m-4">Margin 1rem</div>`} />
                  </li>
                  <li>
                    <strong>Padding:</strong>
                    <ArticleCode code={`<div class="p-4">Padding 1rem</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Backgrounds:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Background Color:</strong>
                    <ArticleCode code={`<div class="bg-green-500">Green Background</div>`} />
                  </li>
                  <li>
                    <strong>Background Image:</strong>
                    <ArticleCode code={`<div class="bg-cover bg-center" style="background-image: url('path/to/image.jpg');">Background Image</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Borders:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Border Width:</strong>
                    <ArticleCode code={`<div class="border-2">Border Width 2px</div>`} />
                  </li>
                  <li>
                    <strong>Border Color:</strong>
                    <ArticleCode code={`<div class="border-red-500">Red Border</div>`} />
                  </li>
                  <li>
                    <strong>Border Radius:</strong>
                    <ArticleCode code={`<div class="rounded-full">Fully Rounded</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>States:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hover State:</strong>
                    <ArticleCode code={`<div class="hover:bg-blue-500">Hover Blue Background</div>`} />
                  </li>
                  <li>
                    <strong>Focus State:</strong>
                    <ArticleCode code={`<input class="focus:border-blue-500" type="text" placeholder="Focus Border Blue">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Transitions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Transition:</strong>
                    <ArticleCode code={`<div class="transition duration-300 ease-in-out transform hover:scale-105">Hover to Scale</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Responsive Design:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Responsive Utilities:</strong>
                    <ArticleCode code={`<div class="hidden md:block">Hidden on small screens, visible on medium and larger</div>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Customizing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using @apply:</strong>
                    <ArticleCode code={`@apply bg-blue-500 text-white font-bold py-2 px-4 rounded;`} />
                  </li>
                  <li>
                    <strong>Configuring Tailwind:</strong>
                    <ArticleCode code={`module.exports = {
  theme: {
    extend: {
      colors: {
        'custom-blue': '#1c92d2',
      },
    },
  },
  variants: {},
  plugins: [],
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default TailwindCheatsheet;
