import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const PyTorchCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                PyTorch Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to PyTorch:</ArticleSubtitle>
              <ArticleParagraph>
                <p>PyTorch is an open-source machine learning library developed by Facebook's AI Research lab. It provides flexibility and speed, making it a popular choice for deep learning research and production. This cheat sheet covers essential commands and operations to help you get started with PyTorch for building and training machine learning models.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install PyTorch:</strong>
                    <ArticleCode code={`pip install torch torchvision`} />
                  </li>
                  <li>
                    <strong>Import PyTorch:</strong>
                    <ArticleCode code={`import torch`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Tensors:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Tensors:</strong>
                    <ArticleCode code={`import torch

a = torch.tensor([1, 2, 3])
b = torch.tensor([[1, 2], [3, 4]])
print(a)
print(b)`} />
                  </li>
                  <li>
                    <strong>Tensors from NumPy:</strong>
                    <ArticleCode code={`import numpy as np
import torch

n_array = np.array([1, 2, 3])
t_tensor = torch.from_numpy(n_array)
print(t_tensor)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Tensor Addition:</strong>
                    <ArticleCode code={`import torch

a = torch.tensor([1, 2, 3])
b = torch.tensor([4, 5, 6])
c = a + b
print(c)`} />
                  </li>
                  <li>
                    <strong>Tensor Multiplication:</strong>
                    <ArticleCode code={`import torch

a = torch.tensor([1, 2, 3])
b = torch.tensor([4, 5, 6])
c = a * b
print(c)`} />
                  </li>
                  <li>
                    <strong>Matrix Multiplication:</strong>
                    <ArticleCode code={`import torch

a = torch.tensor([[1, 2], [3, 4]])
b = torch.tensor([[5, 6], [7, 8]])
c = torch.matmul(a, b)
print(c)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Building a Model:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define a Model:</strong>
                    <ArticleCode code={`import torch.nn as nn

class SimpleModel(nn.Module):
    def __init__(self):
        super(SimpleModel, self).__init__()
        self.fc = nn.Linear(10, 1)

    def forward(self, x):
        return self.fc(x)

model = SimpleModel()
print(model)`} />
                  </li>
                  <li>
                    <strong>Define Loss and Optimizer:</strong>
                    <ArticleCode code={`import torch.optim as optim

criterion = nn.MSELoss()
optimizer = optim.SGD(model.parameters(), lr=0.01)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Training a Model:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Training Loop:</strong>
                    <ArticleCode code={`for epoch in range(10):
    optimizer.zero_grad()
    outputs = model(inputs)
    loss = criterion(outputs, targets)
    loss.backward()
    optimizer.step()
    print(f'Epoch {epoch+1}, Loss: {loss.item()}')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Evaluating a Model:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Evaluate Model:</strong>
                    <ArticleCode code={`with torch.no_grad():
    outputs = model(test_inputs)
    loss = criterion(outputs, test_targets)
    print(f'Test Loss: {loss.item()}')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Pipeline:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>DataLoader:</strong>
                    <ArticleCode code={`from torch.utils.data import DataLoader, TensorDataset

dataset = TensorDataset(inputs, targets)
dataloader = DataLoader(dataset, batch_size=32, shuffle=True)

for batch in dataloader:
    print(batch)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Saving and Loading Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Save Model:</strong>
                    <ArticleCode code={`torch.save(model.state_dict(), 'model.pth')`} />
                  </li>
                  <li>
                    <strong>Load Model:</strong>
                    <ArticleCode code={`model.load_state_dict(torch.load('model.pth'))`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Using GPU:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check for GPU:</strong>
                    <ArticleCode code={`device = torch.device('cuda' if torch.cuda.is_available() else 'cpu')
print(device)`} />
                  </li>
                  <li>
                    <strong>Move Tensors to GPU:</strong>
                    <ArticleCode code={`inputs = inputs.to(device)
model = model.to(device)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use DataLoader:</strong> Utilize DataLoader for efficient data handling and batching.</li>
                  <li><strong>Leverage GPU:</strong> Use GPU for faster training and inference.</li>
                  <li><strong>Implement Regularization:</strong> Apply regularization techniques to prevent overfitting.</li>
                  <li><strong>Optimize Hyperparameters:</strong> Perform hyperparameter tuning to improve model performance.</li>
                  <li><strong>Monitor Training:</strong> Track training metrics and visualize performance using tools like TensorBoard.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PyTorchCheatsheet;
