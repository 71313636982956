import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const CICDCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                CI/CD Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to CI/CD:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Continuous Integration (CI) and Continuous Deployment (CD) are practices that aim to improve software development through automated testing, integration, and deployment. Below are essential commands and configurations for popular CI/CD tools.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Jenkins:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple Pipeline:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  stages {
    stage('Build') {
      steps {
        echo 'Building...'
      }
    }
    stage('Test') {
      steps {
        echo 'Testing...'
      }
    }
    stage('Deploy') {
      steps {
        echo 'Deploying...'
      }
    }
  }
}`} />
                  </li>
                  <li>
                    <strong>Declarative Pipeline Syntax:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  environment {
    VAR_NAME = 'value'
  }
  stages {
    stage('Example') {
      steps {
        script {
          echo "Environment variable: \${VAR_NAME}"
        }
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>GitHub Actions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple Workflow:</strong>
                    <ArticleCode code={`name: CI

on: [push, pull_request]

jobs:
  build:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v2
    - name: Set up Node.js
      uses: actions/setup-node@v2
      with:
        node-version: '14'
    - run: npm install
    - run: npm test`} />
                  </li>
                  <li>
                    <strong>Deploy to GitHub Pages:</strong>
                    <ArticleCode code={`name: Deploy to GitHub Pages

on:
  push:
    branches:
      - main

jobs:
  deploy:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v2
    - name: Build
      run: |
        npm install
        npm run build
    - name: Deploy
      uses: peaceiris/actions-gh-pages@v3
      with:
        github_token: \${{ secrets.GITHUB_TOKEN }}
        publish_dir: ./build`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>GitLab CI/CD:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple GitLab CI/CD Pipeline:</strong>
                    <ArticleCode code={`stages:
  - build
  - test
  - deploy

build:
  stage: build
  script:
    - echo "Building..."

test:
  stage: test
  script:
    - echo "Testing..."

deploy:
  stage: deploy
  script:
    - echo "Deploying..."`} />
                  </li>
                  <li>
                    <strong>Using Docker in GitLab CI:</strong>
                    <ArticleCode code={`image: docker:latest

services:
  - docker:dind

stages:
  - build

build:
  stage: build
  script:
    - docker build -t my-image .`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CircleCI:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple CircleCI Config:</strong>
                    <ArticleCode code={`version: 2.1

jobs:
  build:
    docker:
      - image: circleci/node:14
    steps:
      - checkout
      - run: npm install
      - run: npm test`} />
                  </li>
                  <li>
                    <strong>Deploy to AWS S3:</strong>
                    <ArticleCode code={`version: 2.1

orbs:
  aws-s3: circleci/aws-s3@1.0.0

jobs:
  build:
    docker:
      - image: circleci/node:14
    steps:
      - checkout
      - run: npm install
      - run: npm run build
  deploy:
    docker:
      - image: circleci/python:3.6
    steps:
      - aws-s3/sync:
          from: build
          to: s3://my-bucket/path
          arguments: --acl public-read

workflows:
  version: 2
  build-deploy:
    jobs:
      - build
      - deploy:
          requires:
            - build`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Azure Pipelines:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple Azure Pipeline:</strong>
                    <ArticleCode code={`trigger:
- main

pool:
  vmImage: 'ubuntu-latest'

steps:
- task: UseNode@2
  inputs:
    version: '14.x'
- script: |
    npm install
    npm test
  displayName: 'Install and Test'`} />
                  </li>
                  <li>
                    <strong>Deploy to Azure Web App:</strong>
                    <ArticleCode code={`trigger:
- main

pool:
  vmImage: 'ubuntu-latest'

steps:
- task: UseNode@2
  inputs:
    version: '14.x'
- script: |
    npm install
    npm run build
  displayName: 'Build'
- task: AzureWebApp@1
  inputs:
    azureSubscription: 'your-azure-subscription'
    appName: 'your-app-name'
    package: '$(System.DefaultWorkingDirectory)/**/build.zip'`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Travis CI:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple Travis CI Config:</strong>
                    <ArticleCode code={`language: node_js
node_js:
  - "14"
script:
  - npm install
  - npm test`} />
                  </li>
                  <li>
                    <strong>Deploy to Heroku:</strong>
                    <ArticleCode code={`language: node_js
node_js:
  - "14"
script:
  - npm install
  - npm test
deploy:
  provider: heroku
  api_key:
    secure: "YOUR_ENCRYPTED_API_KEY"
  app: your-app-name`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common CI/CD Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Version Control:</strong> Use version control systems like Git to manage your codebase.</li>
                  <li><strong>Automated Testing:</strong> Implement automated tests to ensure your code works as expected.</li>
                  <li><strong>Build Automation:</strong> Automate the build process to compile and package your code.</li>
                  <li><strong>Continuous Deployment:</strong> Automate the deployment process to ensure your application is always up to date.</li>
                  <li><strong>Monitoring and Alerts:</strong> Implement monitoring and alerting to catch issues early and ensure system stability.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default CICDCheatsheet;
