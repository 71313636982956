import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const AspNetCoreCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                ASP.NET Core Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install .NET SDK:</strong>
                    <ArticleCode code={`# Windows
winget install Microsoft.DotNet.SDK.6

# macOS
brew install --cask dotnet-sdk`} />
                  </li>
                  <li>
                    <strong>Create a New Project:</strong>
                    <ArticleCode code={`dotnet new webapp -o MyWebApp`} />
                  </li>
                  <li>
                    <strong>Run the Project:</strong>
                    <ArticleCode code={`cd MyWebApp
dotnet run`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Controllers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Controller:</strong>
                    <ArticleCode code={`dotnet new controller -name MyController -namespace MyWebApp.Controllers`} />
                  </li>
                  <li>
                    <strong>Basic Controller Example:</strong>
                    <ArticleCode code={`using Microsoft.AspNetCore.Mvc;

namespace MyWebApp.Controllers
{
    [ApiController]
    [Route("[controller]")]
    public class MyController : ControllerBase
    {
        [HttpGet]
        public IActionResult Get()
        {
            return Ok("Hello World!");
        }
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Attribute Routing:</strong>
                    <ArticleCode code={`[Route("api/[controller]")]
public class MyController : ControllerBase
{
    [HttpGet]
    public IActionResult Get()
    {
        return Ok("Hello World!");
    }
}`} />
                  </li>
                  <li>
                    <strong>Conventional Routing:</strong>
                    <ArticleCode code={`app.UseEndpoints(endpoints =>
{
    endpoints.MapControllerRoute(
        name: "default",
        pattern: "{controller=Home}/{action=Index}/{id?}");
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Views:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a View:</strong>
                    <ArticleCode code={`<!-- Views/Home/Index.cshtml -->
@{
    ViewData["Title"] = "Home Page";
}

<div class="text-center">
    <h1 class="display-4">Welcome</h1>
    <p>ASP.NET Core MVC Application</p>
</div>`} />
                  </li>
                  <li>
                    <strong>Using ViewData:</strong>
                    <ArticleCode code={`public IActionResult Index()
{
    ViewData["Message"] = "Hello from ViewData!";
    return View();
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a Model:</strong>
                    <ArticleCode code={`public class User
{
    public int Id { get; set; }
    public string Name { get; set; }
}`} />
                  </li>
                  <li>
                    <strong>Using a Model in a View:</strong>
                    <ArticleCode code={`<!-- Views/Users/Index.cshtml -->
@model IEnumerable<MyWebApp.Models.User>

<h1>Users</h1>
<ul>
    @foreach (var user in Model)
    {
        <li>@user.Name</li>
    }
</ul>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Dependency Injection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Registering a Service:</strong>
                    <ArticleCode code={`public interface IMyService
{
    string GetMessage();
}

public class MyService : IMyService
{
    public string GetMessage()
    {
        return "Hello from MyService!";
    }
}

// Startup.cs
public void ConfigureServices(IServiceCollection services)
{
    services.AddTransient<IMyService, MyService>();
}`} />
                  </li>
                  <li>
                    <strong>Injecting a Service:</strong>
                    <ArticleCode code={`public class HomeController : Controller
{
    private readonly IMyService _myService;

    public HomeController(IMyService myService)
    {
        _myService = myService;
    }

    public IActionResult Index()
    {
        var message = _myService.GetMessage();
        ViewData["Message"] = message;
        return View();
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Entity Framework Core:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install EF Core:</strong>
                    <ArticleCode code={`dotnet add package Microsoft.EntityFrameworkCore.SqlServer
dotnet add package Microsoft.EntityFrameworkCore.Tools`} />
                  </li>
                  <li>
                    <strong>Define a DbContext:</strong>
                    <ArticleCode code={`using Microsoft.EntityFrameworkCore;

public class AppDbContext : DbContext
{
    public AppDbContext(DbContextOptions<AppDbContext> options)
        : base(options)
    {
    }

    public DbSet<User> Users { get; set; }
}`} />
                  </li>
                  <li>
                    <strong>Configure DbContext:</strong>
                    <ArticleCode code={`public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext<AppDbContext>(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));
}`} />
                  </li>
                  <li>
                    <strong>Run Migrations:</strong>
                    <ArticleCode code={`dotnet ef migrations add InitialCreate
dotnet ef database update`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication and Authorization:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setup Identity:</strong>
                    <ArticleCode code={`dotnet add package Microsoft.AspNetCore.Identity.EntityFrameworkCore`} />
                  </li>
                  <li>
                    <strong>Configure Identity:</strong>
                    <ArticleCode code={`public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext<AppDbContext>(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));

    services.AddDefaultIdentity<IdentityUser>()
        .AddEntityFrameworkStores<AppDbContext>();

    services.AddControllersWithViews();
}`} />
                  </li>
                  <li>
                    <strong>Use Identity Middleware:</strong>
                    <ArticleCode code={`public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage();
    }
    else
    {
        app.UseExceptionHandler("/Home/Error");
        app.UseHsts();
    }
    app.UseHttpsRedirection();
    app.UseStaticFiles();

    app.UseRouting();

    app.UseAuthentication();
    app.UseAuthorization();

    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllerRoute(
            name: "default",
            pattern: "{controller=Home}/{action=Index}/{id?}");
        endpoints.MapRazorPages();
    });
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Logging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Logging:</strong>
                    <ArticleCode code={`public class HomeController : Controller
{
    private readonly ILogger<HomeController> _logger;

    public HomeController(ILogger<HomeController> logger)
    {
        _logger = logger;
    }

    public IActionResult Index()
    {
        _logger.LogInformation("This is an information log");
        return View();
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Configuration:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>AppSettings.json:</strong>
                    <ArticleCode code={`{
  "Logging": {
    "LogLevel": {
      "Default": "Information",
      "Microsoft": "Warning",
      "Microsoft.Hosting.Lifetime": "Information"
    }
  },
  "AllowedHosts": "*",
  "MyCustomSetting": "This is a custom setting"
}`} />
                  </li>
                  <li>
                    <strong>Access Configuration:</strong>
                    <ArticleCode code={`public class HomeController : Controller
{
    private readonly IConfiguration _configuration;

    public HomeController(IConfiguration configuration)
    {
        _configuration = configuration;
    }

    public IActionResult Index()
    {
        var customSetting = _configuration["MyCustomSetting"];
        ViewData["Message"] = customSetting;
        return View();
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Testing Packages:</strong>
                    <ArticleCode code={`dotnet add package Microsoft.AspNetCore.Mvc.Testing
dotnet add package xunit
dotnet add package xunit.runner.visualstudio
dotnet add package Moq`} />
                  </li>
                  <li>
                    <strong>Basic Unit Test:</strong>
                    <ArticleCode code={`using Xunit;

public class UnitTest1
{
    [Fact]
    public void Test1()
    {
        Assert.True(true);
    }
}`} />
                  </li>
                  <li>
                    <strong>Integration Test:</strong>
                    <ArticleCode code={`using Microsoft.AspNetCore.Mvc.Testing;
using System.Net.Http;
using System.Threading.Tasks;
using Xunit;

public class IntegrationTest : IClassFixture<WebApplicationFactory<MyWebApp.Startup>>
{
    private readonly HttpClient _client;

    public IntegrationTest(WebApplicationFactory<MyWebApp.Startup> factory)
    {
        _client = factory.CreateClient();
    }

    [Fact]
    public async Task GetHomePage_ReturnsSuccess()
    {
        var response = await _client.GetAsync("/");
        response.EnsureSuccessStatusCode();
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Publish to Folder:</strong>
                    <ArticleCode code={`dotnet publish --configuration Release --output ./publish`} />
                  </li>
                  <li>
                    <strong>Deploy to Azure:</strong>
                    <ArticleCode code={`# Install Azure CLI
curl -sL https://aka.ms/InstallAzureCLIDeb | sudo bash

# Login to Azure
az login

# Create Web App
az webapp create --resource-group MyResourceGroup --plan MyPlan --name MyWebApp --runtime "DOTNET|6.0"

# Deploy
az webapp deploy --resource-group MyResourceGroup --name MyWebApp --src-path ./publish`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default AspNetCoreCheatsheet;
