import React from "react";

function MicroservicesHomePage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-4xl font-bold text-blue-600 mb-8">Welcome to Microservices Resources</h1>
      <p className="text-lg text-gray-700 mb-8 max-w-xl text-center">
      Microservices is an architectural style that structures an application as a collection of small, autonomous services modeled around a business domain. Each microservice is independently deployable, loosely coupled, and can be developed, tested, and scaled independently. This approach allows for greater flexibility, scalability, and resilience compared to traditional monolithic architectures. Microservices communicate with each other through well-defined APIs and can be built using different technologies and programming languages. This architecture is particularly well-suited for large, complex applications that require rapid, continuous delivery of new features and updates.      </p>
      <div className="space-y-4">
        <button
          onClick={() => window.location.href = '/cheat-sheets'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Cheat Sheets
        </button>
        <button
          onClick={() => window.location.href = '/tutorialspage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Tutorials
        </button>
        <button
          onClick={() => window.location.href = '/interviewquestions'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Interview Questions
        </button>
        <button
          onClick={() => window.location.href = '/projectshomepage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Projects
        </button>
      </div>
    </div>
  );
}

export default MicroservicesHomePage;
