import React from 'react';
import Box from './Box';

const BoxesContainer = ({ data }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 p-4">
      {data.map((boxData, index) => (
        <Box
          key={index}
          title={boxData.title}
          urls={boxData.linkToResources}
          mainPageUrl={boxData.mainPageUrl} // Passing the mainPageUrl to each Box component
        />
      ))}
    </div>
  );
};

export default BoxesContainer;
