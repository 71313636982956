import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const JQueryCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                jQuery Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Include jQuery:</strong>
                    <ArticleCode code={`<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Selectors:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Selectors:</strong>
                    <ArticleCode code={`$(document).ready(function(){
  $('p').click(function(){
    $(this).hide();
  });
});`} />
                  </li>
                  <li>
                    <strong>ID Selector:</strong>
                    <ArticleCode code={`$('#myId').hide();`} />
                  </li>
                  <li>
                    <strong>Class Selector:</strong>
                    <ArticleCode code={`$('.myClass').hide();`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Events:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Click Event:</strong>
                    <ArticleCode code={`$('button').click(function(){
  alert('Button clicked!');
});`} />
                  </li>
                  <li>
                    <strong>Mouse Events:</strong>
                    <ArticleCode code={`$('p').mouseenter(function(){
  $(this).css('background-color', 'yellow');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Effects:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hide/Show:</strong>
                    <ArticleCode code={`$('#hide').click(function(){
  $('p').hide();
});

$('#show').click(function(){
  $('p').show();
});`} />
                  </li>
                  <li>
                    <strong>Fade In/Out:</strong>
                    <ArticleCode code={`$('#fadeIn').click(function(){
  $('p').fadeIn();
});

$('#fadeOut').click(function(){
  $('p').fadeOut();
});`} />
                  </li>
                  <li>
                    <strong>Slide Up/Down:</strong>
                    <ArticleCode code={`$('#slideUp').click(function(){
  $('p').slideUp();
});

$('#slideDown').click(function(){
  $('p').slideDown();
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>HTML Manipulation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Get/Set HTML:</strong>
                    <ArticleCode code={`$('#getHtml').click(function(){
  alert($('p').html());
});

$('#setHtml').click(function(){
  $('p').html('New content');
});`} />
                  </li>
                  <li>
                    <strong>Get/Set Text:</strong>
                    <ArticleCode code={`$('#getText').click(function(){
  alert($('p').text());
});

$('#setText').click(function(){
  $('p').text('New text');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CSS Manipulation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Get/Set CSS:</strong>
                    <ArticleCode code={`$('#getCss').click(function(){
  alert($('p').css('color'));
});

$('#setCss').click(function(){
  $('p').css('color', 'blue');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Attributes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Get/Set Attributes:</strong>
                    <ArticleCode code={`$('#getAttr').click(function(){
  alert($('p').attr('id'));
});

$('#setAttr').click(function(){
  $('p').attr('id', 'newId');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Traversing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Find:</strong>
                    <ArticleCode code={`$('div').find('p').hide();`} />
                  </li>
                  <li>
                    <strong>Children:</strong>
                    <ArticleCode code={`$('div').children('p').hide();`} />
                  </li>
                  <li>
                    <strong>Parent:</strong>
                    <ArticleCode code={`$('p').parent().css('background-color', 'yellow');`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>AJAX:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Load:</strong>
                    <ArticleCode code={`$('#loadData').click(function(){
  $('#result').load('data.txt');
});`} />
                  </li>
                  <li>
                    <strong>Get:</strong>
                    <ArticleCode code={`$.get('data.txt', function(data){
  $('#result').html(data);
});`} />
                  </li>
                  <li>
                    <strong>Post:</strong>
                    <ArticleCode code={`$.post('submit.php', { name: 'John', age: 25 }, function(data){
  $('#result').html(data);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Animations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Animation:</strong>
                    <ArticleCode code={`$('button').click(function(){
  $('p').animate({ left: '250px' });
});`} />
                  </li>
                  <li>
                    <strong>Chaining Animations:</strong>
                    <ArticleCode code={`$('button').click(function(){
  $('p').css('color', 'red').slideUp(2000).slideDown(2000);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Document Ready:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Document Ready:</strong>
                    <ArticleCode code={`$(document).ready(function(){
  alert('Document is ready!');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Form Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Form Submit:</strong>
                    <ArticleCode code={`$('form').submit(function(event){
  event.preventDefault();
  alert('Form submitted!');
});`} />
                  </li>
                  <li>
                    <strong>Input Value:</strong>
                    <ArticleCode code={`$('#getValue').click(function(){
  alert($('input').val());
});

$('#setValue').click(function(){
  $('input').val('New value');
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default JQueryCheatsheet;
