import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const ReactNativeCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                React Native Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to React Native:</ArticleSubtitle>
              <ArticleParagraph>
                <p>React Native is a popular framework for building mobile applications using JavaScript and React. This cheat sheet covers essential components, commands, and best practices to help you get started with React Native development.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install React Native CLI:</strong>
                    <ArticleCode code={`npm install -g react-native-cli`} />
                  </li>
                  <li>
                    <strong>Create a New Project:</strong>
                    <ArticleCode code={`npx react-native init MyNewProject`} />
                  </li>
                  <li>
                    <strong>Run the Application:</strong>
                    <ArticleCode code={`cd MyNewProject
npx react-native run-android # For Android
npx react-native run-ios # For iOS`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Core Components:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Text Component:</strong>
                    <ArticleCode code={`import { Text } from 'react-native';

<Text>Hello, world!</Text>`} />
                  </li>
                  <li>
                    <strong>View Component:</strong>
                    <ArticleCode code={`import { View } from 'react-native';

<View>
  <Text>Hello, world!</Text>
</View>`} />
                  </li>
                  <li>
                    <strong>Image Component:</strong>
                    <ArticleCode code={`import { Image } from 'react-native';

<Image source={{uri: 'https://example.com/image.jpg'}} style={{width: 100, height: 100}} />`} />
                  </li>
                  <li>
                    <strong>Button Component:</strong>
                    <ArticleCode code={`import { Button } from 'react-native';

<Button
  title="Press me"
  onPress={() => Alert.alert('Button pressed!')}
/>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Styling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using StyleSheet:</strong>
                    <ArticleCode code={`import { StyleSheet, Text, View } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'blue',
    fontSize: 20,
  },
});

<View style={styles.container}>
  <Text style={styles.text}>Hello, styled world!</Text>
</View>`} />
                  </li>
                  <li>
                    <strong>Inline Styling:</strong>
                    <ArticleCode code={`<Text style={{color: 'blue', fontSize: 20}}>Hello, inline styled world!</Text>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Navigation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>React Navigation Setup:</strong>
                    <ArticleCode code={`npm install @react-navigation/native
npm install @react-navigation/stack`} />
                  </li>
                  <li>
                    <strong>Basic Navigation Example:</strong>
                    <ArticleCode code={`import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Button, View, Text } from 'react-native';

function HomeScreen({ navigation }) {
  return (
    <View>
      <Text>Home Screen</Text>
      <Button
        title="Go to Details"
        onPress={() => navigation.navigate('Details')}
      />
    </View>
  );
}

function DetailsScreen() {
  return (
    <View>
      <Text>Details Screen</Text>
    </View>
  );
}

const Stack = createStackNavigator();

function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="Details" component={DetailsScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Handling User Input:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>TextInput Component:</strong>
                    <ArticleCode code={`import { TextInput, View } from 'react-native';

const [text, setText] = useState('');

<TextInput
  style={{height: 40, borderColor: 'gray', borderWidth: 1}}
  onChangeText={text => setText(text)}
  value={text}
/>`} />
                  </li>
                  <li>
                    <strong>TouchableOpacity Component:</strong>
                    <ArticleCode code={`import { TouchableOpacity, Text, View } from 'react-native';

<TouchableOpacity
  style={{backgroundColor: 'blue', padding: 10}}
  onPress={() => Alert.alert('TouchableOpacity pressed!')}
>
  <Text style={{color: 'white'}}>Press Me</Text>
</TouchableOpacity>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Debugging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using React Developer Tools:</strong>
                    <ArticleCode code={`npm install -g react-devtools
react-devtools`} />
                  </li>
                  <li>
                    <strong>Debugging in Chrome:</strong>
                    <ArticleParagraph>
                      <p>Shake your device or press <code>Cmd+D</code> (iOS) or <code>Cmd+M</code> (Android) to open the developer menu and select "Debug".</p>
                    </ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Component Reusability:</strong> Create reusable components to maintain a clean and organized codebase.</li>
                  <li><strong>State Management:</strong> Use hooks like <code>useState</code> and <code>useEffect</code> for managing component state and side effects.</li>
                  <li><strong>Optimize Performance:</strong> Use <code>React.memo</code> and other optimization techniques to improve performance.</li>
                  <li><strong>Follow Naming Conventions:</strong> Use descriptive names for your components and variables.</li>
                  <li><strong>Testing:</strong> Implement testing using libraries like Jest and React Native Testing Library.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactNativeCheatsheet;
