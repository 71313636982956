import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const WebDevCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Web Development Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>HTML:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Structure:</strong>
                    <ArticleCode code={`<!DOCTYPE html>
<html>
<head>
    <title>Page Title</title>
</head>
<body>
    <h1>This is a Heading</h1>
    <p>This is a paragraph.</p>
</body>
</html>`} />
                  </li>
                  <li>
                    <strong>Links:</strong>
                    <ArticleCode code={`<a href="https://www.example.com">This is a link</a>`} />
                  </li>
                  <li>
                    <strong>Images:</strong>
                    <ArticleCode code={`<img src="image.jpg" alt="Description">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>CSS:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Syntax:</strong>
                    <ArticleCode code={`body {
    font-family: Arial, sans-serif;
}

h1 {
    color: blue;
}`} />
                  </li>
                  <li>
                    <strong>Classes and IDs:</strong>
                    <ArticleCode code={`.class-name {
    color: red;
}

#id-name {
    color: green;
}`} />
                  </li>
                  <li>
                    <strong>Layouts (Flexbox):</strong>
                    <ArticleCode code={`.container {
    display: flex;
}

.item {
    flex: 1;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>JavaScript:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Syntax:</strong>
                    <ArticleCode code={`console.log("Hello, World!");`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`let x = 5;
const y = 3.14;
var z = 10;`} />
                  </li>
                  <li>
                    <strong>Functions:</strong>
                    <ArticleCode code={`function myFunction() {
    console.log("Hello from a function");
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>DOM Manipulation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Selecting Elements:</strong>
                    <ArticleCode code={`const element = document.getElementById("id-name");`} />
                  </li>
                  <li>
                    <strong>Changing Content:</strong>
                    <ArticleCode code={`element.innerHTML = "New Content";`} />
                  </li>
                  <li>
                    <strong>Event Listeners:</strong>
                    <ArticleCode code={`element.addEventListener("click", function() {
    alert("Element clicked!");
});`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>AJAX:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>XMLHttpRequest:</strong>
                    <ArticleCode code={`const xhr = new XMLHttpRequest();
xhr.open("GET", "https://api.example.com/data", true);
xhr.onload = function() {
    if (xhr.status === 200) {
        console.log(xhr.responseText);
    }
};
xhr.send();`} />
                  </li>
                  <li>
                    <strong>Fetch API:</strong>
                    <ArticleCode code={`fetch("https://api.example.com/data")
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Responsive Design:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Media Queries:</strong>
                    <ArticleCode code={`@media (max-width: 600px) {
    body {
        background-color: lightblue;
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Frameworks:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Bootstrap:</strong>
                    <ArticleCode code={`<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
<button class="btn btn-primary">Button</button>`} />
                  </li>
                  <li>
                    <strong>React:</strong>
                    <ArticleCode code={`import React from 'react';
import ReactDOM from 'react-dom';

function App() {
    return <h1>Hello, World!</h1>;
}

ReactDOM.render(<App />, document.getElementById('root'));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Version Control (Git):</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Commands:</strong>
                    <ArticleCode code={`git init
git add .
git commit -m "Initial commit"
git push origin main`} />
                  </li>
                  <li>
                    <strong>Cloning a Repository:</strong>
                    <ArticleCode code={`git clone https://github.com/username/repository.git`} />
                  </li>
                  <li>
                    <strong>Branching:</strong>
                    <ArticleCode code={`git branch new-branch
git checkout new-branch`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using FTP:</strong>
                    <ArticleCode code={`// Use an FTP client to upload files to your server`} />
                  </li>
                  <li>
                    <strong>Using GitHub Pages:</strong>
                    <ArticleCode code={`// Push your repository to GitHub
// Go to the repository settings
// Enable GitHub Pages`} />
                  </li>
                  <li>
                    <strong>Using Netlify:</strong>
                    <ArticleCode code={`// Connect your repository to Netlify
// Configure build settings
// Deploy your site`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDevCheatsheet;
