import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const ApiDesignCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                API Design Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to API Design:</ArticleSubtitle>
              <ArticleParagraph>
                <p>API design involves creating an interface for software applications to interact with each other. A well-designed API is intuitive, easy to use, and meets the needs of its intended users. This cheat sheet covers essential principles and practices to help you design effective APIs.</p>
              </ArticleParagraph>

              <ArticleSubtitle>API Design Principles:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simplicity:</strong> Keep the API simple and intuitive. Aim for a minimalistic design that is easy to understand and use.
                  </li>
                  <li>
                    <strong>Consistency:</strong> Ensure consistent patterns and naming conventions across the API.
                  </li>
                  <li>
                    <strong>Documentation:</strong> Provide clear and comprehensive documentation for the API.
                  </li>
                  <li>
                    <strong>Versioning:</strong> Implement versioning to manage changes and ensure backward compatibility.
                  </li>
                  <li>
                    <strong>Security:</strong> Incorporate security measures such as authentication, authorization, and data validation.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>RESTful API Design:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Use Nouns for Resources:</strong> Name endpoints using nouns to represent resources. For example, <ArticleCode code={`/users`} />.
                  </li>
                  <li>
                    <strong>HTTP Methods:</strong> Use standard HTTP methods (GET, POST, PUT, DELETE) to perform CRUD operations.
                    <ul className="list-disc pl-5">
                      <li><strong>GET:</strong> Retrieve data.</li>
                      <li><strong>POST:</strong> Create a new resource.</li>
                      <li><strong>PUT:</strong> Update an existing resource.</li>
                      <li><strong>DELETE:</strong> Remove a resource.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Use Query Parameters:</strong> Use query parameters for filtering, sorting, and pagination.
                    <ArticleCode code={`/users?sort=asc&limit=10`} />
                  </li>
                  <li>
                    <strong>Use HTTP Status Codes:</strong> Return appropriate HTTP status codes to indicate the outcome of an operation.
                    <ul className="list-disc pl-5">
                      <li><strong>200 OK:</strong> Successful request.</li>
                      <li><strong>201 Created:</strong> Resource created successfully.</li>
                      <li><strong>400 Bad Request:</strong> Invalid request.</li>
                      <li><strong>401 Unauthorized:</strong> Authentication required.</li>
                      <li><strong>404 Not Found:</strong> Resource not found.</li>
                      <li><strong>500 Internal Server Error:</strong> Server error.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Statelessness:</strong> Ensure that each request from a client to the server contains all the information needed to understand and process the request.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>GraphQL API Design:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Schema:</strong> Define the schema using types, queries, and mutations to specify the structure and capabilities of the API.
                    <ArticleCode code={`type Query {
  user(id: ID!): User
}

type Mutation {
  createUser(name: String!): User
}

type User {
  id: ID!
  name: String!
}`} />
                  </li>
                  <li>
                    <strong>Query Language:</strong> Use GraphQL's flexible query language to allow clients to request exactly the data they need.
                    <ArticleCode code={`query {
  user(id: "1") {
    id
    name
  }
}`} />
                  </li>
                  <li>
                    <strong>Mutations:</strong> Use mutations to modify server-side data and return the modified data to the client.
                    <ArticleCode code={`mutation {
  createUser(name: "John Doe") {
    id
    name
  }
}`} />
                  </li>
                  <li>
                    <strong>Real-time Updates:</strong> Use subscriptions to enable real-time updates to clients.
                    <ArticleCode code={`subscription {
  userAdded {
    id
    name
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>API Authentication and Authorization:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>API Keys:</strong> Use API keys for basic authentication. Send the key as a query parameter or in the header.
                    <ArticleCode code={`GET /endpoint?api_key=YOUR_API_KEY`} />
                  </li>
                  <li>
                    <strong>OAuth:</strong> Use OAuth 2.0 for more secure authentication and authorization.
                    <ArticleCode code={`Authorization: Bearer ACCESS_TOKEN`} />
                  </li>
                  <li>
                    <strong>JWT:</strong> Use JSON Web Tokens (JWT) for stateless authentication.
                    <ArticleCode code={`Authorization: Bearer JWT_TOKEN`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>API Rate Limiting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Implement Rate Limiting:</strong> Protect your API from abuse by limiting the number of requests a client can make in a given period.
                    <ArticleCode code={`HTTP/1.1 429 Too Many Requests
Retry-After: 3600`} />
                  </li>
                  <li>
                    <strong>Communicate Limits:</strong> Inform clients of their rate limit status using headers.
                    <ArticleCode code={`X-RateLimit-Limit: 100
X-RateLimit-Remaining: 99
X-RateLimit-Reset: 3600`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>API Documentation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Use OpenAPI/Swagger:</strong> Use OpenAPI or Swagger to create interactive API documentation.
                  </li>
                  <li>
                    <strong>Provide Examples:</strong> Include example requests and responses in your documentation.
                  </li>
                  <li>
                    <strong>Keep Documentation Updated:</strong> Ensure your documentation is always up to date with the latest version of your API.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Design for Scalability:</strong> Ensure your API can handle increased load and usage.</li>
                  <li><strong>Implement Caching:</strong> Use caching mechanisms to improve performance and reduce load on the server.</li>
                  <li><strong>Monitor and Log:</strong> Regularly monitor and log API usage and performance to identify and resolve issues promptly.</li>
                  <li><strong>Handle Errors Gracefully:</strong> Provide meaningful error messages and status codes to help clients debug issues.</li>
                  <li><strong>Secure Your API:</strong> Implement security best practices to protect your API from attacks and unauthorized access.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiDesignCheatsheet;
