import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const PythonCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Python Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Print:</strong>
                    <ArticleCode code={`print("Hello, World!")`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`x = 5
y = "Hello"`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`# This is a comment`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Numbers:</strong>
                    <ArticleCode code={`x = 10  # int
y = 2.5  # float`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`s = "Hello, World!"`} />
                  </li>
                  <li>
                    <strong>Lists:</strong>
                    <ArticleCode code={`my_list = [1, 2, 3]`} />
                  </li>
                  <li>
                    <strong>Tuples:</strong>
                    <ArticleCode code={`my_tuple = (1, 2, 3)`} />
                  </li>
                  <li>
                    <strong>Dictionaries:</strong>
                    <ArticleCode code={`my_dict = {"name": "John", "age": 30}`} />
                  </li>
                  <li>
                    <strong>Sets:</strong>
                    <ArticleCode code={`my_set = {1, 2, 3}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if x > 0:
    print("x is positive")
elif x == 0:
    print("x is zero")
else:
    print("x is negative")`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for i in range(5):
    print(i)`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`while x > 0:
    print(x)
    x -= 1`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`def my_function():
    print("Hello from a function")`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`def add(a, b):
    return a + b

result = add(2, 3)
print(result)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Modules:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Importing Modules:</strong>
                    <ArticleCode code={`import math
print(math.sqrt(16))`} />
                  </li>
                  <li>
                    <strong>From...Import:</strong>
                    <ArticleCode code={`from math import sqrt
print(sqrt(16))`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`with open("file.txt", "r") as file:
    content = file.read()
    print(content)`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`with open("file.txt", "w") as file:
    file.write("Hello, World!")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Built-in Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Len:</strong>
                    <ArticleCode code={`len(my_list)`} />
                  </li>
                  <li>
                    <strong>Range:</strong>
                    <ArticleCode code={`range(5)`} />
                  </li>
                  <li>
                    <strong>Type:</strong>
                    <ArticleCode code={`type(x)`} />
                  </li>
                  <li>
                    <strong>Str:</strong>
                    <ArticleCode code={`str(123)`} />
                  </li>
                  <li>
                    <strong>Int:</strong>
                    <ArticleCode code={`int("123")`} />
                  </li>
                  <li>
                    <strong>Float:</strong>
                    <ArticleCode code={`float("123.45")`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Exception Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Try...Except:</strong>
                    <ArticleCode code={`try:
    print(10 / 0)
except ZeroDivisionError:
    print("Cannot divide by zero")`} />
                  </li>
                  <li>
                    <strong>Finally:</strong>
                    <ArticleCode code={`try:
    print(10 / 0)
except ZeroDivisionError:
    print("Cannot divide by zero")
finally:
    print("This will always execute")`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PythonCheatsheet;
