import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const AnsibleCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Ansible Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Ansible:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt update
sudo apt install ansible

# On macOS:
brew install ansible`} />
                  </li>
                  <li>
                    <strong>Verify Installation:</strong>
                    <ArticleCode code={`ansible --version`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inventory:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Inventory File:</strong>
                    <ArticleCode code={`# inventory.ini
[webservers]
web1 ansible_host=192.168.1.10 ansible_user=ubuntu
web2 ansible_host=192.168.1.11 ansible_user=ubuntu

[dbservers]
db1 ansible_host=192.168.1.20 ansible_user=ubuntu`} />
                  </li>
                  <li>
                    <strong>Ping All Hosts:</strong>
                    <ArticleCode code={`ansible all -i inventory.ini -m ping`} />
                  </li>
                  <li>
                    <strong>Ping Specific Group:</strong>
                    <ArticleCode code={`ansible webservers -i inventory.ini -m ping`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Ad-hoc Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Check Disk Usage:</strong>
                    <ArticleCode code={`ansible all -i inventory.ini -a "df -h"`} />
                  </li>
                  <li>
                    <strong>Check Uptime:</strong>
                    <ArticleCode code={`ansible all -i inventory.ini -a "uptime"`} />
                  </li>
                  <li>
                    <strong>Install Package:</strong>
                    <ArticleCode code={`ansible all -i inventory.ini -b -m apt -a "name=nginx state=present"`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Playbooks:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Playbook:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Install and start nginx
  hosts: webservers
  become: yes
  tasks:
    - name: Install nginx
      apt:
        name: nginx
        state: present

    - name: Start nginx
      service:
        name: nginx
        state: started`} />
                  </li>
                  <li>
                    <strong>Run Playbook:</strong>
                    <ArticleCode code={`ansible-playbook -i inventory.ini playbook.yml`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Roles:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Role:</strong>
                    <ArticleCode code={`ansible-galaxy init myrole`} />
                  </li>
                  <li>
                    <strong>Role Directory Structure:</strong>
                    <ArticleParagraph>
                      <pre>
myrole/
├── files
├── handlers
│   └── main.yml
├── meta
│   └── main.yml
├── tasks
│   └── main.yml
├── templates
├── tests
│   ├── inventory
│   └── test.yml
└── vars
    └── main.yml
                      </pre>
                    </ArticleParagraph>
                  </li>
                  <li>
                    <strong>Use Role in Playbook:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Use myrole
  hosts: all
  roles:
    - myrole`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Variables and Templates:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Variables:</strong>
                    <ArticleCode code={`# vars.yml
nginx_port: 80`} />
                  </li>
                  <li>
                    <strong>Use Variables in Playbook:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Use variables
  hosts: all
  vars_files:
    - vars.yml
  tasks:
    - name: Ensure nginx is running
      service:
        name: nginx
        state: started
        enabled: true`} />
                  </li>
                  <li>
                    <strong>Use Templates:</strong>
                    <ArticleCode code={`# templates/nginx.conf.j2
server {
    listen {{ nginx_port }};
    server_name localhost;
    location / {
        root /var/www/html;
        index index.html index.htm;
    }
}`} />
                  </li>
                  <li>
                    <strong>Deploy Template:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Deploy nginx configuration
  hosts: all
  tasks:
    - name: Deploy nginx config
      template:
        src: templates/nginx.conf.j2
        dest: /etc/nginx/nginx.conf
      notify:
        - Restart nginx`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Handlers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Handler:</strong>
                    <ArticleCode code={`# handlers/main.yml
- name: Restart nginx
  service:
    name: nginx
    state: restarted`} />
                  </li>
                  <li>
                    <strong>Notify Handler:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Deploy nginx configuration
  hosts: all
  tasks:
    - name: Deploy nginx config
      template:
        src: templates/nginx.conf.j2
        dest: /etc/nginx/nginx.conf
      notify:
        - Restart nginx`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Conditionals and Loops:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Conditional Task:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Conditional task example
  hosts: all
  tasks:
    - name: Install nginx only on Ubuntu
      apt:
        name: nginx
        state: present
      when: ansible_os_family == "Debian"`} />
                  </li>
                  <li>
                    <strong>Loop Example:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Loop example
  hosts: all
  tasks:
    - name: Install multiple packages
      apt:
        name: "{{ item }}"
        state: present
      loop:
        - nginx
        - git
        - curl`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Debugging and Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Debug Task:</strong>
                    <ArticleCode code={`# playbook.yml
- name: Debug task example
  hosts: all
  tasks:
    - name: Show variables
      debug:
        msg: "nginx_port is {{ nginx_port }}"`} />
                  </li>
                  <li>
                    <strong>Check Playbook Syntax:</strong>
                    <ArticleCode code={`ansible-playbook playbook.yml --syntax-check`} />
                  </li>
                  <li>
                    <strong>Run Playbook in Check Mode:</strong>
                    <ArticleCode code={`ansible-playbook playbook.yml --check`} />
                  </li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnsibleCheatsheet;
