// cheat-sheets.js

import React from 'react';
import boxdata from '../../data/boxdata';

const CheatSheets = () => {
  const cheatSheetsData = boxdata.find(box => box.title === 'Cheat Sheets');

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">{cheatSheetsData.title}</h1>
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {cheatSheetsData.linkToResources.map((resource, index) => (
            <a
              key={index}
              href={resource.path}
              className="block p-4 bg-white rounded-lg shadow-lg hover:shadow-2xl hover:bg-gray-50 transform hover:-translate-y-1 transition-all duration-300"
            >
              <h2 className="text-xl font-semibold text-gray-700">{resource.title}</h2>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheatSheets;
