import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const OAuthCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                OAuth Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to OAuth:</ArticleSubtitle>
              <ArticleParagraph>
                <p>OAuth is an open-standard authorization protocol or framework that provides applications the ability to secure designated access. OAuth is commonly used as a way to grant websites or applications limited access to a user's information without exposing passwords. This cheat sheet covers essential commands and operations to help you get started with OAuth for secure authorization.</p>
              </ArticleParagraph>

              <ArticleSubtitle>OAuth Flow:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The OAuth 2.0 authorization framework enables a third-party application to obtain limited access to an HTTP service, either on behalf of a resource owner by orchestrating an approval interaction between the resource owner and the HTTP service, or by allowing the third-party application to obtain access on its own behalf.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Authorization Code Grant:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The authorization code grant is used to obtain both access tokens and refresh tokens and is optimized for confidential clients.</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Step 1: Redirect to Authorization Server:</strong>
                    <ArticleCode code={`GET /authorize?
  response_type=code&
  client_id=YOUR_CLIENT_ID&
  redirect_uri=YOUR_REDIRECT_URI&
  scope=YOUR_REQUESTED_SCOPES`} />
                  </li>
                  <li>
                    <strong>Step 2: Handle Redirect and Get Authorization Code:</strong>
                    <ArticleParagraph>
                      <p>After the user authorizes the application, the authorization server redirects the user back to the application with an authorization code:</p>
                      <ArticleCode code={`GET YOUR_REDIRECT_URI?code=AUTHORIZATION_CODE`} />
                    </ArticleParagraph>
                  </li>
                  <li>
                    <strong>Step 3: Exchange Authorization Code for Access Token:</strong>
                    <ArticleCode code={`POST /token
  grant_type=authorization_code&
  code=AUTHORIZATION_CODE&
  redirect_uri=YOUR_REDIRECT_URI&
  client_id=YOUR_CLIENT_ID&
  client_secret=YOUR_CLIENT_SECRET`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Implicit Grant:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The implicit grant is optimized for public clients known to operate a particular redirection URI. It does not support refresh tokens.</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Redirect to Authorization Server:</strong>
                    <ArticleCode code={`GET /authorize?
  response_type=token&
  client_id=YOUR_CLIENT_ID&
  redirect_uri=YOUR_REDIRECT_URI&
  scope=YOUR_REQUESTED_SCOPES`} />
                  </li>
                  <li>
                    <strong>Handle Redirect and Get Access Token:</strong>
                    <ArticleParagraph>
                      <p>The authorization server redirects the user back to the application with an access token:</p>
                      <ArticleCode code={`GET YOUR_REDIRECT_URI#access_token=ACCESS_TOKEN`} />
                    </ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Client Credentials Grant:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The client credentials grant is used when applications request an access token to access their own resources, not on behalf of a user.</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Request Access Token:</strong>
                    <ArticleCode code={`POST /token
  grant_type=client_credentials&
  client_id=YOUR_CLIENT_ID&
  client_secret=YOUR_CLIENT_SECRET`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Refresh Token Grant:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The refresh token grant is used to obtain a new access token when the current access token becomes invalid or expires.</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Request New Access Token:</strong>
                    <ArticleCode code={`POST /token
  grant_type=refresh_token&
  refresh_token=YOUR_REFRESH_TOKEN&
  client_id=YOUR_CLIENT_ID&
  client_secret=YOUR_CLIENT_SECRET`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Security Best Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use HTTPS:</strong> Always use HTTPS to ensure that all communication between the client, server, and authorization server is encrypted.</li>
                  <li><strong>Validate Redirect URIs:</strong> Ensure that redirect URIs are validated and registered to prevent open redirects.</li>
                  <li><strong>Use PKCE:</strong> Use Proof Key for Code Exchange (PKCE) to enhance security, especially for public clients.</li>
                  <li><strong>Short-lived Tokens:</strong> Use short-lived access tokens and refresh tokens to minimize the impact of token leakage.</li>
                  <li><strong>Scopes:</strong> Use scopes to limit the access granted to the client to only the resources it needs.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default OAuthCheatsheet;
