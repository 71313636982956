import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const OwaspTop10Cheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                OWASP Top 10 Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to OWASP Top 10:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The OWASP Top 10 is a standard awareness document for developers and web application security. It represents a broad consensus about the most critical security risks to web applications. This cheat sheet covers essential information about each of the OWASP Top 10 security risks and how to mitigate them.</p>
              </ArticleParagraph>

              <ArticleSubtitle>A1:2021 - Broken Access Control:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Access control enforces policies such that users cannot act outside of their intended permissions.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Bypassing access controls by modifying the URL, internal application state, or the HTML page, or simply using a custom API attack tool.</li>
                  <li><strong>Mitigation:</strong> Implement access control mechanisms consistently throughout the application. Ensure proper validation and handling of permissions.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A2:2021 - Cryptographic Failures:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Cryptographic failures occur when sensitive data is not properly protected.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Exposing data through improper encryption techniques, or using weak keys.</li>
                  <li><strong>Mitigation:</strong> Encrypt sensitive data at rest and in transit using strong encryption algorithms. Manage keys securely.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A3:2021 - Injection:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Injection flaws, such as SQL, NoSQL, OS, and LDAP injection, occur when untrusted data is sent to an interpreter as part of a command or query.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> SQL Injection through unsanitized user input.</li>
                  <li><strong>Mitigation:</strong> Use parameterized queries and prepared statements. Validate and sanitize all user inputs.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A4:2021 - Insecure Design:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Insecure design involves missing or ineffective security controls.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Exploiting weak design patterns that do not adequately protect against threats.</li>
                  <li><strong>Mitigation:</strong> Integrate security design principles into the development lifecycle. Conduct threat modeling and secure design reviews.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A5:2021 - Security Misconfiguration:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Security misconfiguration is the most common issue in web applications and can happen at any level of the application stack.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Exploiting default configurations, incomplete configurations, or open cloud storage.</li>
                  <li><strong>Mitigation:</strong> Establish secure configuration settings for all components and regularly review and update configurations.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A6:2021 - Vulnerable and Outdated Components:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Components with known vulnerabilities may undermine application defenses and enable various attacks.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Exploiting a known vulnerability in an outdated library.</li>
                  <li><strong>Mitigation:</strong> Continuously inventory and manage software components, including libraries and dependencies. Apply patches and updates promptly.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A7:2021 - Identification and Authentication Failures:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Failures in identification and authentication can lead to unauthorized access and information disclosure.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Credential stuffing, where an attacker uses lists of known usernames and passwords.</li>
                  <li><strong>Mitigation:</strong> Implement strong authentication mechanisms, such as multi-factor authentication (MFA), and enforce secure password policies.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A8:2021 - Software and Data Integrity Failures:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Integrity failures occur when software updates, critical data, and CI/CD pipelines are compromised.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> Exploiting software supply chain vulnerabilities.</li>
                  <li><strong>Mitigation:</strong> Implement integrity checks, secure CI/CD pipelines, and use signed updates and dependency management.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A9:2021 - Security Logging and Monitoring Failures:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Insufficient logging and monitoring can prevent timely detection and response to security incidents.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> An attacker exploits a vulnerability and the breach goes undetected due to lack of logging.</li>
                  <li><strong>Mitigation:</strong> Implement comprehensive logging and monitoring, and ensure logs are reviewed regularly. Set up alerting for suspicious activities.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>A10:2021 - Server-Side Request Forgery (SSRF):</ArticleSubtitle>
              <ArticleParagraph>
                <p>SSRF flaws occur when a web application fetches a remote resource without validating the user-supplied URL.</p>
                <ul className="list-disc pl-5">
                  <li><strong>Example Attack:</strong> An attacker manipulates URLs to access internal resources.</li>
                  <li><strong>Mitigation:</strong> Validate and sanitize all user inputs, and restrict outbound network access for applications.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Continuous Learning:</strong> Stay updated with the latest security threats and mitigation techniques.</li>
                  <li><strong>Security in SDLC:</strong> Integrate security practices throughout the Software Development Lifecycle (SDLC).</li>
                  <li><strong>Regular Audits:</strong> Conduct regular security audits and code reviews.</li>
                  <li><strong>Penetration Testing:</strong> Perform penetration testing to identify and fix vulnerabilities.</li>
                  <li><strong>Security Awareness:</strong> Train development and operations teams on security best practices.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwaspTop10Cheatsheet;
