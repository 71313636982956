import React from "react";

function JavaHomePage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-4xl font-bold text-blue-600 mb-8">Welcome to Java Resources</h1>
      <p className="text-lg text-gray-700 mb-8 max-w-xl text-center">
      Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible. It is a general-purpose programming language intended to let application developers write once, run anywhere (WORA), meaning that compiled Java code can run on all platforms that support Java without the need for recompilation. Java is widely used for building enterprise-scale applications, mobile applications, and large systems, making it one of the most popular and versatile programming languages in the world.
      </p>
      <div className="space-y-4">
        <button
          onClick={() => window.location.href = '/cheat-sheets'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Cheat Sheets
        </button>
        <button
          onClick={() => window.location.href = '/tutorialspage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Tutorials
        </button>
        <button
          onClick={() => window.location.href = '/interviewquestions'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Interview Questions
        </button>
        <button
          onClick={() => window.location.href = '/projectshomepage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Projects
        </button>
      </div>
    </div>
  );
}

export default JavaHomePage;
