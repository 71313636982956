import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const MatplotlibCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Matplotlib Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Matplotlib:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Matplotlib is a comprehensive library for creating static, animated, and interactive visualizations in Python. This cheat sheet covers essential commands and operations to help you get started with Matplotlib for data visualization.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Setup and Installation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Matplotlib:</strong>
                    <ArticleCode code={`pip install matplotlib`} />
                  </li>
                  <li>
                    <strong>Import Matplotlib:</strong>
                    <ArticleCode code={`import matplotlib.pyplot as plt`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Plotting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Line Plot:</strong>
                    <ArticleCode code={`import matplotlib.pyplot as plt

x = [1, 2, 3, 4, 5]
y = [2, 3, 5, 7, 11]

plt.plot(x, y)
plt.xlabel('x-axis label')
plt.ylabel('y-axis label')
plt.title('Line Plot')
plt.show()`} />
                  </li>
                  <li>
                    <strong>Scatter Plot:</strong>
                    <ArticleCode code={`import matplotlib.pyplot as plt

x = [1, 2, 3, 4, 5]
y = [2, 3, 5, 7, 11]

plt.scatter(x, y)
plt.xlabel('x-axis label')
plt.ylabel('y-axis label')
plt.title('Scatter Plot')
plt.show()`} />
                  </li>
                  <li>
                    <strong>Bar Plot:</strong>
                    <ArticleCode code={`import matplotlib.pyplot as plt

x = ['A', 'B', 'C', 'D', 'E']
y = [2, 3, 5, 7, 11]

plt.bar(x, y)
plt.xlabel('x-axis label')
plt.ylabel('y-axis label')
plt.title('Bar Plot')
plt.show()`} />
                  </li>
                  <li>
                    <strong>Histogram:</strong>
                    <ArticleCode code={`import matplotlib.pyplot as plt

data = [1, 2, 2, 3, 3, 3, 4, 4, 4, 4]

plt.hist(data, bins=4)
plt.xlabel('x-axis label')
plt.ylabel('y-axis label')
plt.title('Histogram')
plt.show()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Customizing Plots:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Adding Grid:</strong>
                    <ArticleCode code={`plt.grid(True)`} />
                  </li>
                  <li>
                    <strong>Changing Line Style:</strong>
                    <ArticleCode code={`plt.plot(x, y, linestyle='--')`} />
                  </li>
                  <li>
                    <strong>Changing Marker Style:</strong>
                    <ArticleCode code={`plt.plot(x, y, marker='o')`} />
                  </li>
                  <li>
                    <strong>Changing Colors:</strong>
                    <ArticleCode code={`plt.plot(x, y, color='green')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Subplots:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Subplots:</strong>
                    <ArticleCode code={`fig, axs = plt.subplots(2, 2)

axs[0, 0].plot(x, y)
axs[0, 0].set_title('Plot 1')

axs[0, 1].scatter(x, y)
axs[0, 1].set_title('Plot 2')

axs[1, 0].bar(x, y)
axs[1, 0].set_title('Plot 3')

axs[1, 1].hist(data, bins=4)
axs[1, 1].set_title('Plot 4')

plt.tight_layout()
plt.show()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Saving Figures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Save as PNG:</strong>
                    <ArticleCode code={`plt.savefig('plot.png')`} />
                  </li>
                  <li>
                    <strong>Save as PDF:</strong>
                    <ArticleCode code={`plt.savefig('plot.pdf')`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Plotting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Logarithmic Scale:</strong>
                    <ArticleCode code={`plt.plot(x, y)
plt.yscale('log')
plt.show()`} />
                  </li>
                  <li>
                    <strong>Polar Plot:</strong>
                    <ArticleCode code={`import numpy as np

theta = np.linspace(0, 2*np.pi, 100)
r = np.sin(theta)

plt.polar(theta, r)
plt.show()`} />
                  </li>
                  <li>
                    <strong>3D Plot:</strong>
                    <ArticleCode code={`from mpl_toolkits.mplot3d import Axes3D
import numpy as np

fig = plt.figure()
ax = fig.add_subplot(111, projection='3d')

x = np.linspace(-5, 5, 100)
y = np.linspace(-5, 5, 100)
X, Y = np.meshgrid(x, y)
Z = np.sin(np.sqrt(X**2 + Y**2))

ax.plot_surface(X, Y, Z, cmap='viridis')

plt.show()`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Use Axes Methods:</strong> Prefer using axes methods for more control over plot elements.</li>
                  <li><strong>Consistent Style:</strong> Use consistent styles and colors to make plots more readable and professional.</li>
                  <li><strong>Label Everything:</strong> Always label axes and add a title to your plots for clarity.</li>
                  <li><strong>Use Legends:</strong> Add legends to distinguish different data series in your plots.</li>
                  <li><strong>Save Figures:</strong> Save your figures for reproducibility and presentation purposes.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatplotlibCheatsheet;
