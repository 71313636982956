import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const MySQLCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                MySQL Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install MySQL:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update
sudo apt-get install mysql-server

# On macOS:
brew install mysql`} />
                  </li>
                  <li>
                    <strong>Start MySQL Service:</strong>
                    <ArticleCode code={`sudo service mysql start`} />
                  </li>
                  <li>
                    <strong>Connect to MySQL:</strong>
                    <ArticleCode code={`mysql -u root -p`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Database:</strong>
                    <ArticleCode code={`CREATE DATABASE mydatabase;`} />
                  </li>
                  <li>
                    <strong>Connect to Database:</strong>
                    <ArticleCode code={`USE mydatabase;`} />
                  </li>
                  <li>
                    <strong>Create User:</strong>
                    <ArticleCode code={`CREATE USER 'myuser'@'localhost' IDENTIFIED BY 'mypassword';`} />
                  </li>
                  <li>
                    <strong>Grant Privileges:</strong>
                    <ArticleCode code={`GRANT ALL PRIVILEGES ON mydatabase.* TO 'myuser'@'localhost';`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Table Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Table:</strong>
                    <ArticleCode code={`CREATE TABLE users (
  id INT AUTO_INCREMENT PRIMARY KEY,
  name VARCHAR(100),
  email VARCHAR(100) UNIQUE NOT NULL
);`} />
                  </li>
                  <li>
                    <strong>Insert Data:</strong>
                    <ArticleCode code={`INSERT INTO users (name, email) VALUES ('John Doe', 'john.doe@example.com');`} />
                  </li>
                  <li>
                    <strong>Select Data:</strong>
                    <ArticleCode code={`SELECT * FROM users;`} />
                  </li>
                  <li>
                    <strong>Update Data:</strong>
                    <ArticleCode code={`UPDATE users SET email = 'new.email@example.com' WHERE name = 'John Doe';`} />
                  </li>
                  <li>
                    <strong>Delete Data:</strong>
                    <ArticleCode code={`DELETE FROM users WHERE name = 'John Doe';`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Join Tables:</strong>
                    <ArticleCode code={`SELECT users.name, orders.amount
FROM users
JOIN orders ON users.id = orders.user_id;`} />
                  </li>
                  <li>
                    <strong>Aggregate Functions:</strong>
                    <ArticleCode code={`SELECT COUNT(*) FROM users;
SELECT AVG(amount) FROM orders;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Indexes and Constraints:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Index:</strong>
                    <ArticleCode code={`CREATE INDEX idx_user_email ON users(email);`} />
                  </li>
                  <li>
                    <strong>Unique Constraint:</strong>
                    <ArticleCode code={`ALTER TABLE users ADD CONSTRAINT unique_email UNIQUE (email);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Backup and Restore:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Backup Database:</strong>
                    <ArticleCode code={`mysqldump -u root -p mydatabase > mydatabase_backup.sql`} />
                  </li>
                  <li>
                    <strong>Restore Database:</strong>
                    <ArticleCode code={`mysql -u root -p mydatabase < mydatabase_backup.sql`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>User Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Users:</strong>
                    <ArticleCode code={`SELECT user FROM mysql.user;`} />
                  </li>
                  <li>
                    <strong>Change User Password:</strong>
                    <ArticleCode code={`ALTER USER 'myuser'@'localhost' IDENTIFIED BY 'newpassword';`} />
                  </li>
                  <li>
                    <strong>Revoke Privileges:</strong>
                    <ArticleCode code={`REVOKE ALL PRIVILEGES, GRANT OPTION FROM 'myuser'@'localhost';`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>String Functions:</strong>
                    <ArticleCode code={`SELECT CONCAT(first_name, ' ', last_name) FROM users;
SELECT LENGTH(name) FROM users;`} />
                  </li>
                  <li>
                    <strong>Date Functions:</strong>
                    <ArticleCode code={`SELECT NOW();
SELECT DATEDIFF(NOW(), '1990-01-01');`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Performance Tuning:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Analyze Query Performance:</strong>
                    <ArticleCode code={`EXPLAIN SELECT * FROM users;`} />
                  </li>
                  <li>
                    <strong>Optimize Table:</strong>
                    <ArticleCode code={`OPTIMIZE TABLE users;`} />
                  </li>
                  <li>
                    <strong>Set Query Cache Size:</strong>
                    <ArticleCode code={`SET GLOBAL query_cache_size = 16777216;`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default MySQLCheatsheet;
