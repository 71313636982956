import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const SQLiteCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                SQLite Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install SQLite:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get install sqlite3

# On macOS:
brew install sqlite3`} />
                  </li>
                  <li>
                    <strong>Start SQLite CLI:</strong>
                    <ArticleCode code={`sqlite3 mydatabase.db`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Database:</strong>
                    <ArticleCode code={`sqlite3 mydatabase.db`} />
                  </li>
                  <li>
                    <strong>Show Databases:</strong>
                    <ArticleCode code={`.databases`} />
                  </li>
                  <li>
                    <strong>Create Table:</strong>
                    <ArticleCode code={`CREATE TABLE users (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  name TEXT,
  email TEXT UNIQUE NOT NULL
);`} />
                  </li>
                  <li>
                    <strong>Insert Data:</strong>
                    <ArticleCode code={`INSERT INTO users (name, email) VALUES ('John Doe', 'john.doe@example.com');`} />
                  </li>
                  <li>
                    <strong>Select Data:</strong>
                    <ArticleCode code={`SELECT * FROM users;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Table Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Update Data:</strong>
                    <ArticleCode code={`UPDATE users SET email = 'new.email@example.com' WHERE name = 'John Doe';`} />
                  </li>
                  <li>
                    <strong>Delete Data:</strong>
                    <ArticleCode code={`DELETE FROM users WHERE name = 'John Doe';`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Advanced Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Join Tables:</strong>
                    <ArticleCode code={`SELECT users.name, orders.amount
FROM users
JOIN orders ON users.id = orders.user_id;`} />
                  </li>
                  <li>
                    <strong>Aggregate Functions:</strong>
                    <ArticleCode code={`SELECT COUNT(*) FROM users;
SELECT AVG(amount) FROM orders;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Indexes and Constraints:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Index:</strong>
                    <ArticleCode code={`CREATE INDEX idx_user_email ON users(email);`} />
                  </li>
                  <li>
                    <strong>Unique Constraint:</strong>
                    <ArticleCode code={`CREATE UNIQUE INDEX idx_unique_email ON users(email);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Backup and Restore:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Backup Database:</strong>
                    <ArticleCode code={`sqlite3 mydatabase.db ".backup mydatabase_backup.db"`} />
                  </li>
                  <li>
                    <strong>Restore Database:</strong>
                    <ArticleCode code={`sqlite3 mydatabase.db ".restore mydatabase_backup.db"`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>User Management:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>List Users:</strong>
                    <ArticleCode code={`.tables`} />
                  </li>
                  <li>
                    <strong>Change User Password:</strong>
                    <ArticleParagraph>Not applicable in SQLite as it does not support user management</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>String Functions:</strong>
                    <ArticleCode code={`SELECT CONCAT(first_name, ' ', last_name) FROM users;
SELECT LENGTH(name) FROM users;`} />
                  </li>
                  <li>
                    <strong>Date Functions:</strong>
                    <ArticleCode code={`SELECT DATE('now');
SELECT STRFTIME('%Y-%m-%d', 'now');`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Performance Tuning:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Analyze Query Performance:</strong>
                    <ArticleCode code={`EXPLAIN QUERY PLAN SELECT * FROM users;`} />
                  </li>
                  <li>
                    <strong>Vacuum Database:</strong>
                    <ArticleCode code={`VACUUM;`} />
                  </li>
                  <li>
                    <strong>Set Cache Size:</strong>
                    <ArticleCode code={`PRAGMA cache_size = 10000;`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SQLiteCheatsheet;
