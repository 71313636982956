import React from "react";

function SpringbootHomePage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-4xl font-bold text-blue-600 mb-8">Welcome to Spring boot Resources</h1>
      <p className="text-lg text-gray-700 mb-8 max-w-xl text-center">
      Spring Boot is an open-source framework based on the Java programming language that simplifies the creation of stand-alone, production-grade Spring-based applications. It provides a comprehensive infrastructure for developing robust and scalable applications with minimal configuration. By leveraging default configurations, embedded servers, and a wide range of plugins, Spring Boot allows developers to get started quickly and focus on writing business logic rather than boilerplate code. Its opinionated approach to configuration reduces development time and enhances productivity, making it a popular choice for building microservices and modern enterprise applications.      </p>
      <div className="space-y-4">
        <button
          onClick={() => window.location.href = '/cheat-sheets'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Cheat Sheets
        </button>
        <button
          onClick={() => window.location.href = '/tutorialspage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Tutorials
        </button>
        <button
          onClick={() => window.location.href = '/interviewquestions'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Interview Questions
        </button>
        <button
          onClick={() => window.location.href = '/projectshomepage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Projects
        </button>
      </div>
    </div>
  );
}

export default SpringbootHomePage;
