import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const PerlCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Perl Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Hello World:</strong>
                    <ArticleCode code={`#!/usr/bin/perl
print "Hello, World!\\n";`} />
                  </li>
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`$x = 5;
$y = 3.14;
$z = 10;
$s = "Hello";`} />
                  </li>
                  <li>
                    <strong>Comments:</strong>
                    <ArticleCode code={`# This is a single-line comment
=begin comment
This is a
multi-line comment
=end comment`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Types:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Scalar Types:</strong>
                    <ArticleCode code={`$x = 10;       # Integer
$y = 2.5;      # Float
$c = 'A';      # Character
$b = 1;        # Boolean (0 or 1)`} />
                  </li>
                  <li>
                    <strong>Strings:</strong>
                    <ArticleCode code={`$s = "Hello, World!";`} />
                  </li>
                  <li>
                    <strong>Arrays:</strong>
                    <ArticleCode code={`@arr = (1, 2, 3);`} />
                  </li>
                  <li>
                    <strong>Hashes:</strong>
                    <ArticleCode code={`%hash = ("name" => "John", "age" => 30);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Structures:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statements:</strong>
                    <ArticleCode code={`if ($x > 0) {
    print "x is positive\\n";
} elsif ($x == 0) {
    print "x is zero\\n";
} else {
    print "x is negative\\n";
}`} />
                  </li>
                  <li>
                    <strong>For Loops:</strong>
                    <ArticleCode code={`for (my $i = 0; $i < 5; $i++) {
    print "$i\\n";
}`} />
                  </li>
                  <li>
                    <strong>While Loops:</strong>
                    <ArticleCode code={`$x = 5;
while ($x > 0) {
    print "$x\\n";
    $x--;
}`} />
                  </li>
                  <li>
                    <strong>Foreach Loops:</strong>
                    <ArticleCode code={`@arr = (1, 2, 3);
foreach my $value (@arr) {
    print "$value\\n";
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Functions:</strong>
                    <ArticleCode code={`sub myFunction {
    print "Hello from a function\\n";
}`} />
                  </li>
                  <li>
                    <strong>Parameters and Return Values:</strong>
                    <ArticleCode code={`sub add {
    my ($a, $b) = @_;
    return $a + $b;
}

$result = add(2, 3);
print "$result\\n";`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>File I/O:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Reading from a File:</strong>
                    <ArticleCode code={`open(my $fh, '<', 'file.txt') or die "Could not open file: $!";
my $data = do { local $/; <$fh> };
print "$data\\n";`} />
                  </li>
                  <li>
                    <strong>Writing to a File:</strong>
                    <ArticleCode code={`open(my $fh, '>', 'file.txt') or die "Could not open file: $!";
print $fh "Hello, World!\\n";
close $fh;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using eval:</strong>
                    <ArticleCode code={`eval {
    $result = 10 / 0;
};
if ($@) {
    print "Exception caught: $@";
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Regular Expressions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Matching:</strong>
                    <ArticleCode code={`if ($s =~ /World/) {
    print "Match found\\n";
}`} />
                  </li>
                  <li>
                    <strong>Substitution:</strong>
                    <ArticleCode code={`$s =~ s/World/Perl/;
print "$s\\n";`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerlCheatsheet;
