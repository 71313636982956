import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const TDDCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Test-Driven Development (TDD) Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to TDD:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Test-Driven Development (TDD) is a software development process where you write tests before writing the actual code. The process involves writing a test, running it to see it fail, writing the minimum code required to pass the test, and then refactoring the code while ensuring all tests still pass. This cheat sheet covers essential TDD practices and steps to help you implement TDD effectively.</p>
              </ArticleParagraph>

              <ArticleSubtitle>TDD Cycle:</ArticleSubtitle>
              <ArticleParagraph>
                <p>The TDD cycle is often described as the "Red-Green-Refactor" loop:</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Red:</strong> Write a test that defines a function or improvements of a function, and run it to ensure it fails.
                  </li>
                  <li>
                    <strong>Green:</strong> Write the minimal amount of code necessary to pass the test.
                  </li>
                  <li>
                    <strong>Refactor:</strong> Clean up the code while ensuring that all tests still pass.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Writing Tests:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Define Test Cases:</strong> Identify the different scenarios your code should handle and write test cases for each.
                  </li>
                  <li>
                    <strong>Use Assertions:</strong> Use assertions to check the expected outcomes.
                    <ArticleCode code={`assertEqual(actual, expected)`} />
                  </li>
                  <li>
                    <strong>Test-Driven Code:</strong> Write tests before writing the code to implement the functionality.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Example in Python:</ArticleSubtitle>
              <ArticleParagraph>
                <ArticleParagraph>
                  <strong>Step 1: Write a Failing Test:</strong>
                  <ArticleCode code={`import unittest

class TestSum(unittest.TestCase):
    def test_sum(self):
        self.assertEqual(sum([1, 2, 3]), 6)

if __name__ == '__main__':
    unittest.main()`} />
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Step 2: Write Minimum Code to Pass the Test:</strong>
                  <ArticleCode code={`def sum(numbers):
    return 6

# Run the test again to ensure it passes`} />
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Step 3: Refactor the Code:</strong>
                  <ArticleCode code={`def sum(numbers):
    return sum(numbers)

# Ensure all tests still pass after refactoring`} />
                </ArticleParagraph>
              </ArticleParagraph>

              <ArticleSubtitle>Example in JavaScript:</ArticleSubtitle>
              <ArticleParagraph>
                <ArticleParagraph>
                  <strong>Step 1: Write a Failing Test:</strong>
                  <ArticleCode code={`const assert = require('assert');

function sum(numbers) {
  // Implementation goes here
}

try {
  assert.strictEqual(sum([1, 2, 3]), 6);
  console.log('Test passed');
} catch (error) {
  console.log('Test failed');
}`}/>
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Step 2: Write Minimum Code to Pass the Test:</strong>
                  <ArticleCode code={`function sum(numbers) {
  return 6;
}

// Run the test again to ensure it passes`} />
                </ArticleParagraph>
                <ArticleParagraph>
                  <strong>Step 3: Refactor the Code:</strong>
                  <ArticleCode code={`function sum(numbers) {
  return numbers.reduce((a, b) => a + b, 0);
}

// Ensure all tests still pass after refactoring`} />
                </ArticleParagraph>
              </ArticleParagraph>

              <ArticleSubtitle>Benefits of TDD:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Improved Code Quality:</strong> TDD encourages writing cleaner, more modular, and more maintainable code.</li>
                  <li><strong>Fewer Bugs:</strong> By writing tests first, you catch errors early in the development process.</li>
                  <li><strong>Better Documentation:</strong> Tests serve as documentation for the code, making it easier to understand the intended behavior.</li>
                  <li><strong>Refactoring Confidence:</strong> With a comprehensive test suite, you can refactor code with confidence, knowing that any errors will be caught by the tests.</li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common TDD Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Start Small:</strong> Write small, focused tests and build from there.</li>
                  <li><strong>Test Coverage:</strong> Aim for high test coverage, but prioritize quality over quantity.</li>
                  <li><strong>Mocking and Stubbing:</strong> Use mocks and stubs to isolate the code being tested from external dependencies.</li>
                  <li><strong>Continuous Integration:</strong> Integrate tests into your CI/CD pipeline to ensure tests are run frequently.</li>
                  <li><strong>Refactor Often:</strong> Regularly refactor code to improve its structure and maintainability.</li>
                  <li><strong>Review and Refactor Tests:</strong> Regularly review and refactor your tests to keep them clean and maintainable.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default TDDCheatsheet;
