// usePageTracking.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import analytics from "./firebase";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const logPageView = (path) => {
      logEvent(analytics, "page_view", { page_path: path });
    };

    logPageView(location.pathname);
  }, [location]);
};

export default usePageTracking;
