const boxdata = [
  {
    title: 'Cheat Sheets',
    mainPageUrl: 'cheat-sheets',
    linkToResources: [
      { title: 'Java Collections Cheat Sheet', path: 'java/collections/cheatsheet' },
      { title: 'Java Strings & Arrays Cheat Sheet', path: 'java/strings/arrays/cheatsheet' },
      { title: 'Spring Boot Cheat Sheet', path: 'spring/boot/cheatsheet' },
      { title: 'SQL Cheat Sheet', path: 'sql/cheatsheet' },
      { title: 'Docker Cheat Sheet', path: 'docker/cheatsheet' },
      { title: 'Python Cheat Sheet', path: 'python/cheatsheet' },
      { title: 'JavaScript Cheat Sheet', path: 'javascript/cheatsheet' },
      { title: 'Java 8 Cheatsheet', path: 'java8/cheatsheet' },
      { title: 'C++ Cheatsheet', path: 'c++/cheatsheet' },
      { title: 'C# Cheatsheet', path: 'cSharp/cheatsheet' },
      { title: 'Ruby Cheatsheet', path: 'ruby/cheatsheet' },
      { title: 'Go Cheatsheet', path: 'go/cheatsheet' },
      { title: 'Rust Cheatsheet', path: 'rust/cheatsheet' },
      { title: 'Swift Cheatsheet', path: 'swift/cheatsheet' },
      { title: 'PHP Cheatsheet', path: 'php/cheatsheet' },
      { title: 'TypeScript Cheatsheet', path: 'typescript/cheatsheet' },
      { title: 'Kotlin Cheatsheet', path: 'kotlin/cheatsheet' },
      { title: 'Scala Cheatsheet', path: 'scala/cheatsheet' },
      { title: 'Perl Cheatsheet', path: 'perl/cheatsheet' },
      { title: 'R Cheatsheet', path: 'R/cheatsheet' },
      { title: 'Web Development Cheatsheet', path: 'webdevelopment/cheatsheet' },
      { title: 'HTML5 Cheatsheet', path: 'html5/cheatsheet' },
      { title: 'CSS3 Cheatsheet', path: 'css3/cheatsheet' },
      { title: 'Sass/SCSS Cheatsheet', path: 'sassscss/cheatsheet' },
      { title: 'BootStrap Cheatsheet', path: 'bootstrap/cheatsheet' },
      { title: 'Tailwind CSS Cheatsheet', path: 'tailwind/cheatsheet' },
      { title: 'React JS Cheatsheet', path: 'reactjs/cheatsheet' },
      { title: 'Vue JS Cheatsheet', path: 'vuejs/cheatsheet' },
      { title: 'Angular JS Cheatsheet', path: 'angularjs/cheatsheet' },
      { title: 'Svelte JS Cheatsheet', path: 'sveltejs/cheatsheet' },
      { title: 'Node JS Cheatsheet', path: 'nodejs/cheatsheet' },
      { title: 'Express JS Cheatsheet', path: 'expressjs/cheatsheet' },
      { title: 'Next JS Cheatsheet', path: 'nextjs/cheatsheet' },
      { title: 'Jquery Cheatsheet', path: 'jquery/cheatsheet' },
      { title: 'Backend Development Cheatsheet', path: 'backenddevelopment/cheatsheet' },
      { title: 'Django Cheatsheet', path: 'django/cheatsheet' },
      { title: 'Flask Cheatsheet', path: 'flask/cheatsheet' },
      { title: 'Ruby on Rails Cheatsheet', path: 'rubyrails/cheatsheet' },
      { title: 'Laravel Cheatsheet', path: 'laravel/cheatsheet' },
      { title: 'ASP.NET Core Cheatsheet', path: 'asp.net/cheatsheet' },
      { title: 'Phoenix (Elixir) Cheatsheet', path: 'phoenix/cheatsheet' },
      { title: 'GraphQL Cheatsheet', path: 'graphql/cheatsheet' },
      { title: 'PostgreSQL Cheatsheet', path: 'postgresql/cheatsheet' },
      { title: 'MySQL Cheatsheet', path: 'mysql/cheatsheet' },
      { title: 'SQLite Cheatsheet', path: 'sqlite/cheatsheet' },
      { title: 'MongoDb Cheatsheet', path: 'mongodb/cheatsheet' },
      { title: 'Redis Cheatsheet', path: 'redis/cheatsheet' },
      { title: 'Cassandra Cheatsheet', path: 'cassandra/cheatsheet' },
      { title: 'Devops Cheatsheet', path: 'devops/cheatsheet' },
      { title: 'Kubernetes Cheatsheet', path: 'kubernetes/cheatsheet' },
      { title: 'AWS Cheatsheet', path: 'aws/cheatsheet' },
      { title: 'Azure Cheatsheet', path: 'azure/cheatsheet' },
      { title: 'GCP Cheatsheet', path: 'gcp/cheatsheet' },
      { title: 'Terraform Cheatsheet', path: 'terraform/cheatsheet' },
      { title: 'Ansible Cheatsheet', path: 'ansible/cheatsheet' },
      { title: 'Jenkins Cheatsheet', path: 'jenkins/cheatsheet' },
      { title: 'CI/CD Pipelines Cheatsheet', path: 'cicd/cheatsheet' },
      { title: 'Git & GitHub Cheatsheet', path: 'git/cheatsheet' },
      { title: 'Bash/Shell Scripting Cheatsheet', path: 'bash/cheatsheet' },
      { title: 'Linux Command Line Cheatsheet', path: 'linux/cheatsheet' },
      { title: 'React Native Cheatsheet', path: 'reactnative/cheatsheet' },
      { title: 'Pandas Cheatsheet', path: 'pandas/cheatsheet' },
      { title: 'Flutter Cheatsheet', path: 'flutter/cheatsheet' },
      { title: 'Numpy Cheatsheet', path: 'numpy/cheatsheet' },
      { title: 'Matplotlib Cheatsheet', path: 'matplotlib/cheatsheet' },
      { title: 'TensorFlow Cheatsheet', path: 'tensorflow/cheatsheet' },
      { title: 'PyTorch Cheatsheet', path: 'pytorch/cheatsheet' },
      { title: 'Vim Cheatsheet', path: 'vim/cheatsheet' },
      { title: 'HTTP Status Codes Cheatsheet', path: 'httpstatuscodes/cheatsheet' },
      { title: 'WebSocket Cheatsheet', path: 'websocket/cheatsheet' },
      { title: 'OAuth Cheatsheet', path: 'oauth/cheatsheet' },
      { title: 'API Design Cheatsheet', path: 'apidesign/cheatsheet' },
      { title: 'SEO Basics Cheatsheet', path: 'seobasics/cheatsheet' },
      { title: 'Test-Driven Development (TDD) Cheatsheet', path: 'tdd/cheatsheet' },
      { title: 'OWASP Top 10 Cheatsheet', path: 'owasptop10/cheatsheet' },
      { title: 'SSL/TLS Cheatsheet', path: 'ssltls/cheatsheet' },
      { title: 'Encryption Cheatsheet', path: 'encryption/cheatsheet' },
      { title: 'Secure Coding Practices Cheatsheet', path: 'securecodingpractices/cheatsheet' },
    
    ],
  },
  {
    title: 'Tutorials',
    linkToResources: [
      { title: 'Getting Started with Java', thirdPartyPath: 'https://www.javatpoint.com/java-tutorial' },
      { title: 'Spring Boot for Beginners', thirdPartyPath: 'https://www.geeksforgeeks.org/spring-boot/' },
      { title: 'Rest APIs with Spring Boot', thirdPartyPath: 'https://spring.io/guides/tutorials/rest' },
      { title: 'Introduction to Docker', thirdPartyPath: 'https://www.tutorialspoint.com/docker/index.htm' },
    ]
  },
  {
    title: 'Interview Questions',
    linkToResources: [
      { title: '100+ Most Asked Java Questions', path: 'java-interview-questions' },
      { title: '100+ Spring Boot Interview Questions', path: 'spring-boot-interview-questions' },
      { title: 'Most asked SQL Interview Questions', path: 'sql-interview-questions' },
      { title: 'Docker Interview Questions', path: 'docker-interview-questions' },
    ],
  },
  {
    title: 'Quizzes',
    linkToResources: [
      { title: 'Java Basics Quiz', path: 'java-basics-quiz' },
      { title: 'Spring Boot Quiz', path: 'spring-boot-quiz' },
      { title: 'SQL Fundamentals Quiz', path: 'sql-fundamentals-quiz' },
      { title: 'Docker Essentials Quiz', path: 'docker-essentials-quiz' },
    ],
  },
  {
    title: 'Projects',
    linkToResources: [
      { title: 'Simple Blog with Spring Boot', path: 'spring-boot-blog-project' },
      { title: 'CRUD API with Java and Spring Boot', path: 'crud-api-spring-boot-project' },
      { title: 'Inventory Management System', path: 'inventory-management-system-project' },
      { title: 'Dockerized Microservices Application', path: 'docker-microservices-project' },
    ],
  },
  {
    title: 'Cloud/Devops',
    linkToResources: [
      { title: 'Introduction to Cloud', path: 'aws/introduction' },
      { title: 'Getting Started with Kubernetes', path: 'kubernetes-getting-started' },
      { title: 'CI/CD with Jenkins', path: 'cicd-jenkins' },
      { title: 'Monitoring with Prometheus and Grafana', path: 'prometheus-grafana-monitoring' },
    ],
  },
];

export default boxdata;
