import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const HTML5Cheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                HTML5 Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Structure:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>HTML Document:</strong>
                    <ArticleCode code={`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <h1>Hello, World!</h1>
</body>
</html>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Text Formatting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Headings:</strong>
                    <ArticleCode code={`<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>`} />
                  </li>
                  <li>
                    <strong>Paragraphs:</strong>
                    <ArticleCode code={`<p>This is a paragraph.</p>`} />
                  </li>
                  <li>
                    <strong>Bold and Italic:</strong>
                    <ArticleCode code={`<strong>Bold text</strong>
<em>Italic text</em>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Links and Images:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Links:</strong>
                    <ArticleCode code={`<a href="https://www.example.com">This is a link</a>`} />
                  </li>
                  <li>
                    <strong>Images:</strong>
                    <ArticleCode code={`<img src="image.jpg" alt="Description">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Lists:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Unordered List:</strong>
                    <ArticleCode code={`<ul>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
</ul>`} />
                  </li>
                  <li>
                    <strong>Ordered List:</strong>
                    <ArticleCode code={`<ol>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
</ol>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Forms:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Form:</strong>
                    <ArticleCode code={`<form action="/submit" method="post">
    <label for="name">Name:</label>
    <input type="text" id="name" name="name">
    <input type="submit" value="Submit">
</form>`} />
                  </li>
                  <li>
                    <strong>Input Types:</strong>
                    <ArticleCode code={`<input type="text" placeholder="Text input">
<input type="password" placeholder="Password">
<input type="email" placeholder="Email">
<input type="number" placeholder="Number">`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Semantic Elements:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Sectioning Elements:</strong>
                    <ArticleCode code={`<header>Header content</header>
<nav>Navigation links</nav>
<main>Main content</main>
<article>Article content</article>
<aside>Sidebar content</aside>
<footer>Footer content</footer>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Media:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Audio:</strong>
                    <ArticleCode code={`<audio controls>
    <source src="audio.mp3" type="audio/mp3">
    Your browser does not support the audio element.
</audio>`} />
                  </li>
                  <li>
                    <strong>Video:</strong>
                    <ArticleCode code={`<video controls>
    <source src="video.mp4" type="video/mp4">
    Your browser does not support the video element.
</video>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Canvas:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Canvas:</strong>
                    <ArticleCode code={`<canvas id="myCanvas" width="200" height="100"></canvas>`} />
                  </li>
                  <li>
                    <strong>Drawing on Canvas:</strong>
                    <ArticleCode code={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.fillStyle = "#FF0000";
ctx.fillRect(0, 0, 150, 75);
</script>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Local Storage:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Storing Data:</strong>
                    <ArticleCode code={`localStorage.setItem("key", "value");`} />
                  </li>
                  <li>
                    <strong>Retrieving Data:</strong>
                    <ArticleCode code={`var value = localStorage.getItem("key");`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Geolocation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Getting Location:</strong>
                    <ArticleCode code={`navigator.geolocation.getCurrentPosition(function(position) {
    console.log(position.coords.latitude, position.coords.longitude);
});`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default HTML5Cheatsheet;
