import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Set up the PDF worker. This is required for the library to work properly
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = () => {
  return (
    <div>
      <Document
        file={`${process.env.PUBLIC_URL}/java-string.pdf`} // Make sure to replace 'your-pdf-file.pdf' with the actual PDF file name
        onLoadError={console.error}
      >
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};

export default PDFViewer;
