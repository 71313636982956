import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const JenkinsCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Jenkins Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Jenkins:</strong>
                    <ArticleCode code={`# On Ubuntu:
wget -q -O - https://pkg.jenkins.io/debian/jenkins.io.key | sudo apt-key add -
sudo sh -c 'echo deb http://pkg.jenkins.io/debian-stable binary/ > /etc/apt/sources.list.d/jenkins.list'
sudo apt-get update
sudo apt-get install jenkins

# On macOS:
brew install jenkins-lts`} />
                  </li>
                  <li>
                    <strong>Start Jenkins:</strong>
                    <ArticleCode code={`sudo systemctl start jenkins`} />
                  </li>
                  <li>
                    <strong>Enable Jenkins at Boot:</strong>
                    <ArticleCode code={`sudo systemctl enable jenkins`} />
                  </li>
                  <li>
                    <strong>Access Jenkins:</strong>
                    <ArticleParagraph>Open a web browser and go to `http://localhost:8080`</ArticleParagraph>
                  </li>
                  <li>
                    <strong>Unlock Jenkins:</strong>
                    <ArticleParagraph>Get the initial admin password using `sudo cat /var/lib/jenkins/secrets/initialAdminPassword`</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Pipeline Basics:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple Pipeline:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  stages {
    stage('Build') {
      steps {
        echo 'Building...'
      }
    }
    stage('Test') {
      steps {
        echo 'Testing...'
      }
    }
    stage('Deploy') {
      steps {
        echo 'Deploying...'
      }
    }
  }
}`} />
                  </li>
                  <li>
                    <strong>Declarative Pipeline Syntax:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  environment {
    VAR_NAME = 'value'
  }
  stages {
    stage('Example') {
      steps {
        script {
          echo "Environment variable: \${VAR_NAME}"
        }
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Restart Jenkins:</strong>
                    <ArticleCode code={`sudo systemctl restart jenkins`} />
                  </li>
                  <li>
                    <strong>Check Jenkins Status:</strong>
                    <ArticleCode code={`sudo systemctl status jenkins`} />
                  </li>
                  <li>
                    <strong>Stop Jenkins:</strong>
                    <ArticleCode code={`sudo systemctl stop jenkins`} />
                  </li>
                  <li>
                    <strong>Install Plugin:</strong>
                    <ArticleParagraph>Go to `Manage Jenkins -- Manage Plugins -- Available` and search for the plugin to install.</ArticleParagraph>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Scripted Pipeline Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Simple Scripted Pipeline:</strong>
                    <ArticleCode code={`node {
  stage('Build') {
    echo 'Building...'
  }
  stage('Test') {
    echo 'Testing...'
  }
  stage('Deploy') {
    echo 'Deploying...'
  }
}`} />
                  </li>
                  <li>
                    <strong>Using Parallel Stages:</strong>
                    <ArticleCode code={`node {
  stage('Parallel Stages') {
    parallel {
      stage('Unit Tests') {
        steps {
          echo 'Running Unit Tests...'
        }
      }
      stage('Integration Tests') {
        steps {
          echo 'Running Integration Tests...'
        }
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Environment Variables:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Set Environment Variable:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  environment {
    MY_VAR = 'my_value'
  }
  stages {
    stage('Example') {
      steps {
        echo "MY_VAR is \${env.MY_VAR}"
      }
    }
  }
}`} />
                  </li>
                  <li>
                    <strong>Using Credentials:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  environment {
    MY_SECRET = credentials('my-credentials-id')
  }
  stages {
    stage('Example') {
      steps {
        echo "MY_SECRET is \${env.MY_SECRET}"
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Triggers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Build Periodically:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  triggers {
    cron('H */4 * * 1-5')
  }
  stages {
    stage('Build') {
      steps {
        echo 'Building...'
      }
    }
  }
}`} />
                  </li>
                  <li>
                    <strong>Poll SCM:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  triggers {
    pollSCM('H/5 * * * *')
  }
  stages {
    stage('Build') {
      steps {
        echo 'Building...'
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Post Actions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Post Actions Example:</strong>
                    <ArticleCode code={`pipeline {
  agent any
  stages {
    stage('Build') {
      steps {
        echo 'Building...'
      }
    }
  }
  post {
    always {
      echo 'This always runs'
    }
    success {
      echo 'This runs on success'
    }
    failure {
      echo 'This runs on failure'
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Shared Libraries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Load Shared Library:</strong>
                    <ArticleCode code={`@Library('my-shared-library') _
pipeline {
  agent any
  stages {
    stage('Example') {
      steps {
        mySharedLibraryFunction()
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Agents:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Specify Agent:</strong>
                    <ArticleCode code={`pipeline {
  agent {
    label 'my-agent-label'
  }
  stages {
    stage('Build') {
      steps {
        echo 'Building...'
      }
    }
  }
}`} />
                  </li>
                  <li>
                    <strong>Docker Agent:</strong>
                    <ArticleCode code={`pipeline {
  agent {
    docker {
      image 'maven:3-alpine'
      label 'docker'
    }
  }
  stages {
    stage('Build') {
      steps {
        sh 'mvn -B clean install'
      }
    }
  }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default JenkinsCheatsheet;
