import React from "react";
import BoxesContainer from "../../components/mainComponents/BoxesContainer";
import SideLongBox from "../../components/mainComponents/SideLongBox";
import SubHeroSection from "../../components/mainComponents/SubHeroSection";
import data from "../../data/boxdata";
import sideboxdata from "../../data/sideBoxData";

function HomePage() {
  return (
    <>
      <div className="app-container ">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start">
          <div className="md:col-span-6">
            <BoxesContainer data={data} />
          </div>
          <div className="md:col-span-2 p-4">
            {/* The use of flex and h-full ensures that the SideLongBox fills the height similar to the BoxesContainer */}
            <div className="flex flex-col h-full">
              <SideLongBox data={sideboxdata} />
            </div>
          </div>
        </div>
      </div>
      <SubHeroSection />
    </>
  );
}

export default HomePage;
