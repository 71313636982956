import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const VimCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Vim Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Vim:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Vim is a highly configurable text editor built to make text editing more efficient. It is an improved version of the vi editor distributed with most UNIX systems. This cheat sheet covers essential commands and operations to help you get started with Vim.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Modes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Normal Mode:</strong> Press <ArticleCode code={`<Esc>`} /> to enter Normal mode.
                  </li>
                  <li>
                    <strong>Insert Mode:</strong> Press <ArticleCode code={`i`} /> to enter Insert mode.
                  </li>
                  <li>
                    <strong>Visual Mode:</strong> Press <ArticleCode code={`v`} /> to enter Visual mode.
                  </li>
                  <li>
                    <strong>Command-Line Mode:</strong> Press <ArticleCode code={`:`} /> to enter Command-Line mode.
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Save File:</strong> <ArticleCode code={`:w`} />
                  </li>
                  <li>
                    <strong>Save and Quit:</strong> <ArticleCode code={`:wq`} />
                  </li>
                  <li>
                    <strong>Quit without Saving:</strong> <ArticleCode code={`:q!`} />
                  </li>
                  <li>
                    <strong>Undo:</strong> <ArticleCode code={`u`} />
                  </li>
                  <li>
                    <strong>Redo:</strong> <ArticleCode code={`Ctrl + r`} />
                  </li>
                  <li>
                    <strong>Delete a Line:</strong> <ArticleCode code={`dd`} />
                  </li>
                  <li>
                    <strong>Copy a Line:</strong> <ArticleCode code={`yy`} />
                  </li>
                  <li>
                    <strong>Paste:</strong> <ArticleCode code={`p`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Navigation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Move Cursor Up:</strong> <ArticleCode code={`k`} />
                  </li>
                  <li>
                    <strong>Move Cursor Down:</strong> <ArticleCode code={`j`} />
                  </li>
                  <li>
                    <strong>Move Cursor Left:</strong> <ArticleCode code={`h`} />
                  </li>
                  <li>
                    <strong>Move Cursor Right:</strong> <ArticleCode code={`l`} />
                  </li>
                  <li>
                    <strong>Move to Beginning of Line:</strong> <ArticleCode code={`0`} />
                  </li>
                  <li>
                    <strong>Move to End of Line:</strong> <ArticleCode code={`$`} />
                  </li>
                  <li>
                    <strong>Move to Top of File:</strong> <ArticleCode code={`gg`} />
                  </li>
                  <li>
                    <strong>Move to Bottom of File:</strong> <ArticleCode code={`G`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Editing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Insert at Cursor:</strong> <ArticleCode code={`i`} />
                  </li>
                  <li>
                    <strong>Append at End of Line:</strong> <ArticleCode code={`A`} />
                  </li>
                  <li>
                    <strong>Delete Character:</strong> <ArticleCode code={`x`} />
                  </li>
                  <li>
                    <strong>Delete to End of Line:</strong> <ArticleCode code={`D`} />
                  </li>
                  <li>
                    <strong>Change Word:</strong> <ArticleCode code={`cw`} />
                  </li>
                  <li>
                    <strong>Replace Character:</strong> <ArticleCode code={`r`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Searching:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Search Forward:</strong> <ArticleCode code={`/search_term`} />
                  </li>
                  <li>
                    <strong>Search Backward:</strong> <ArticleCode code={`?search_term`} />
                  </li>
                  <li>
                    <strong>Next Search Result:</strong> <ArticleCode code={`n`} />
                  </li>
                  <li>
                    <strong>Previous Search Result:</strong> <ArticleCode code={`N`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Visual Mode:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Select Text:</strong> <ArticleCode code={`v`} />
                  </li>
                  <li>
                    <strong>Select Line:</strong> <ArticleCode code={`V`} />
                  </li>
                  <li>
                    <strong>Select Block:</strong> <ArticleCode code={`Ctrl + v`} />
                  </li>
                  <li>
                    <strong>Copy Selected Text:</strong> <ArticleCode code={`y`} />
                  </li>
                  <li>
                    <strong>Cut Selected Text:</strong> <ArticleCode code={`d`} />
                  </li>
                  <li>
                    <strong>Paste Selected Text:</strong> <ArticleCode code={`p`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Configuration:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Edit Vim Configuration:</strong> <ArticleCode code={`vim ~/.vimrc`} />
                  </li>
                  <li>
                    <strong>Enable Line Numbers:</strong> Add <ArticleCode code={`set number`} /> to <ArticleCode code={`~/.vimrc`} />
                  </li>
                  <li>
                    <strong>Enable Syntax Highlighting:</strong> Add <ArticleCode code={`syntax on`} /> to <ArticleCode code={`~/.vimrc`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Practice Navigation:</strong> Regularly practice navigating using Vim commands to increase your efficiency.</li>
                  <li><strong>Customize Vim:</strong> Customize your <ArticleCode code={`~/.vimrc`} /> file to suit your preferences and workflow.</li>
                  <li><strong>Learn Shortcuts:</strong> Invest time in learning Vim shortcuts to speed up your editing process.</li>
                  <li><strong>Use Plugins:</strong> Explore and use Vim plugins to extend the functionality of your editor.</li>
                  <li><strong>Stay in Normal Mode:</strong> Try to stay in Normal mode as much as possible for efficiency and use commands to switch to other modes when necessary.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default VimCheatsheet;
