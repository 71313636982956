import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const RubyRailsCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Ruby on Rails Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Rails:</strong>
                    <ArticleCode code={`gem install rails`} />
                  </li>
                  <li>
                    <strong>Create a New Rails Project:</strong>
                    <ArticleCode code={`rails new myapp`} />
                  </li>
                  <li>
                    <strong>Run Rails Server:</strong>
                    <ArticleCode code={`cd myapp
rails server`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Models:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Model:</strong>
                    <ArticleCode code={`rails generate model User name:string email:string`} />
                  </li>
                  <li>
                    <strong>Migrate Database:</strong>
                    <ArticleCode code={`rails db:migrate`} />
                  </li>
                  <li>
                    <strong>Model Definition:</strong>
                    <ArticleCode code={`class User < ApplicationRecord
  validates :name, presence: true
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Controllers:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Controller:</strong>
                    <ArticleCode code={`rails generate controller Users`} />
                  </li>
                  <li>
                    <strong>Basic Controller Action:</strong>
                    <ArticleCode code={`class UsersController < ApplicationController
  def index
    @users = User.all
  end

  def show
    @user = User.find(params[:id])
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Views:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic View Template:</strong>
                    <ArticleCode code={`<!-- app/views/users/index.html.erb -->
<h1>All Users</h1>
<ul>
  <% @users.each do |user| %>
    <li><%= link_to user.name, user_path(user) %></li>
  <% end %>
</ul>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Routes:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up Routes:</strong>
                    <ArticleCode code={`# config/routes.rb
Rails.application.routes.draw do
  resources :users
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Forms:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Form Helpers:</strong>
                    <ArticleCode code={`<%= form_with(model: @user, local: true) do |form| %>
  <div class="field">
    <%= form.label :name %>
    <%= form.text_field :name %>
  </div>

  <div class="field">
    <%= form.label :email %>
    <%= form.text_field :email %>
  </div>

  <div class="actions">
    <%= form.submit %>
  </div>
<% end %>`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Validations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Model Validations:</strong>
                    <ArticleCode code={`class User < ApplicationRecord
  validates :name, presence: true
  validates :email, presence: true, uniqueness: true
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Associations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining Associations:</strong>
                    <ArticleCode code={`class Post < ApplicationRecord
  belongs_to :user
end

class User < ApplicationRecord
  has_many :posts
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Database Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Basic Queries:</strong>
                    <ArticleCode code={`@users = User.all
@user = User.find(params[:id])
@user = User.find_by(email: 'example@example.com')
@users = User.where(active: true)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>RESTful API:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating a JSON Response:</strong>
                    <ArticleCode code={`class UsersController < ApplicationController
  def index
    @users = User.all
    render json: @users
  end

  def show
    @user = User.find(params[:id])
    render json: @user
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Setting Up Devise:</strong>
                    <ArticleCode code={`gem 'devise'
bundle install
rails generate devise:install
rails generate devise User
rails db:migrate`} />
                  </li>
                  <li>
                    <strong>Configuring Devise:</strong>
                    <ArticleCode code={`# config/routes.rb
Rails.application.routes.draw do
  devise_for :users
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Testing:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using RSpec:</strong>
                    <ArticleCode code={`gem 'rspec-rails', '~> 5.0.0'
bundle install
rails generate rspec:install`} />
                  </li>
                  <li>
                    <strong>Basic Test Example:</strong>
                    <ArticleCode code={`# spec/models/user_spec.rb
require 'rails_helper'

RSpec.describe User, type: :model do
  it "is valid with valid attributes" do
    user = User.new(name: "John", email: "john@example.com")
    expect(user).to be_valid
  end

  it "is not valid without a name" do
    user = User.new(name: nil)
    expect(user).to_not be_valid
  end
end`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Background Jobs:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Active Job:</strong>
                    <ArticleCode code={`class MyJob < ApplicationJob
  queue_as :default

  def perform(*args)
    # Do something later
  end
end`} />
                  </li>
                  <li>
                    <strong>Enqueueing a Job:</strong>
                    <ArticleCode code={`MyJob.perform_later(args)`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Using Capistrano:</strong>
                    <ArticleCode code={`gem 'capistrano', '~> 3.14'
gem 'capistrano-rails', '~> 1.6'
gem 'capistrano-passenger', '~> 0.2.0'
bundle install
cap install`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default RubyRailsCheatsheet;
