import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const KubernetesCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Kubernetes Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Getting Started:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install kubectl:</strong>
                    <ArticleCode code={`# On Ubuntu:
sudo apt-get update
sudo apt-get install -y apt-transport-https gnupg2 curl
curl -s https://packages.cloud.google.com/apt/doc/apt-key.gpg | sudo apt-key add -
echo "deb https://apt.kubernetes.io/ kubernetes-xenial main" | sudo tee -a /etc/apt/sources.list.d/kubernetes.list
sudo apt-get update
sudo apt-get install -y kubectl

# On macOS:
brew install kubectl`} />
                  </li>
                  <li>
                    <strong>Install Minikube:</strong>
                    <ArticleCode code={`# On Ubuntu:
curl -Lo minikube https://storage.googleapis.com/minikube/releases/latest/minikube-linux-amd64
chmod +x minikube
sudo mv minikube /usr/local/bin/

# On macOS:
brew install minikube`} />
                  </li>
                  <li>
                    <strong>Start Minikube:</strong>
                    <ArticleCode code={`minikube start`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Basic kubectl Commands:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Get Cluster Info:</strong>
                    <ArticleCode code={`kubectl cluster-info`} />
                  </li>
                  <li>
                    <strong>Get Nodes:</strong>
                    <ArticleCode code={`kubectl get nodes`} />
                  </li>
                  <li>
                    <strong>Get Pods:</strong>
                    <ArticleCode code={`kubectl get pods`} />
                  </li>
                  <li>
                    <strong>Get Services:</strong>
                    <ArticleCode code={`kubectl get services`} />
                  </li>
                  <li>
                    <strong>Get Deployments:</strong>
                    <ArticleCode code={`kubectl get deployments`} />
                  </li>
                  <li>
                    <strong>Get Namespaces:</strong>
                    <ArticleCode code={`kubectl get namespaces`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Pod Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Pod:</strong>
                    <ArticleCode code={`kubectl run mypod --image=nginx`} />
                  </li>
                  <li>
                    <strong>Describe Pod:</strong>
                    <ArticleCode code={`kubectl describe pod mypod`} />
                  </li>
                  <li>
                    <strong>Delete Pod:</strong>
                    <ArticleCode code={`kubectl delete pod mypod`} />
                  </li>
                  <li>
                    <strong>Logs from Pod:</strong>
                    <ArticleCode code={`kubectl logs mypod`} />
                  </li>
                  <li>
                    <strong>Exec Command in Pod:</strong>
                    <ArticleCode code={`kubectl exec -it mypod -- /bin/bash`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Service Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Service:</strong>
                    <ArticleCode code={`kubectl expose pod mypod --port=80 --target-port=80`} />
                  </li>
                  <li>
                    <strong>Describe Service:</strong>
                    <ArticleCode code={`kubectl describe service mypod`} />
                  </li>
                  <li>
                    <strong>Delete Service:</strong>
                    <ArticleCode code={`kubectl delete service mypod`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Deployment Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Deployment:</strong>
                    <ArticleCode code={`kubectl create deployment mydeployment --image=nginx`} />
                  </li>
                  <li>
                    <strong>Scale Deployment:</strong>
                    <ArticleCode code={`kubectl scale deployment mydeployment --replicas=3`} />
                  </li>
                  <li>
                    <strong>Update Deployment:</strong>
                    <ArticleCode code={`kubectl set image deployment/mydeployment nginx=nginx:1.16.1`} />
                  </li>
                  <li>
                    <strong>Rollback Deployment:</strong>
                    <ArticleCode code={`kubectl rollout undo deployment/mydeployment`} />
                  </li>
                  <li>
                    <strong>Describe Deployment:</strong>
                    <ArticleCode code={`kubectl describe deployment mydeployment`} />
                  </li>
                  <li>
                    <strong>Delete Deployment:</strong>
                    <ArticleCode code={`kubectl delete deployment mydeployment`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>ConfigMaps and Secrets:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create ConfigMap:</strong>
                    <ArticleCode code={`kubectl create configmap myconfigmap --from-literal=key1=value1`} />
                  </li>
                  <li>
                    <strong>Get ConfigMap:</strong>
                    <ArticleCode code={`kubectl get configmaps`} />
                  </li>
                  <li>
                    <strong>Create Secret:</strong>
                    <ArticleCode code={`kubectl create secret generic mysecret --from-literal=username=admin --from-literal=password=secret`} />
                  </li>
                  <li>
                    <strong>Get Secrets:</strong>
                    <ArticleCode code={`kubectl get secrets`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Namespaces:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Namespace:</strong>
                    <ArticleCode code={`kubectl create namespace mynamespace`} />
                  </li>
                  <li>
                    <strong>Get Namespaces:</strong>
                    <ArticleCode code={`kubectl get namespaces`} />
                  </li>
                  <li>
                    <strong>Delete Namespace:</strong>
                    <ArticleCode code={`kubectl delete namespace mynamespace`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Persistent Volumes and Claims:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create Persistent Volume:</strong>
                    <ArticleCode code={`# persistent-volume.yaml
apiVersion: v1
kind: PersistentVolume
metadata:
  name: mypv
spec:
  capacity:
    storage: 1Gi
  accessModes:
    - ReadWriteOnce
  hostPath:
    path: /mnt/data`} />
                  </li>
                  <li>
                    <strong>Create Persistent Volume Claim:</strong>
                    <ArticleCode code={`# persistent-volume-claim.yaml
apiVersion: v1
kind: PersistentVolumeClaim
metadata:
  name: mypvc
spec:
  accessModes:
    - ReadWriteOnce
  resources:
    requests:
      storage: 1Gi`} />
                  </li>
                  <li>
                    <strong>Apply Configurations:</strong>
                    <ArticleCode code={`kubectl apply -f persistent-volume.yaml
kubectl apply -f persistent-volume-claim.yaml`} />
                  </li>
                  <li>
                    <strong>Get Persistent Volumes:</strong>
                    <ArticleCode code={`kubectl get pv`} />
                  </li>
                  <li>
                    <strong>Get Persistent Volume Claims:</strong>
                    <ArticleCode code={`kubectl get pvc`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Helm:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Helm:</strong>
                    <ArticleCode code={`# On macOS:
brew install helm

# On Ubuntu:
curl https://raw.githubusercontent.com/helm/helm/main/scripts/get-helm-3 | bash`} />
                  </li>
                  <li>
                    <strong>Add Repository:</strong>
                    <ArticleCode code={`helm repo add stable https://charts.helm.sh/stable`} />
                  </li>
                  <li>
                    <strong>Update Repositories:</strong>
                    <ArticleCode code={`helm repo update`} />
                  </li>
                  <li>
                    <strong>Install Chart:</strong>
                    <ArticleCode code={`helm install myrelease stable/nginx`} />
                  </li>
                  <li>
                    <strong>List Releases:</strong>
                    <ArticleCode code={`helm list`} />
                  </li>
                  <li>
                    <strong>Uninstall Release:</strong>
                    <ArticleCode code={`helm uninstall myrelease`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Monitoring and Logging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Install Prometheus and Grafana:</strong>
                    <ArticleCode code={`helm repo add prometheus-community https://prometheus-community.github.io/helm-charts
helm repo add grafana https://grafana.github.io/helm-charts
helm repo update
helm install prometheus prometheus-community/kube-prometheus-stack
helm install grafana grafana/grafana`} />
                  </li>
                  <li>
                    <strong>Access Grafana:</strong>
                    <ArticleCode code={`kubectl port-forward svc/grafana 3000:80`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Security:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Network Policies:</strong>
                    <ArticleCode code={`# network-policy.yaml
apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
metadata:
  name: allow-nginx
spec:
  podSelector:
    matchLabels:
      app: nginx
  policyTypes:
  - Ingress
  ingress:
  - from:
    - podSelector:
        matchLabels:
          access: true`} />
                  </li>
                  <li>
                    <strong>Apply Network Policy:</strong>
                    <ArticleCode code={`kubectl apply -f network-policy.yaml`} />
                  </li>
                  <li>
                    <strong>Get Network Policies:</strong>
                    <ArticleCode code={`kubectl get networkpolicies`} />
                  </li>
                </ul>
              </ArticleParagraph>

            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default KubernetesCheatsheet;
