import React from 'react';

const NavSection = ({ title, items }) => (
  <div className="flex flex-col space-y-2">
    <h2 className="font-semibold text-gray-900 uppercase text-lg">{title}</h2>
    <nav className="list-none">
      {items.map((item, index) => (
        <li key={index}>
          <a href={item.link} className="text-gray-600 hover:text-blue-500 transition-colors">{item.text}</a>
        </li>
      ))}
    </nav>
  </div>
);

const ContactDetail = ({ label, value }) => (
  <div className="flex flex-col">
    <label className="text-gray-700 text-sm font-semibold">{label}</label>
    <p className="text-gray-600">{value}</p>
  </div>
);

const Footer = () => (
  <footer className="bg-gray-50 text-gray-600 body-font">
    <div className="container px-5 py-6 mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
      <div className="md:col-span-1 flex items-center">
        <div className="flex items-center justify-center md:justify-start text-gray-900 space-x-3">
          <img src="/favicon.ico" alt="Interview Preparation" className="w-14 h-14 rounded-full shadow-sm" />
          <span className="text-xl font-medium">Java Spring</span>
        </div>
        <p className="mt-2 ml-2 text-sm text-gray-500">A place for learning</p>
      </div>
      <div className="md:col-span-3 grid grid-cols-1 md:grid-cols-3 gap-6">
        <NavSection 
          title="Sections" 
          items={[
            { text: 'Java', link: '/java-home' }, 
            { text: 'Spring Boot', link: '/spring-boot-home' }, 
            { text: 'SQL', link: '/sql-home' }, 
            { text: 'Microservices', link: '/microservices-home' }
          ]} 
        />
        <NavSection 
          title="About Us" 
          items={[
            { text: 'Editors', link: '/about/editors' }, 
            { text: 'Contributors', link: '/about/contributors' }, 
            { text: 'Developers', link: '/about/developers' },
            { text: 'Prvacy Notice', link: '/privacy-notice' }
          ]} 
        />
        <div className="space-y-2">
          <h2 className="font-semibold text-gray-900 uppercase text-lg">Contact Us</h2>
          <ContactDetail label="Email" value="info@javaspring.com" />
          <ContactDetail label="GitHub" value="github.com/javaspring" />
          <ContactDetail label="YouTube" value="youtube.com/java--spring" />
        </div>
      </div>
    </div>
    <div className="bg-gray-100">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-500 text-sm text-center sm:text-left">© 2024 JavaSpring</p>
        <span className="inline-flex space-x-2 mt-2 sm:mt-0">
          {/* Social icons can be added here */}
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;
