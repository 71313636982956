import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const SQLCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                SQL Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Creating and Managing Tables:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Create a Table:</strong>
                    <ArticleCode code={`CREATE TABLE table_name (
  column1 datatype PRIMARY KEY,
  column2 datatype,
  column3 datatype
);`} />
                  </li>
                  <li>
                    <strong>Drop a Table:</strong>
                    <ArticleCode code={`DROP TABLE table_name;`} />
                  </li>
                  <li>
                    <strong>Alter a Table:</strong>
                    <ArticleCode code={`ALTER TABLE table_name ADD column_name datatype;
ALTER TABLE table_name DROP COLUMN column_name;
ALTER TABLE table_name MODIFY COLUMN column_name datatype;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Inserting, Updating, and Deleting Data:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Insert Data:</strong>
                    <ArticleCode code={`INSERT INTO table_name (column1, column2, column3)
VALUES (value1, value2, value3);`} />
                  </li>
                  <li>
                    <strong>Update Data:</strong>
                    <ArticleCode code={`UPDATE table_name
SET column1 = value1, column2 = value2
WHERE condition;`} />
                  </li>
                  <li>
                    <strong>Delete Data:</strong>
                    <ArticleCode code={`DELETE FROM table_name
WHERE condition;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Select Queries:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Select All:</strong>
                    <ArticleCode code={`SELECT * FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Select Specific Columns:</strong>
                    <ArticleCode code={`SELECT column1, column2 FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Where Clause:</strong>
                    <ArticleCode code={`SELECT * FROM table_name WHERE condition;`} />
                  </li>
                  <li>
                    <strong>Order By:</strong>
                    <ArticleCode code={`SELECT * FROM table_name ORDER BY column_name ASC|DESC;`} />
                  </li>
                  <li>
                    <strong>Group By:</strong>
                    <ArticleCode code={`SELECT column_name, COUNT(*) FROM table_name GROUP BY column_name;`} />
                  </li>
                  <li>
                    <strong>Having Clause:</strong>
                    <ArticleCode code={`SELECT column_name, COUNT(*) FROM table_name GROUP BY column_name HAVING condition;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Joins:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Inner Join:</strong>
                    <ArticleCode code={`SELECT columns
FROM table1
INNER JOIN table2
ON table1.column = table2.column;`} />
                  </li>
                  <li>
                    <strong>Left Join:</strong>
                    <ArticleCode code={`SELECT columns
FROM table1
LEFT JOIN table2
ON table1.column = table2.column;`} />
                  </li>
                  <li>
                    <strong>Right Join:</strong>
                    <ArticleCode code={`SELECT columns
FROM table1
RIGHT JOIN table2
ON table1.column = table2.column;`} />
                  </li>
                  <li>
                    <strong>Full Join:</strong>
                    <ArticleCode code={`SELECT columns
FROM table1
FULL JOIN table2
ON table1.column = table2.column;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Aggregate Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Count:</strong>
                    <ArticleCode code={`SELECT COUNT(*) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Sum:</strong>
                    <ArticleCode code={`SELECT SUM(column_name) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Average:</strong>
                    <ArticleCode code={`SELECT AVG(column_name) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Max:</strong>
                    <ArticleCode code={`SELECT MAX(column_name) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Min:</strong>
                    <ArticleCode code={`SELECT MIN(column_name) FROM table_name;`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>String Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Concatenate:</strong>
                    <ArticleCode code={`SELECT CONCAT(column1, column2) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Substring:</strong>
                    <ArticleCode code={`SELECT SUBSTRING(column_name, start, length) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Length:</strong>
                    <ArticleCode code={`SELECT LENGTH(column_name) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Upper:</strong>
                    <ArticleCode code={`SELECT UPPER(column_name) FROM table_name;`} />
                  </li>
                  <li>
                    <strong>Lower:</strong>
                    <ArticleCode code={`SELECT LOWER(column_name) FROM table_name;`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SQLCheatsheet;
