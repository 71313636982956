import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const SecureCodingPracticesCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Secure Coding Practices Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Introduction to Secure Coding:</ArticleSubtitle>
              <ArticleParagraph>
                <p>Secure coding practices help prevent vulnerabilities and protect applications from various attacks. This cheat sheet covers essential secure coding principles and practices to help you write secure code.</p>
              </ArticleParagraph>

              <ArticleSubtitle>Input Validation:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Validate All Inputs:</strong> Ensure that all inputs are validated before processing.
                    <ArticleCode code={`if (!isValid(input)) {
  throw new Error('Invalid input');
}`} />
                  </li>
                  <li>
                    <strong>Use Whitelisting:</strong> Prefer whitelisting over blacklisting for input validation.
                    <ArticleCode code={`const allowedValues = ['value1', 'value2'];
if (!allowedValues.includes(input)) {
  throw new Error('Invalid input');
}`} />
                  </li>
                  <li>
                    <strong>Limit Input Length:</strong> Restrict the length of input to prevent buffer overflow attacks.
                    <ArticleCode code={`if (input.length > MAX_LENGTH) {
  throw new Error('Input too long');
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Output Encoding:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Encode Output:</strong> Encode data before rendering it to prevent injection attacks.
                    <ArticleCode code={`const encodedOutput = encodeHTML(userInput);`} />
                  </li>
                  <li>
                    <strong>Use Appropriate Encoding:</strong> Use the right encoding method for the context (HTML, JavaScript, URL, etc.).
                    <ArticleCode code={`const encodedURL = encodeURIComponent(userInput);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Authentication and Authorization:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Implement Strong Authentication:</strong> Use strong authentication mechanisms like multi-factor authentication (MFA).
                    <ArticleCode code={`const isAuthenticated = authenticateUser(username, password);`} />
                  </li>
                  <li>
                    <strong>Use Role-Based Access Control (RBAC):</strong> Implement RBAC to enforce access controls.
                    <ArticleCode code={`const hasAccess = checkUserRole(user, 'admin');`} />
                  </li>
                  <li>
                    <strong>Session Management:</strong> Securely manage user sessions and use secure cookies.
                    <ArticleCode code={`res.cookie('sessionId', sessionId, { secure: true, httpOnly: true });`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Data Protection:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Encrypt Sensitive Data:</strong> Use encryption to protect sensitive data both at rest and in transit.
                    <ArticleCode code={`const encryptedData = encrypt(data, key);`} />
                  </li>
                  <li>
                    <strong>Hash Passwords:</strong> Always hash passwords before storing them.
                    <ArticleCode code={`const hashedPassword = hash(password, salt);`} />
                  </li>
                  <li>
                    <strong>Use Secure Algorithms:</strong> Use strong cryptographic algorithms and avoid deprecated ones.
                    <ArticleCode code={`const cipher = crypto.createCipher('aes-256-cbc', key);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Error Handling and Logging:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Avoid Revealing Information:</strong> Do not reveal sensitive information in error messages.
                    <ArticleCode code={`if (error) {
  console.error('An error occurred');
}`} />
                  </li>
                  <li>
                    <strong>Use Centralized Logging:</strong> Implement centralized logging for better monitoring and incident response.
                    <ArticleCode code={`const logger = createLogger();
logger.error('Error message');`} />
                  </li>
                  <li>
                    <strong>Sanitize Log Data:</strong> Ensure that log data is sanitized to prevent log injection attacks.
                    <ArticleCode code={`logger.info('User input: ', sanitize(input));`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Secure Communication:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Use HTTPS:</strong> Always use HTTPS to encrypt data in transit.
                    <ArticleCode code={`const server = https.createServer(options, app);`} />
                  </li>
                  <li>
                    <strong>Enable HSTS:</strong> Use HTTP Strict Transport Security (HSTS) to enforce HTTPS.
                    <ArticleCode code={`res.setHeader('Strict-Transport-Security', 'max-age=31536000; includeSubDomains');`} />
                  </li>
                  <li>
                    <strong>Validate SSL Certificates:</strong> Ensure SSL certificates are valid and not expired.
                    <ArticleCode code={`const isValidCertificate = validateSSLCertificate(certificate);`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Secure Coding Practices:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Least Privilege Principle:</strong> Grant only the necessary permissions required for a task.</li>
                  <li><strong>Secure Defaults:</strong> Use secure default settings and configurations.</li>
                  <li><strong>Regular Updates:</strong> Keep software and dependencies up-to-date to mitigate known vulnerabilities.</li>
                  <li><strong>Code Reviews:</strong> Conduct regular code reviews to identify and fix security issues.</li>
                  <li><strong>Security Training:</strong> Provide ongoing security training to developers and staff.</li>
                  <li><strong>Threat Modeling:</strong> Perform threat modeling to identify potential security threats and vulnerabilities.</li>
                </ul>
              </ArticleParagraph>
              
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecureCodingPracticesCheatsheet;
