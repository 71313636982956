import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";

const SassCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>
              <div>
                {/* <PDFViewer /> */}
              </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Sass/SCSS Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Basic Syntax:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Variables:</strong>
                    <ArticleCode code={`$primary-color: #333;
$font-stack: Helvetica, sans-serif;

body {
    font: 100% $font-stack;
    color: $primary-color;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Nesting:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Nesting Selectors:</strong>
                    <ArticleCode code={`nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li { display: inline-block; }

    a {
        display: block;
        padding: 6px 12px;
        text-decoration: none;
    }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Partials and Import:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Partials and Import:</strong>
                    <ArticleCode code={`/* _reset.scss */
html, body, ul, ol { margin: 0; padding: 0; }

/* main.scss */
@import 'reset';

body {
    font: 100% Helvetica, sans-serif;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Mixins:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining and Using Mixins:</strong>
                    <ArticleCode code={`@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

.box { @include border-radius(10px); }`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Extend/Inheritance:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Extending a Class:</strong>
                    <ArticleCode code={`%message-shared {
    border: 1px solid #ccc;
    padding: 10px;
    color: #333;
}

.message {
    @extend %message-shared;
}

.success { @extend %message-shared; border-color: green; }

.error { @extend %message-shared; border-color: red; }

.warning { @extend %message-shared; border-color: yellow; }`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Functions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Defining and Using Functions:</strong>
                    <ArticleCode code={`@function calculate-margin($size) {
    @return $size * 2;
}

.container {
    margin: calculate-margin(10px);
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Control Directives & Expressions:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>If Statement:</strong>
                    <ArticleCode code={`@if lightness($color) > 50% {
    background-color: #000;
} @else {
    background-color: #fff;
}`} />
                  </li>
                  <li>
                    <strong>For Loop:</strong>
                    <ArticleCode code={`@for $i from 1 through 3 {
    .item-#{$i} { width: 2em * $i; }
}`} />
                  </li>
                  <li>
                    <strong>Each Loop:</strong>
                    <ArticleCode code={`@each $animal in puma, sea-slug, egret, salamander {
    .#{$animal}-icon {
        background-image: url('/images/#{$animal}.png');
    }
}`} />
                  </li>
                  <li>
                    <strong>While Loop:</strong>
                    <ArticleCode code={`$i: 6;
@while $i > 0 {
    .item-#{$i} { width: 2em * $i; }
    $i: $i - 1;
}`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Importing CSS Files:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Importing CSS Files:</strong>
                    <ArticleCode code={`@import 'style1.css';
@import 'style2.css';`} />
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Operators:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Arithmetic Operators:</strong>
                    <ArticleCode code={`.container {
    width: 100% / 3;
    height: 100px + 50px;
}`} />
                  </li>
                  <li>
                    <strong>Comparison Operators:</strong>
                    <ArticleCode code={`@if 5 > 3 {
    .box { border: 1px solid #000; }
}`} />
                  </li>
                  <li>
                    <strong>Boolean Operators:</strong>
                    <ArticleCode code={`@if true and false {
    .box { display: none; }
}`} />
                  </li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SassCheatsheet;
