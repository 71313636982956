import React from "react";
import ArticleTitle from "../../components/articleComponents/ArticleTitle";
import ArticleSubtitle from "../../components/articleComponents/ArticleSubtitle";
import ArticleParagraph from "../../components/articleComponents/ArticleParagraph";
import ArticleCode from "../../components/articleComponents/ArticleCode";
import Sidebar from "../../components/articleComponents/Sidebar";
import ArticleList from "../../components/articleComponents/ArticleList";
import awsListData from "../../data/awsListData";
import { Document, Page } from 'react-pdf';
import PDFViewer from "../../components/articleComponents/PDFViewer";

const JavaStringsArraysCheatsheet = () => {
  return (
    <>
      <div className="app-container">
        <div className="grid grid-cols-1 md:grid-cols-8 w-full gap-4 items-start py-4">
          <div className="md:col-span-2 p-1 mx-auto">
            <Sidebar>{/* <ArticleList topics={awsListData} /> */}
            <div>
      {/* <PDFViewer /> */}
    </div>
            </Sidebar>
          </div>
          <div className="md:col-span-4 p-1">
            <div className="bg-white rounded-lg shadow-md p-6">
              <ArticleTitle>
                Java Strings and Arrays Cheat Sheet
              </ArticleTitle>

              <ArticleSubtitle>Java Strings:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Strings:</strong> <code>String str = "Hello";</code>, <code>String str = new String("Hello");</code>
                  </li>
                  <li>
                    <strong>Concatenation:</strong> <code>str1 + str2</code>, <code>str1.concat(str2)</code>
                  </li>
                  <li>
                    <strong>Length:</strong> <code>str.length()</code>
                  </li>
                  <li>
                    <strong>Substring:</strong> <code>str.substring(startIndex)</code>, <code>str.substring(startIndex, endIndex)</code>
                  </li>
                  <li>
                    <strong>Character Extraction:</strong> <code>str.charAt(index)</code>
                  </li>
                  <li>
                    <strong>String Comparison:</strong> <code>str1.equals(str2)</code>, <code>str1.compareTo(str2)</code>
                  </li>
                  <li>
                    <strong>String Conversion:</strong> <code>String.valueOf(data)</code>, <code>str.toUpperCase()</code>, <code>str.toLowerCase()</code>
                  </li>
                  <li>
                    <strong>Trim:</strong> <code>str.trim()</code>
                  </li>
                  <li>
                    <strong>Replace:</strong> <code>str.replace(oldChar, newChar)</code>
                  </li>
                  <li>
                    <strong>Split:</strong> <code>str.split(delimiter)</code>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Java Arrays:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Creating Arrays:</strong> <code>int[] arr = new int[10];</code>, <code>int[] arr = [{1}, {2}, {3}];</code>
                  </li>
                  <li>
                    <strong>Accessing Elements:</strong> <code>arr[index]</code>
                  </li>
                  <li>
                    <strong>Array Length:</strong> <code>arr.length</code>
                  </li>
                  <li>
                    <strong>Iterating Arrays:</strong> 
                    <ul className="list-disc pl-5">
                      <li><code>for (int i = 0; i &lt; arr.length; i++) { }</code></li>
                      <li><code>for (int value : arr) { }</code></li>
                    </ul>
                  </li>
                  <li>
                    <strong>Array Copying:</strong> <code>System.arraycopy(src, srcPos, dest, destPos, length)</code>
                  </li>
                  <li>
                    <strong>Sorting Arrays:</strong> <code>Arrays.sort(arr)</code>
                  </li>
                  <li>
                    <strong>Filling Arrays:</strong> <code>Arrays.fill(arr, value)</code>
                  </li>
                  <li>
                    <strong>Comparing Arrays:</strong> <code>Arrays.equals(arr1, arr2)</code>
                  </li>
                  <li>
                    <strong>Searching Arrays:</strong> <code>Arrays.binarySearch(arr, key)</code>
                  </li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common String Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Concatenation:</strong> <code>str1 + str2</code></li>
                  <li><strong>Length:</strong> <code>str.length()</code></li>
                  <li><strong>Substring:</strong> <code>str.substring(start, end)</code></li>
                  <li><strong>Character Extraction:</strong> <code>str.charAt(index)</code></li>
                  <li><strong>String Comparison:</strong> <code>str1.equals(str2)</code></li>
                  <li><strong>String Conversion:</strong> <code>String.valueOf(data)</code></li>
                </ul>
              </ArticleParagraph>

              <ArticleSubtitle>Common Array Operations:</ArticleSubtitle>
              <ArticleParagraph>
                <ul className="list-disc pl-5">
                  <li><strong>Array Copy:</strong> <code>System.arraycopy(src, srcPos, dest, destPos, length)</code></li>
                  <li><strong>Array Sort:</strong> <code>Arrays.sort(arr)</code></li>
                  <li><strong>Array Fill:</strong> <code>Arrays.fill(arr, value)</code></li>
                  <li><strong>Array Equals:</strong> <code>Arrays.equals(arr1, arr2)</code></li>
                  <li><strong>Array Search:</strong> <code>Arrays.binarySearch(arr, key)</code></li>
                </ul>
              </ArticleParagraph>
            </div>
          </div>
          <div className="md:col-span-2">
            <Sidebar>
              <div className="admob-ad mx-10">Ad space</div>
            </Sidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default JavaStringsArraysCheatsheet;
