import React from "react";

function SqlHomePage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-4xl font-bold text-blue-600 mb-8">Welcome to SQL Resources</h1>
      <p className="text-lg text-gray-700 mb-8 max-w-xl text-center">
      SQL (Structured Query Language) is a standardized programming language used for managing and manipulating relational databases. It allows users to create, read, update, and delete (CRUD) data within a database. SQL is essential for querying and retrieving data, defining database schemas, and managing access permissions. It is widely used in various database systems like MySQL, PostgreSQL, Oracle, and Microsoft SQL Server. SQL's powerful syntax and versatility make it the backbone of database operations in many applications, enabling efficient data management and complex querying capabilities.      </p>
      <div className="space-y-4">
        <button
          onClick={() => window.location.href = '/cheat-sheets'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Cheat Sheets
        </button>
        <button
          onClick={() => window.location.href = '/tutorialspage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Tutorials
        </button>
        <button
          onClick={() => window.location.href = '/interviewquestions'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Interview Questions
        </button>
        <button
          onClick={() => window.location.href = '/projectshomepage'}
          className="px-6 py-3 mx-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          Projects
        </button>
      </div>
    </div>
  );
}

export default SqlHomePage;
